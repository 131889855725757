import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  TablePagination,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  IconButton,
  Tooltip,
  Switch,
  TextField,
  InputAdornment,
  TableSortLabel,
  CircularProgress,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { adminService } from "src/api/services/admin";
import useToast from "src/hooks/useToast";
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import ConfirmModal from "src/components/ConfirmModal";
import { useStyles } from "src/pages/dashboard/styles/main";
import { errorMessage } from "src/utils/errorTypeDetector";
import { useLocation } from "react-router-dom";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name(En)",
  },
  {
    id: "nameAr",
    numeric: false,
    disablePadding: false,
    label: "Name(Ar)",
  },
];

const Results = ({ resultData, pagination, actions, setListData, loading }) => {
  const classes = useStyles();
  const { toast } = useToast();
  const permissions = usePermission();
  const location = useLocation();
  const isReordering = location.pathname.includes("reorder");

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState({ id: false, name: false, nameAr: false });
  const [confirmModal, setConfirmModal] = useState({ show: false, data: null });

  const [movedRowIndex, setMovedRowIndex] = useState(null);

  const handlePageChange = (_event, newPage) => {
    actions.changePage(newPage);
  };

  const handleLimitChange = (event) => {
    actions.changeTake(parseInt(event.target.value));
  };
  const reorderList = async (prevId, nextId, id) => {
    if (!permissions[PERMISSIONS.Reorder_Categories]) return;
    try {
      const data = {
        prevId,
        nextId,
      };
      await adminService.reorderCategory(id, data);
      toast("Reorderd successfully.");
    } catch (err) {
      console.log(err);
      toast(errorMessage(err), "error");
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setListData(arrayMove(resultData, oldIndex, newIndex));
    setMovedRowIndex(newIndex);
  };
  useEffect(() => {
    if (movedRowIndex || movedRowIndex === 0) {
      let currentIndex = movedRowIndex;
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : -1;
      const nextIndex =
        currentIndex !== resultData.length ? currentIndex + 1 : -1;

      const currentId = resultData[currentIndex].id;
      let prevId = currentIndex > -1 ? resultData[prevIndex]?.id : -1;
      let nextId = currentIndex > -1 ? resultData[nextIndex]?.id : -1;
      reorderList(prevId, nextId, currentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movedRowIndex]);

  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    setOrder((prev) => {
      return {
        ...prev,
        [property]: !prev[property],
      };
    });
    actions.handleSortCategory({ [property]: order[property] ? 1 : -1 });
  };

  const handleDelete = () => {
    actions.onDelete(confirmModal.data);
  };

  const TableBodySortable = SortableContainer(
    ({ children, displayRowCheckbox }) => (
      <TableBody
        className={loading ? classes.fadedRow : classes.tableRow}
        displayRowCheckbox={displayRowCheckbox}
      >
        {children}
      </TableBody>
    )
  );

  return (
    <>
      <Card>
        <Box p={2}>
          <TextField
            sx={{
              m: 0,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
            }}
            onChange={actions.handleSearchCategory}
            placeholder={"Search by name ..."}
            size="small"
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Box>
        <Divider />
        {resultData && resultData?.length === 0 ? (
          <>
            <Typography
              sx={{
                py: 10,
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {loading ? (
                <div className={classes.findingContainer}>
                  <CircularProgress className={classes.findingSpinner} />
                  trying to find data , Please waite !
                </div>
              ) : (
                "We couldn't find any category matching your search criteria"
              )}
            </Typography>
          </>
        ) : (
          <>
            <TableContainer style={{ width: "100%" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {isReordering && (
                      <TableCell style={{ width: "5%" }}>&nbsp; </TableCell>
                    )}
                    {!isReordering &&
                      headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={"left"}
                          padding={"normal"}
                          sortDirection={true}
                        >
                          <TableSortLabel
                            active={true}
                            direction={
                              orderBy === headCell.id && order !== false
                                ? "asc"
                                : "desc"
                            }
                            onClick={createSortHandler(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    {isReordering && (
                      <>
                        <TableCell>{"ID"}</TableCell>
                        <TableCell>{"Name(En)"}</TableCell>
                        <TableCell>{"Name(Ar)"}</TableCell>
                      </>
                    )}
                    <TableCell>{"Active"}</TableCell>
                    {!isReordering && (
                      <>
                        {" "}
                        <TableCell>{"Update"}</TableCell>
                        {permissions[PERMISSIONS.Revoke_Category] && (
                          <TableCell>{"Delete"}</TableCell>
                        )}
                      </>
                    )}
                  </TableRow>
                </TableHead>
                {!isReordering ? (
                  resultData &&
                  resultData.map((row, index) => {
                    return (
                      <Row
                        index={index}
                        key={row.id}
                        data={row}
                        setConfirmModal={setConfirmModal}
                      />
                    );
                  })
                ) : (
                  <TableBodySortable
                    onSortEnd={onSortEnd}
                    useDragHandle
                    displayRowCheckbox={false}
                  >
                    <div className={classes.spinner}>
                      {loading && <CircularProgress />}
                    </div>

                    {resultData &&
                      resultData.map((row, index) => {
                        return (
                          <RowWithOrdering
                            index={index}
                            key={row.id}
                            data={row}
                            setConfirmModal={setConfirmModal}
                          />
                        );
                      })}
                  </TableBodySortable>
                )}
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={pagination.totalCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={pagination.page}
                rowsPerPage={pagination.take}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          </>
        )}
      </Card>
      <ConfirmModal
        show={confirmModal.show}
        onHide={() => setConfirmModal({ show: false, data: null })}
        action={handleDelete}
        actionTitle="category"
      />
    </>
  );
};

export default Results;

const ListActiveStatus = ({ data }) => {
  const { toast } = useToast();
  const permissions = usePermission();
  const [isCategoryDisabled, setIsCategoryDisabled] = useState(data.isActive);
  const [, setLoading] = useState(false);

  const handleToggle = async () => {
    if (!permissions[PERMISSIONS.Update_Category]) return;
    const tempData = {
      isActive: !isCategoryDisabled,
    };

    try {
      setLoading(true);
      await adminService.updateCategory(data.id, tempData);
      toast(
        `${data.name.toUpperCase()} ${
          !isCategoryDisabled ? "Activate" : "Deactivate"
        } successful!`,
        "success"
      );
      setIsCategoryDisabled((toggle) => !toggle);
      setLoading(false);
    } catch (error) {
      toast(`Error happened in toggle class`, "error");
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Box>
      <Switch
        disabled={!permissions[PERMISSIONS.Update_Category]}
        checked={isCategoryDisabled}
        onChange={(e) => handleToggle(e)}
        inputProps={{ "aria-label": "controlled" }}
      />
    </Box>
  );
};
const DragHandle = SortableHandle(({ style }) => (
  <span style={{ ...style, ...{ cursor: "move" } }}> {"::::"} </span>
));

const Row = ({ data, setConfirmModal, ...other }) => {
  const location = useLocation();
  const permissions = usePermission();
  const isReordering = location.pathname.includes("reorder");
  return (
    <TableRow {...other}>
      {isReordering && other?.children?.[0]}
      {isReordering && (
        <TableCell style={{ width: "5%" }}>
          <DragHandle />
        </TableCell>
      )}
      <TableCell>{data.id}</TableCell>
      <TableCell>
        <Typography fontWeight="700">{data.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight="700">{data.nameAr}</Typography>
      </TableCell>
      <TableCell>
        <ListActiveStatus data={data} />
      </TableCell>
      {!isReordering && (
        <>
          <TableCell>
            <Typography noWrap>
              <Tooltip title={"Update"} arrow>
                <Link
                  to={
                    permissions[PERMISSIONS.Update_Category]
                      ? ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.UPDATE.EDIT_BY_DATA(
                          data?.id
                        ).ABSOLUTE
                      : "#"
                  }
                >
                  <IconButton color="primary">
                    <LaunchTwoToneIcon fontSize="small" />
                  </IconButton>
                </Link>
              </Tooltip>
            </Typography>
          </TableCell>
          {permissions[PERMISSIONS.Revoke_Category] && (
            <TableCell>
              <DeleteIcon
                onClick={() => {
                  permissions[PERMISSIONS.Revoke_Category] && setConfirmModal({ show: true, data: data });
                }}
                style={{ color: "red", cursor: "pointer" }}
              />
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  );
};

const RowWithOrdering = SortableElement(
  ({ data, setConfirmModal, index, key }) => {
    return (
      <Row
        data={data}
        setConfirmModal={setConfirmModal}
        index={index}
        key={key}
      />
    );
  }
);
