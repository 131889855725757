import { useState, useEffect } from "react";
import Footer from "src/components/layouts/AccentSidebarLayout/Footer";
import {
  Box,
  Tabs,
  Tab,
  Grid,
  styled,
  Button,
  CircularProgress,
  Chip,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { adminService } from "src/api/services/admin";
import Profile from "src/components/Profile";
import ProfileCover from "src/components/Profile/ProfileCover";
import SecurityTab from "src/components/Profile/SecurityTab";
import BookHistory from "src/pages/dashboard/users/Customers/components/BookHistory";
import AddressHistory from "src/pages/dashboard/users/Customers/components/AddressHistory";
import BidsHistory from "src/pages/dashboard/users/Pro/components/Bids";
import PaymentsHistory from "src/pages/dashboard/users/Pro/components/Payments";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { useCallback } from "react";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const UserDetails = () => {
  const { toast } = useToast();
  const permissions = usePermission();
  const { userType } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [approveLoading, setAprroveLoading] = useState(false);
  const userId = useParams().id;
  const [currentTab, setCurrentTab] = useState("info");

  const tabs = [
    { value: "info", label: "Info" },
    ...(userType === "admin"
      ? [{ value: "security", label: "Passwords/Security" }]
      : []),
    ...(userType === "customer" && permissions[PERMISSIONS.Get_User_Address]
      ? [{ value: "address_history", label: "Address History" }]
      : []),
    ...(userType === "customer" &&
    permissions[PERMISSIONS.Get_Book_Request_List]
      ? [{ value: "book_history", label: "Booking History" }]
      : []),
    ...(userType === "pro" && permissions[PERMISSIONS.Get_Pro_Bid_History]
      ? [{ value: "bid_history", label: "Bid History" }]
      : []),
    ...(userType === "pro"
      ? [{ value: "payment_history", label: "Payment History" }]
      : []),
  ];

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  const getUser = useCallback(async () => {
    try {
      const response = await adminService[
        userType !== "pro" ? "getUserDetail" : "getProDetail"
      ](userId);
      setUser(response?.data?.data);
    } catch (err) {
      toast(errorMessage(err), "error");
      console.error(err);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 900);
    }
  }, []);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser]);

  const approvePro = async () => {
    try {
      setAprroveLoading(true);
      await adminService.approvePro({ agentId: user?.id });
      let copyUser = { ...user };
      copyUser.status = "VERIFIED";
      setUser(copyUser);
      toast("user approved successfully", "success");
    } catch (err) {
      setAprroveLoading(false);
      toast(errorMessage(err), "error");
    } finally {
      setAprroveLoading(false);
    }
  };

  if (!user) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          mt: 3,
        }}
      >
        <Grid
          sx={{
            px: { xs: 1, sm: 1, md: 3, lg: 4, xl: 4 },
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          {loading ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid item xs={12} md={8}>
                <ProfileCover
                  target="USERS"
                  user={user}
                  handleRefreshData={getUser}
                  imageUploader={
                    userType === "pro" || userType === "customer" ? false : true
                  }
                />

                {user?.status === "WAITING_FOR_APPROVAL" && (
                  <Button
                    startIcon={
                      approveLoading ? <CircularProgress size="1rem" /> : null
                    }
                    variant="contained"
                    style={{ color: "#fff" }}
                    onClick={approvePro}
                    disabled={approveLoading}
                  >
                    Approve Pro Profile
                  </Button>
                )}

                {user?.status === "VERIFIED" && (
                  <Chip
                    label="Pro is verified"
                    color="success"
                    style={{
                      marginTop: "-5rem",
                      marginLeft: "2rem",
                      borderRadius: "10px",
                      marginBottom: "5px",
                    }}
                  />
                )}
                {(userType === "pro" ||
                  userType === "customer" ||
                  userType === "admin") &&
                  user.isDeleted && (
                    <Box>
                      {" "}
                      <Chip
                        label="user is deleted"
                        color="error"
                        style={{
                          marginLeft: "2rem",
                          borderRadius: "10px",
                        }}
                      />
                    </Box>
                  )}
              </Grid>
              <Grid item xs={12}>
                <TabsWrapper
                  onChange={handleTabsChange}
                  value={currentTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  {tabs.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
                </TabsWrapper>
              </Grid>
              <Grid item xs={12}>
                {currentTab === "info" && (
                  <Profile
                    user={user}
                    handleRefreshData={getUser}
                    from={userType}
                  />
                )}
                {currentTab === "security" && <SecurityTab user={user} />}
                {currentTab === "book_history" && <BookHistory />}
                {currentTab === "address_history" && (
                  <AddressHistory user={user} handleRefreshData={getUser} />
                )}
                {currentTab === "bid_history" && (
                  <BidsHistory user={user} handleRefreshData={getUser} />
                )}
                {currentTab === "payment_history" && (
                  <PaymentsHistory user={user} handleRefreshData={getUser} />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default UserDetails;

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;

      .MuiTabs-indicator {
        box-shadow: none;
      }
    }
`
);
