import React from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
import { convertTimezone, diffDateTime } from "src/utils/FormatDate";
import Text from "src/components/Text";

const Info = ({ id, data }) => {
  return (
    <Card>
      <Box
        p={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            {"data "}
          </Typography>
          <Typography variant="subtitle2">
            {"Manage datas related to bid"}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <CardContent
        sx={{
          p: 4,
        }}
      >
        <Typography variant="subtitle2">
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              mb={2}
            >
              <Box pr={2} display="flex">
                <Typography pr={3} variant="subtitle2">
                  {"Book ID:"}
                </Typography>
              </Box>
              <Text color="black">
                <b>{data?.id}</b>
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              mt={2}
              mb={2}
            >
              <Box pr={3} display="flex">
                <Typography pr={3} variant="subtitle2">
                  {"Created At:"}
                </Typography>
              </Box>
              <Text color="black">
                <b>
                  {data?.createdAt
                    ? convertTimezone(data?.createdAt)
                    : ""}
                </b>
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              mt={2}
              mb={2}
            >
              <Box pr={3} display="flex">
                <Typography pr={3} variant="subtitle2">
                  {"Expire Date:"}
                </Typography>
              </Box>
              <Text color="black">
                <b>
                  {data?.expiresAt ? diffDateTime(data.expiresAt) : ""}
                </b>
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              mt={2}
              mb={2}
            >
              <Box pr={3} display="flex">
                <Typography pr={3} variant="subtitle2">
                  {"User Name:"}
                </Typography>
              </Box>
              <Text color="black">
                <b>{data?.username}</b>
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              mt={2}
              mb={2}
            >
              <Box pr={3} display="flex">
                <Typography pr={3} variant="subtitle2">
                  {"Phone Number:"}
                </Typography>
              </Box>
              <Text color="black">
                <b>{data?.phoneNumber}</b>
              </Text>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              mt={2}
              mb={2}
            >
              <Box pr={3} display="flex">
                <Typography pr={3} variant="subtitle2">
                  {"Service:"}
                </Typography>
              </Box>
              <Text color="black">
                <b>{data?.service}</b>
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              mt={2}
            >
              <Box pr={3} display="flex">
                <Typography pr={3} variant="subtitle2">
                  {"Address:"}
                </Typography>
              </Box>
              <Text color="black">
                {/* <b>{data?.address}</b> */}
                <b>
                  {`Floor : ${data?.address?.floor || "-"}` +
                    " " +
                    `Flat : ${data?.address?.falt || "-"}` +
                    " " +
                    `block : ${data?.address?.block || "-"}` +
                    " " +
                    `House : ${data?.address?.house || "-"}` +
                    " " +
                    `Street : ${data?.address?.street || "-"}` +
                    " " +
                    `Area : ${data?.address?.area?.name || "-"}`}
                </b>
              </Text>
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Info;
