import { useStaticPages } from "../../EditStaticPagesContext";
import Editor from "../Editor";

const Editors = () => {
    const { activePage } = useStaticPages();

    return <>
        <Editor
            editorValue={activePage?.html || ''}
            title={activePage?.title || ''}
            language={{
                name: 'English',
                slug: 'EN'
            }}
        />
        <Editor
            editorValue={activePage?.htmlAr || ''}
            language={{
                name: 'Arabic',
                slug: 'AR'
            }}
            title={activePage?.titleAr || ''}
        />

    </>
}

export default Editors;