import React from "react";
import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
}
  from "@mui/material";
import Text from "../Text";

// ***** role management should be restricted and only super admin should see and work with it. so dynamic importing is required!
const RoleManagement = ({ user, handleRefreshData }) => {

  const checkRole = (isAdmin, isSuperAdmin, role) => {
    if (role) {
      return role.toUpperCase();
    }
    if (!isAdmin && !isSuperAdmin) {
      return "CUSTOMER";
    }
    if (isAdmin) {
      return "ADMIN";
    } else {
      return "SUPER-ADMIN";
    }
  };

  return (
    <Card>
      <Box
        p={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            {"Role "}
          </Typography>
          <Typography variant="subtitle2">
            {"Manage details related to user role"}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <CardContent
        sx={{
          p: 4,
        }}
      >
        <Typography variant="subtitle2">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
              <Box pr={3} pb={2}>
                {"Current role"}:
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Text color="black">
                <b>{checkRole(user?.isAdmin, user?.isSuperAdmin, user?.role)}</b>
              </Text>
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
    </Card>
  );
};
export default RoleManagement;
