import React, { useState, useCallback, useEffect } from "react";
import { adminService } from "src/api/services/admin";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import AddUpdateForm from "./components/AddUpdateForm";
import RelatedProducts from "./components/RelatedServices";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const AddUpdatePage = () => {
  const { id } = useParams();
  const { toast } = useToast();
  const permissions = usePermission();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const targetId = id;

  const [categoryDetail, setCategoryDetail] = useState(null);

  const onCreate = async (formData) => {
    if (!permissions[PERMISSIONS.Create_New_Category]) return;
    try {
      const { unitId, ...rest } = formData;
      let ids = unitId.map((item) => item.id);
      await adminService.createCategory({ unitOfMeasurementIds: ids, ...rest });
    } catch (error) {
      console.error(error);
      toast(errorMessage(error), "error");
    }
  };

  const onUpdate = async (formData) => {
    if (!permissions[PERMISSIONS.Update_Category]) return;

    const { unitId, ...rest } = formData;

    const ids = unitId.map((item) => item.id);

    let units = {
      toAdd: [],
      toDelete: [],
    };
    if (!categoryDetail?.unitOfMeasurements) {
      units.toAdd = [...ids];
    } else {
      let currentCategoryUnitsId = categoryDetail.unitOfMeasurements?.map(
        (item) => item.id
      );

      let newItemAdded = ids.filter(
        (item) => !currentCategoryUnitsId.includes(item)
      );
      let deletedItem = currentCategoryUnitsId.filter(
        (item) => !ids.includes(item)
      );

      units.toAdd = [...newItemAdded];
      units.toDelete = [...deletedItem];
    }

    try {
      await adminService.updateCategory(targetId, rest);
      if (units.toAdd.length > 0 || units.toDelete.length > 0) {
        await adminService.setUnitsForCategory(targetId, units);
      }
    } catch (error) {
      console.log(error);
      toast(errorMessage(error), "error");
    }
  };

  const getCategoryDetail = useCallback(async () => {
    try {
      const response = await adminService.getCategoryDetail(id);
      setCategoryDetail(response?.data?.data);
    } catch (error) {
      console.error(error);
      toast(errorMessage(error), "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      getCategoryDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCategoryDetail]);

  return (
    <>
      <Grid
        container
        style={{ maxWidth: "100%" }}
        padding={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <>
          <Tooltip arrow placement="top" title={"Go back"}>
            <Link to={ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.ROOT.ABSOLUTE}>
              <IconButton
                color="primary"
                sx={{
                  p: { xs: 1, sm: 1, lg: 2, md: 2, xl: 2 },
                  mr: { xs: 1, sm: 1, lg: 2, md: 2, xl: 2 },
                }}
              >
                <ArrowBackTwoToneIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {!id ? "Add new category" : "Update category"}
            </Typography>
          </Grid>
        </>
      </Grid>
      <Grid
        container
        p={{ xs: 1, sm: 1, lg: 2, md: 2, xl: 2 }}
        direction={!isMediumScreen ? "row" : "column-reverse"}
      >
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          item
          sx={{
            p: { xs: 0, sm: 0, lg: 2, md: 2, xl: 2 },
          }}
          maxWidth='100%'
          width='100%'
        >
          <AddUpdateForm
            targetAPI={!id ? onCreate : onUpdate}
            detail={categoryDetail}
          />
          {id && (
            <RelatedProducts targetId={targetId} filterType="categoryId" />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AddUpdatePage;
