import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link as RouterLink, Navigate, useLocation } from "react-router-dom";
import { authService } from "src/api/services/auth";
import { useSetTokens, useSetUser, useUser } from "src/contexts/GlobalContext";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import useToast from "src/hooks/useToast";
import Logo from "./components/Logo";
import { useSetPermission } from "src/contexts/GlobalContext";
import {
  Box,
  Button,
  TextField,
  Link,
  CircularProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useRefMounted from "../../hooks/useRefMounted";

import { loginValidationSchema } from "./login-validation-schema";

const Login = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "all",
    resolver: yupResolver(loginValidationSchema()),
  });

  const classes = useStyles();

  const location = useLocation();
  const setTokens = useSetTokens();
  const setUser = useSetUser();
  const setPermission = useSetPermission();
  const user = useUser();
  const { toast } = useToast();
  const isMountedRef = useRefMounted();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const loginResponse = await authService.login(data.email, data.password);
      setTokens(
        loginResponse.data.data.access_token,
        loginResponse.data.data.refresh_token
      );
      const { user } = loginResponse.data.data;
      const { permissions } = user;
      setUser(user);
      const currentUserPermissions = {};
      for (let p of permissions) {
        currentUserPermissions[p] = true;
      }
      setPermission(currentUserPermissions);
      toast("login success!", "success");
      if (isMountedRef.current) {
        setLoading(false);
      }
    } catch (err) {
      if (isMountedRef.current) {
        setLoading(false);
        toast(err.response.data.meta.message, "error");
      }
    }
  };

  if (user) {
    if (location.state?.previousPath)
      return <Navigate to={location.state.previousPath} replace />;
    //**** redirect user to path entered before login
    else
      return (
        <Navigate
          to={ROUTE_CONSTANTS.DASHBOARD.USERS.ADMINS.ABSOLUTE}
          replace
        />
      );
  }

  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box className={classes.container}>
        <Logo />
        <Typography
          variant="h4"
          style={{ marginBottom: "20px", textAlign: "center" }}
        >
          Login
        </Typography>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                autoFocus
                type="email"
                label={"Email address"}
                name="email"
                variant="outlined"
                id="fullName"
              />
            )}
          />
          {errors.email && (
            <Typography color="red">{errors.email.message}</Typography>
          )}
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label={"Password"}
                name="password"
                type="password"
                variant="outlined"
              />
            )}
          />

          {errors.password && (
            <Typography color="red">{errors.password.message}</Typography>
          )}
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Link
              component={RouterLink}
              to={ROUTE_CONSTANTS.AUTH.FORGOT_PASSWORD.ABSOLUTE}
            >
              <b>{"Lost password?"}</b>
            </Link>
          </Box>

          <Button
            sx={{
              mt: 3,
            }}
            color="primary"
            startIcon={loading ? <CircularProgress size="1rem" /> : null}
            disabled={loading}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            style={{ color: "#fff" }}
          >
            {"Sign in"}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Login;

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "100%",
    width: 400,
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  },
}));
