import {
  Grid,
  Typography,
  Button,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { Link, useParams, useLocation } from "react-router-dom";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";
import { makeStyles } from "@mui/styles";


const PageHeader = () => {
  const { id: questionnaireId, questionId } = useParams();
  const location = useLocation();
  const permissions = usePermission();
  const isReordering = location.pathname.includes("reorder");
  const classes = useStyles();

  const route = isReordering
    ? ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.ROOT.GET_BY_DATA(
        questionnaireId,
        questionId
      ).ABSOLUTE
    : ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.REORDER.REORDER_BY_DATA(
        questionnaireId,
        questionId
      ).ABSOLUTE;

  const icon = isReordering ? (
    <ArrowBackIcon fontSize="small" />
  ) : (
    <FormatListBulletedIcon fontSize="small" />
  );

  const handleNavigate = () => {
    if (permissions[PERMISSIONS.Reorder_Options]) {
      return (
        <Link to={route} style={{ textDecoration: "none" }}>
          <Button
            sx={{
              mt: { xs: 0, sm: 0 },
            }}
            style={{ color: "#fff" }}
            variant="contained"
            startIcon={icon}
          >
            {isReordering ? "Back to list" : "Reorder"}
          </Button>
        </Link>
      );
    }
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
        <Grid item>
          <Box display="flex" flexDirection="row">
            <Tooltip arrow placement="top" title={"Go back"}>
              <Link
                to={
                  ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.ROOT.GET_BY_DATA(
                    questionnaireId
                  ).ABSOLUTE
                }
              >
                <IconButton
                  color="primary"
                  sx={{
                    p: 2,
                    mr: 2,
                  }}
                >
                  <ArrowBackTwoToneIcon />
                </IconButton>
              </Link>
            </Tooltip>
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                {"Options Management"}
              </Typography>
              <Typography variant="subtitle2">
                {
                  "All aspects related to the panel options can be managed from this page"
                }
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item className={classes.header_buttons}>
          <Grid container spacing={2} className={classes.button_container}>
            <Grid item>{handleNavigate()}</Grid>
            {permissions[PERMISSIONS.Create_New_Option] && (
              <Grid item>
                <Link
                  to={
                    ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.ADD_NEW.ADD_NEW_BY_DATA(
                      questionnaireId,
                      questionId
                    ).ABSOLUTE
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      mt: { xs: 0, sm: 0 },
                    }}
                    style={{ color: "#fff" }}
                    variant="contained"
                    startIcon={<AddTwoToneIcon fontSize="small" />}
                  >
                    {"Create Option"}
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* } */}
      </Grid>
    </>
  );
};

export default PageHeader;

const useStyles = makeStyles((theme) => ({
  header:{
    [theme.breakpoints.down("md")]: {
      padding: '15px',
      width:'100% !important'
    },
  },
  header_buttons: {
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
      marginBottom: '0 !important',
      marginTop:'10px !important',
      display:'flex',
      justifyContent:'center'
    },
  },
  button_container :{
    [theme.breakpoints.down("md")]: {
      display:'flex !important',
      margin: '0 !important' ,
      justifyContent: 'flex-end !important'
    }
  }
}));