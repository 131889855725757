import * as Yup from "yup";

export const addUpdateQuestionValidationSchema = () =>
  Yup.object().shape(
    {
      question: Yup.string().when("questionId", {
        is: (questionId) => questionId && questionId.length > 0,
        then: (schema) => schema.max(255),
        otherwise: (schema) =>
          schema.max(255).required("The name field is required"),
      }),

      questionAr: Yup.string().when("questionId", {
        is: (questionId) => questionId && questionId?.length > 0,
        then: (schema) => schema.max(255),
        otherwise: (schema) =>
          schema.max(255).required("The Arabic name field is required"),
      }),
      description: Yup.string().when("questionId", {
        is: (questionId) => questionId && questionId?.length > 0,
        then: (schema) => schema.max(400),
        otherwise: (schema) =>
          schema.max(255).required("The description field is required"),
      }),
      descriptionAr: Yup.string().when("questionId", {
        is: (questionId) => questionId && questionId?.length > 0,
        then: (schema) => schema.max(400),
        otherwise: (schema) =>
          schema.max(255).required("The Arabic description field is required"),
      }),
      type: Yup.string().when("questionId", {
        is: (questionId) => questionId && questionId?.length > 0,
        otherwise: (schema) => schema.required("The type field is required"),
      }),
      questionId: Yup.string(),
    },
    [["questionId"]]
  );
