import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Card,
  styled,
  Switch,
} from "@mui/material";
import { Controller } from "react-hook-form";
import * as Yup from "yup";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import Footer from "src/components/layouts/AccentSidebarLayout/Footer";
import PageHeader from "./components/PageHeader";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { adminService } from "src/api/services/admin";
import DynamicForm from "./components/From";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const InAppSubscriotion = () => {
  const [loading, setLoading] = useState({ ios: false, android: false });
  const [subscription, setSubscription] = useState(null);
  const { toast } = useToast();
  const permissions = usePermission();

  const getSubscription = async () => {
    if (!permissions[PERMISSIONS.Get_Setting]) return;
    try {
      const response = await adminService.getSettings("build:review");
      const subscription = JSON.parse(response?.data?.data?.value);
      setSubscription(subscription);
    } catch (error) {
      toast(errorMessage(error), "error");
    }
  };
  const handleSubmitAndroid = async (formData) => {
    if (!permissions[PERMISSIONS.Update_Settings]) return;
    try {
      let data = {
        ios: { ...subscription.ios },
        android: {
          ...formData.android,
        },
      };
      setLoading({ ...loading, android: true });
      await adminService.updateSettings("build:review", {
        value: JSON.stringify(data),
      });
      toast("android data is changed successfully.");
    } catch (error) {
      toast(errorMessage(error), "error");
      setLoading({ ...loading, android: false });
    } finally {
      setLoading({ ...loading, android: false });
    }
  };
  const handleSubmitIos = async (formData) => {
    if (!permissions[PERMISSIONS.Update_Settings]) return;
    try {
      let data = {
        android: { ...subscription.android },
        ios: {
          ...formData.ios,
        },
      };
      setLoading({ ...loading, ios: true });
      await adminService.updateSettings("build:review", {
        value: JSON.stringify(data),
      });
      toast("ios data is changed successfully.");
    } catch (error) {
      setLoading({ ...loading, ios: false });
      toast(errorMessage(error), "error");
    } finally {
      setLoading({ ...loading, ios: false });
    }
  };

  useEffect(() => {
    getSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(subscription);
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "91vh",
        width: "100%",
        maxWidth: "100%",
        position: "relative",
      }}
    >
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        sx={{
          mt: { xs: 1, sm: 1, md: 3, lg: 8, xl: 8 },
          pl: { xs: 5, sm: 5, md: 9, lg: 9, xl: 9 },
          pr: { xs: 1, sm: 1, md: 9, lg: 9, xl: 9 },
        }}
        container
        direction="row"
        justifyContent="flex-start"
        spacing={4}
      >
        <StyledWrapperCard>
          <Typography variant="h5" component="h5" gutterBottom mb={4}>
            In-App subscription control payment method
          </Typography>
          {subscription && subscription.android && subscription.ios ? (
            <>
              <Grid item xs={12}>
                <DynamicForm
                  onSubmitCustomAction={handleSubmitAndroid}
                  validationSchema={Yup.object().shape({
                    android: Yup.object({
                      buildNumber: Yup.number()
                        .typeError("buildNumber in ios must be a number")
                        .required("The buildNumber ios field is required")
                        .positive("You should use positive number")
                        .min(0),
                      underReview: Yup.boolean(),
                    }),
                  })}
                  initialValues={{
                    android: {
                      buildNumber: subscription.android.buildNumber || 0,
                      underReview: subscription.android.underReview || false,
                    },
                  }}
                  fields={(errors, control) => (
                    <Grid
                      container
                      spacing={2}
                      display="flex"
                      alignItems="center"
                    >
                      <Grid item xs={12} fullWidth>
                        <Grid
                          container
                          spacing={3}
                          display="flex"
                          alignItems="center"
                        >
                          <Grid item lg={6} md={6} xl={6} xs={6}>
                            <Box display="flex" alignItems="center">
                              <Typography variant="body1" mr={2} width={100}>
                                Android
                              </Typography>
                              <Controller
                                name="android.buildNumber"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    margin="normal"
                                    autoFocus
                                    value={field.value}
                                  />
                                )}
                              />
                            </Box>
                            <Box>
                              {errors?.android?.buildNumber && (
                                <Typography color="red">
                                  {errors?.android?.buildNumber.message}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            lg={3}
                            md={3}
                            xl={3}
                            xs={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Controller
                              name="android.underReview"
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  {...field}
                                  inputProps={{ "aria-label": "controlled" }}
                                  checked={field.value}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              color="primary"
                              startIcon={
                                loading.android ? (
                                  <CircularProgress size="1rem" />
                                ) : null
                              }
                              disabled={loading.android}
                              type="submit"
                              size="large"
                              variant="contained"
                              style={{ color: "#fff", width: "120px" }}
                            >
                              {"Update"}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <DynamicForm
                  onSubmitCustomAction={handleSubmitIos}
                  validationSchema={Yup.object().shape({
                    ios: Yup.object({
                      buildNumber: Yup.number()
                        .typeError("buildNumber in ios must be a number")
                        .required("The buildNumber ios field is required")
                        .positive("You should use positive number")
                        .min(0),
                      underReview: Yup.boolean(),
                    }),
                  })}
                  initialValues={{
                    ios: {
                      buildNumber: subscription?.ios?.buildNumber,
                      underReview: subscription?.ios?.underReview || false,
                    },
                  }}
                  fields={(errors, control) => (
                    <Grid
                      container
                      spacing={4}
                      display="flex"
                      alignItems="center"
                    >
                      <Grid item xs={12} fullWidth>
                        <Grid
                          container
                          spacing={3}
                          display="flex"
                          alignItems="center"
                        >
                          <Grid item lg={6} md={6} xl={6} xs={6}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              fullWidth
                            >
                              <Typography variant="body1" mr={2} width={100}>
                                Ios
                              </Typography>
                              <Controller
                                name="ios.buildNumber"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    margin="normal"
                                    autoFocus
                                    value={field.value}
                                  />
                                )}
                              />
                              <Box>
                                {errors?.ios?.buildNumber && (
                                  <Typography color="red">
                                    {errors?.ios?.buildNumber.message}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            lg={3}
                            md={3}
                            xl={3}
                            xs={2}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Controller
                              name="ios.underReview"
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  {...field}
                                  inputProps={{ "aria-label": "controlled" }}
                                  checked={field.value}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              color="primary"
                              startIcon={
                                loading.ios ? (
                                  <CircularProgress size="1rem" />
                                ) : null
                              }
                              disabled={loading.ios}
                              type="submit"
                              size="large"
                              variant="contained"
                              style={{
                                color: "#fff",
                                width: "120px",
                                height: "40",
                              }}
                            >
                              {"Update"}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
            </>
          ) : (
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress style={{ marginRight: "12px" }} />
              <p>wait until fetching subscription data</p>
            </Grid>
          )}
        </StyledWrapperCard>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Footer />
      </Grid>
    </Box>
  );
};

export default InAppSubscriotion;

const StyledWrapperCard = styled(Card)`
  padding: 40px 40px !important;

  ${(props) => props.theme.breakpoints.up("md")} {
    width: 65%;
    padding: 50px 50px;
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    padding: 10px !important;
  }
`;
