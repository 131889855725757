import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useSidebarClose } from "src/contexts/GlobalContext";

const SidebarRoutes = ({ route }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleCloseSideBar = useSidebarClose();

  const handleClick = (link) => {
    navigate(link);
    handleCloseSideBar();
  };

  const activeTabFinder = () => {
    const linkArray = route.link?.split("/");
    const myLocation = location.pathname
      .split("/")
      .slice(0, linkArray?.length)
      .join("/");

    return route.link === myLocation;
  };

  return (
    <StyledSidebarRoutes
      isactive={activeTabFinder() ? "true" : "false"}
      onClick={() => handleClick(route.link)}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography className="route-name" ml={1}>
          {route.name}
        </Typography>
      </Box>
    </StyledSidebarRoutes>
  );
};

export default SidebarRoutes;

const StyledSidebarRoutes = styled(Box)`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  padding-left: 30px;
  height: 35px;
  cursor: pointer;
  color: ${(props) => props.isactive === "true" && "#182057"};
  border: ${(props) => props.isactive === "true" && "1px solid #31DDA1"};
  background: ${(props) => props.isactive === "true" && "#e2fff5"};
  :hover {
    background: #e2fff5;
    color: #182057;
  }
  .route-name {
    font-size: 12px;
    font-weight: 600;
  }
`;
