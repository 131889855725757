import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TablePagination,
  styled,
  Tooltip,
  IconButton,
} from "@mui/material";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { adminService } from "src/api/services/admin";
import { Link } from "react-router-dom";
import { staticImages } from "src/assets/images";
import { imageURLCombiner } from "src/utils/imageUrlCombiner";

import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const DragHandle = SortableHandle(({ style }) => (
  <span style={{ ...style, ...{ cursor: "move" } }}> {"::::"} </span>
));

const TableBodySortable = SortableContainer(
  ({ children, displayRowCheckbox }) => (
    <TableBody displayRowCheckbox={displayRowCheckbox}>{children}</TableBody>
  )
);

const Row = SortableElement(({ data, onDelete, ...other }) => {
  const permissions = usePermission();

  return (
    <TableRow {...other}>
      {other?.children?.[0]}
      <TableCell style={{ width: "5%" }}>
        <DragHandle />
      </TableCell>
      <TableCell>{data.id}</TableCell>
      <TableCell>
        <StyledImage
          alt={`service-${data?.name}-photo`}
          src={
            imageURLCombiner(data?.media?.path) || staticImages.sampleProduct
          }
        />
      </TableCell>
      <TableCell>
        <Typography fontWeight="700">{data.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight="700">{data.nameAr}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography noWrap>
          <Typography noWrap>
            <Tooltip title={"Update"} arrow>
              <Link
                to={
                  permissions[PERMISSIONS.Update_Service]
                    ? {
                        pathname:
                          ROUTE_CONSTANTS.DASHBOARD.CATALOG.TYPE.UPDATE.EDIT_BY_DATA(
                            data.id
                          ).ABSOLUTE,
                        search: "?from=category",
                      }
                    : "#"
                }
              >
                <IconButton color="primary">
                  <LaunchTwoToneIcon fontSize="small" />
                </IconButton>
              </Link>
            </Tooltip>
          </Typography>
        </Typography>
      </TableCell>
    </TableRow>
  );
});

const RelatedServices = ({ targetId, filterType }) => {
  const permissions = usePermission();
  const [movedRowIndex, setMovedRowIndex] = useState(null);
  const [listData, setListData] = useState([]);
  const [, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const getMyList = async () => {
    if (!permissions[PERMISSIONS.Get_Related_Services_To_Category]) return;
    let param = {
      take,
      skip: page === 0 ? page : page * take,
      categoryId: targetId,
    };

    try {
      const response = await adminService.getRelatedServices(param);
      setLoading(false);
      setListData(response?.data?.data?.items);
      setTotalCount(response?.data?.data?.count);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleChangePage = (page) => {
    if (page >= 0) setPage(page);
  };
  const reorderList = async (prevId, nextId, id) => {
    try {
      const data = {
        prevId,
        nextId,
      };
      await adminService.reorderService(id, data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, take]);

  useEffect(() => {
    if (movedRowIndex || movedRowIndex === 0) {
      let currentIndex = movedRowIndex;
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : -1;
      const nextIndex =
        currentIndex !== listData.length ? currentIndex + 1 : -1;

      const currentId = listData[currentIndex].id;
      let prevId = currentIndex > -1 ? listData[prevIndex]?.id : -1;
      let nextId = currentIndex > -1 ? listData[nextIndex]?.id : -1;
      reorderList(prevId, nextId, currentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movedRowIndex]);

  const handlePageChange = (_event, newPage) => {
    handleChangePage(newPage);
  };

  const handleLimitChange = (event) => {
    setTake(parseInt(event.target.value));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setListData(arrayMove(listData, oldIndex, newIndex));
    setMovedRowIndex(newIndex);
  };

  return (
    <div style={{ marginTop: "30px" }}>
      <Typography variant="subtitle2" marginBottom="20px">
        Related Services
      </Typography>
      <Card style={{ padding: "25px" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "5%" }}>&nbsp; </TableCell>
                <TableCell>{"Id"}</TableCell>
                <TableCell>{"Photo"}</TableCell>
                <TableCell>{"Name(En)"}</TableCell>
                <TableCell>{"Name(Ar)"}</TableCell>
                <TableCell align="center">{"Update"}</TableCell>
              </TableRow>
            </TableHead>
            {listData.length > 0 ? (
              <TableBodySortable
                onSortEnd={onSortEnd}
                useDragHandle
                displayRowCheckbox={false}
              >
                {listData &&
                  listData.map((row, index) => {
                    return <Row index={index} key={row.id} data={row} />;
                  })}
              </TableBodySortable>
            ) : (
              <Typography variant="h5" mt={2}>
                No related service exist
              </Typography>
            )}
          </Table>
        </TableContainer>
      </Card>
      <Card
        sx={{
          p: 2,
          mt: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography component="span" variant="subtitle1">
            {"Showing"}
          </Typography>{" "}
          <b>{totalCount < take ? totalCount : take}</b> {"of"}{" "}
          <b>{totalCount}</b> <b>{"Services"}</b>
        </Box>
        <TablePagination
          component="div"
          count={totalCount}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={take}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </Card>
    </div>
  );
};

const StyledImage = styled("img")`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
`;
export default RelatedServices;
