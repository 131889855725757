import React, { useState } from "react";
import {
  Box,
  Card,
  Divider,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  TableSortLabel,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { Link } from "react-router-dom";
import { useStyles } from "src/pages/dashboard/styles/main";
import { convertTimezone, diffDateTime } from "src/utils/FormatDate";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Customer",
  },
];

const Results = ({ resultData, pagination, actions, loading }) => {
  const classes = useStyles();
  const permissions = usePermission();

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState({ id: false, username: false });

  const handlePageChange = (_event, newPage) => {
    actions.changePage(newPage);
  };

  //**** change number of vendors gotten in a page ***
  const handleLimitChange = (event) => {
    actions.changeTake(parseInt(event.target.value));
  };
  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    setOrder((prev) => {
      return {
        ...prev,
        [property]: !prev[property],
      };
    });
    actions.handleSortBookReq({ [property]: order[property] ? 1 : -1 });
  };

  return (
    <Card>
      <Box p={2}>
        <TextField
          sx={{
            m: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          onChange={actions.handleSearchBookReq}
          placeholder={"Search by name, phonen umber..."}
          size="small"
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </Box>
      <Divider />
      {resultData.length === 0 ? (
        <>
          <Typography
            sx={{
              py: 10,
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {loading ? (
              <div className={classes.findingContainer}>
                <CircularProgress className={classes.findingSpinner} />
                trying to find data , Please waite !
              </div>
            ) : (
              "We couldn't find any book request matching your search criteria"
            )}
          </Typography>
        </>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={"left"}
                      padding={"normal"}
                      sortDirection={true}
                    >
                      <TableSortLabel
                        active={true}
                        direction={
                          orderBy === headCell.id && order !== false
                            ? "asc"
                            : "desc"
                        }
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell style={{ textAlign: "center" }}>
                    {"Phone Number"}
                  </TableCell>
                  <TableCell>{"Requested Service"}</TableCell>
                  <TableCell align="center">{"Bid No"}</TableCell>
                  <TableCell align="center">{"Call No"}</TableCell>
                  <TableCell align="center">{"Message No"}</TableCell>
                  <TableCell align="center">{"Create Date"}</TableCell>
                  <TableCell align="center">{"Expires In"}</TableCell>
                  <TableCell align="center">{"Actions"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                className={loading ? classes.fadedRow : classes.tableRow}
              >
                <div className={classes.spinner}>
                  {loading && <CircularProgress />}
                </div>

                {resultData.map((data, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell>
                        <Typography variant="h5">{data?.id || ""}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">{data.username}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" textAlign="center">
                          {data.phoneNumber || "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">
                          {data?.service || ""}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5">
                          {data?.bidsCount || 0}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5">
                          {data?.callsCount || 0}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5">
                          {data?.messegasCount || 0}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5">
                          {data?.createdAt
                            ? convertTimezone(data.createdAt)
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5">
                          {data?.expiresAt && diffDateTime(data?.expiresAt)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap>
                          <Tooltip title={"View"} arrow>
                            <Link
                              to={
                                permissions[PERMISSIONS.Get_Book_Detail]
                                  ? ROUTE_CONSTANTS.DASHBOARD.BOOKING.BOOKREQUESTS.DETAILS.GET_BY_DATA(
                                      data?.id
                                    ).ABSOLUTE
                                  : "#"
                              }
                            >
                              <IconButton color="primary">
                                <LaunchTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={pagination.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={pagination.page}
              rowsPerPage={pagination.take}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </>
      )}
    </Card>
  );
};

export default Results;
