import React, { useState } from "react";
import {
  Box,
  Card,
  Divider,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  TableSortLabel,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import { Link as RouterLink } from "react-router-dom";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "BookId",
  },
];

const Results = ({ resultData, pagination, actions }) => {
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState(true);

  const handlePageChange = (_event, newPage) => {
    actions.changePage(newPage);
  };

  //**** change number of vendors gotten in a page ***
  const handleLimitChange = (event) => {
    actions.changeTake(parseInt(event.target.value));
  };
  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    actions.handleSortCustomerBookHistory({ [property]: order ? 1 : -1 });
    setOrder((prev) => !prev);
  };

  return (
    <Card>
      <Box p={2}>
        <TextField
          sx={{
            m: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          onChange={actions.handleSearchCustomerBookHistory}
          placeholder={"Search by title"}
          size="small"
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </Box>
      <Divider />
      {resultData.length === 0 ? (
        <>
          <Typography
            sx={{
              py: 10,
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {"We couldn't find any book history matching your search criteria"}
          </Typography>
        </>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell?.id}
                      align={"left"}
                      padding={"normal"}
                      sortDirection='asc'
                      width={headCell?.id === "id" && 100}
                    >
                      <TableSortLabel
                        active={true}
                        direction={
                          orderBy === headCell?.id && order !== false
                            ? "asc"
                            : "desc"
                        }
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell align="center">{"Title"}</TableCell>
                  <TableCell align="center">{"Count Bid"}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {"Actions"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultData.map((data, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell>
                        <Typography variant="h5">{data?.id || ""}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5">
                          {data?.service || ""}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5">
                          {data?.bidsCount || (data?.bidsCount === 0 && 0)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap>
                          <RouterLink to={ROUTE_CONSTANTS.DASHBOARD.BOOKING.BOOKREQUESTS.DETAILS.GET_BY_DATA(data?.id).ABSOLUTE}>
                            <Tooltip title={"View"} arrow>
                              <IconButton color="primary">
                                <LaunchTwoToneIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </RouterLink>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={pagination.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={pagination.page}
              rowsPerPage={pagination.take}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </>
      )}
    </Card>
  );
};

export default Results;
