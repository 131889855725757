import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { adminRolesOptions } from "src/constants";
import { createAdminValidationSchema } from "./validation-schema";
import { adminService } from "src/api/services/admin";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { usePermission } from "src/contexts/GlobalContext";

export default function CreateAdmin({ handleClose, open, reload }) {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      role: "",
    },
    mode: "all",
    resolver: yupResolver(createAdminValidationSchema()),
  });

  const onSubmit = async (formData) => {
    const { role, ...rest } = formData;
    let data = {
      ...rest,
      ...(role === "ADMIN" && { isAdmin: true, isSuperAdmin: false }),
      ...(role === "SUPERADMIN" && { isSuperAdmin: true, isAdmin: false }),
    };
    try {
      setLoading(false);
      await adminService.createAdmin(data);
      toast("You Added User Successfully.", "success");
      reload();
      handleClose();
    } catch (err) {
      toast(errorMessage(err?.meta?.message), "error");
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          p: 3,
        }}
      >
        <Typography variant="h4" gutterBottom>
          {"Add new admin user"}
        </Typography>
        <Typography variant="subtitle2">
          {"Fill in the fields below to create new admin"}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          dividers
          sx={{
            p: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="fullName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type="text"
                        label={"Full Name"}
                        name="fullName"
                        variant="outlined"
                        {...field}
                        id="fullName"
                      />
                    )}
                  />
                  {errors.fullName && (
                    <Typography color="red">
                      {errors.fullName.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => {
                      return (
                        <TextField
                          fullWidth
                          type="text"
                          label={"Email"}
                          variant="outlined"
                          {...field}
                          id="email"
                        />
                      );
                    }}
                  />
                  {errors.email && (
                    <Typography color="red">{errors.email.message}</Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputLabel id="select-role-label">Role</InputLabel>
                        <Select
                          fullWidth
                          labelId="select-role-label"
                          id="select-role"
                          label="Role"
                          name={field.name}
                          {...field}
                          value={field.value}
                          onChange={(event) =>
                            field.onChange(event.target.value)
                          }
                        >
                          {adminRolesOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                  {errors.role && (
                    <Typography color="red">{errors.role.message}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            p: 3,
          }}
        >
          <Button color="secondary" onClick={handleClose}>
            {"Cancel"}
          </Button>
          <Button
            style={{ color: "#fff" }}
            type="submit"
            startIcon={loading ? <CircularProgress size="1rem" /> : null}
            // disabled={Boolean(errors.submit) || loading}
            variant="contained"
          >
            {"Add new user"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
