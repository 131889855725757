import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { adminService } from "src/api/services/admin";
import { Grid, Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";

const StaticPage = () => {
  const { toast } = useToast();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const parameters = location.pathname.split("/");
  const staticPageType = parameters[parameters.length - 2].split("_").join(" ");
  let language = parameters[parameters.length - 1];
  language = language.toUpperCase();

  const [html, setHtml] = useState({ __html: "" });
  const [title, setTitle] = useState("");

  const getData = async () => {
    try {
      let res = await adminService.getStaticPage(staticPageType, language);
      setTitle(res.data.data.title);
      setHtml({ __html: res.data.data.html });
    } catch (error) {
      toast(errorMessage(error), "error");
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container display="flex" justifyContent="center">
      <Grid
        item
        xl={10}
        lg={10}
        md={10}
        sm={12}
        xs={12}
        mt={10}
        style={{ marginTop: "1rem", minHeight: "200px", padding: "15px" }}
      >
        <Typography
          variant={isMobile ? "h4" : "h3"}
          fontWeight={isMobile ? "700" : "600"}
          textAlign={language === "AR" && "right"}
        >
          {title}
        </Typography>
        {isMobile ? (
          <div
            dangerouslySetInnerHTML={html}
            style={{ direction: language === "AR" ? "rtl" : "ltr" }}
          />
        ) : (
          <Card
            style={{
              marginTop: "1rem",
              minHeight: "200px",
              padding: "20px",
              overflowX: "hidden",
              width: "100%",
            }}
          >
            <div
              dangerouslySetInnerHTML={html}
              style={{
                direction: language === "AR" ? "rtl" : "ltr",
                width: "100%",
                lineBreak: "anywhere",
              }}
            />
          </Card>
        )}
      </Grid>
    </Grid>
  );
};
export default StaticPage;
