import { useRef, useState } from "react";
import { useSidebarClose } from "src/contexts/GlobalContext";
import { NavLink } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import useToast from "src/hooks/useToast";
import { useUser, useSetUser } from "src/contexts/GlobalContext";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { ACCESS_TOKEN } from "src/constants/access-token";
import { REFRESH_TOKEN } from "src/constants/refresh_token";
import { USER_INFO } from "src/constants/user-info";

const HeaderUserBox = () => {
  const handleCloseSideBar = useSidebarClose();
  const { toast } = useToast();
  const user = useUser();
  const setUser = useSetUser();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(USER_INFO);
    setUser(null);
    handleClose();
    handleCloseSideBar();
    toast("Sign out success!", "success");
  };

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={`${user?.fullName}`} src={user?.media?.path} />
        <Box
          component="span"
          sx={{
            display: { xs: "none", md: "inline-block" },
          }}
        >
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.fullName || ""}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.isAdmin ? "Admin" : "Super-Admin" || ""}
            </UserBoxDescription>
          </UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: "none", sm: "inline-block" },
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1,
            }}
          />
        </Box>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210,
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user?.fullName} src={user?.media?.path} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.fullName || ""}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.isAdmin ? "ADMIN" : "SUPER-ADMIN"}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0,
          }}
        />
        <List
          sx={{
            p: 1,
          }}
          component="nav"
        >
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
            to={ROUTE_CONSTANTS.DASHBOARD.USERS.PROFILE.ABSOLUTE}
            component={NavLink}
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={"Profile"} />
          </ListItem>
        </List>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1,
              }}
            />
            {"Sign out"}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default HeaderUserBox;

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding: ${theme.spacing(0, 1)};
        height: ${theme.spacing(7)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);
