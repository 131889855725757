import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  TablePagination,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  TableSortLabel,
  Divider,
  CircularProgress,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { Link, useLocation, useParams } from "react-router-dom";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ToggleActivation from "../../Questionnaire/components/ToggleActivation";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { adminService } from "src/api/services/admin";

import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { useStyles } from "src/pages/dashboard/styles/main";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "question",
    numeric: false,
    disablePadding: false,
    label: "Title",
  },
];

const Results = ({ resultData, pagination, actions, loading }) => {
  const classes = useStyles();
  const { id: questionnaireId } = useParams();
  const location = useLocation();
  const isReordering = location.pathname.includes("reorder");

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState({ id: false, question: false });

  const [movedRowIndex, setMovedRowIndex] = useState(null);
  const { toast } = useToast();

  const handlePageChange = (_event, newPage) => {
    actions.changePage(newPage);
  };

  const handleLimitChange = (event) => {
    actions.changeTake(parseInt(event.target.value));
  };

  const reorderList = async (prevId, nextId, id) => {
    try {
      const data = {
        prevId,
        nextId,
        questionnaireId: +questionnaireId,
      };
      await adminService.reorderQuestions(id, data);
      toast("Reordered successfully!");
    } catch (err) {
      toast(errorMessage(err), "error");
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    actions.setter(arrayMove(resultData, oldIndex, newIndex));
    setMovedRowIndex(newIndex);
  };

  useEffect(() => {
    if (movedRowIndex || movedRowIndex === 0) {
      let currentIndex = movedRowIndex;
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : -1;
      const nextIndex =
        currentIndex !== resultData.length ? currentIndex + 1 : -1;

      const currentId = resultData[currentIndex].id;
      let prevId = currentIndex > -1 ? resultData[prevIndex]?.id : -1;
      let nextId = currentIndex > -1 ? resultData[nextIndex]?.id : -1;
      reorderList(prevId, nextId, currentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movedRowIndex]);

  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    setOrder((prev) => {
      return {
        ...prev,
        [property]: !prev[property],
      };
    });
    actions.handleSortQuestion({ [property]: order[property] ? 1 : -1 });
  };

  const TableBodySortable = SortableContainer(
    ({ children, displayRowCheckbox }) => (
      <TableBody
        className={loading ? classes.fadedRow : classes.tableRow}
        displayRowCheckbox={displayRowCheckbox}
      >
        {children}
      </TableBody>
    )
  );

  return (
    <Card>
      <Box p={2}>
        <TextField
          sx={{
            m: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          onChange={actions.handleSearchQuestion}
          placeholder={"Search by name ..."}
          size="small"
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </Box>
      <Divider />
      {resultData && resultData?.length === 0 ? (
        <Typography
          sx={{
            py: 10,
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {loading ? (
            <div className={classes.findingContainer}>
              <CircularProgress className={classes.findingSpinner} />
              trying to find data , Please waite !
            </div>
          ) : (
            "We couldn't find any question matching your search criteria"
          )}
        </Typography>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {isReordering && (
                    <TableCell style={{ width: "5%" }}>&nbsp; </TableCell>
                  )}
                  {!isReordering &&
                    headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={"normal"}
                        sortDirection={true}
                      >
                        <TableSortLabel
                          active={true}
                          direction={
                            orderBy === headCell.id &&
                            order[headCell.id] !== false
                              ? "asc"
                              : "desc"
                          }
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  {isReordering && (
                    <>
                      <TableCell>{"ID"}</TableCell>
                      <TableCell>{"Title"}</TableCell>
                    </>
                  )}
                  <TableCell>{"Description"}</TableCell>
                  <TableCell>{"Type"}</TableCell>
                  {!isReordering && (
                    <>
                      <TableCell>{"Active"}</TableCell>
                      <TableCell align="center">{"Action"}</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              {!isReordering ? (
                resultData &&
                resultData.map((row, index) => {
                  return <Row index={index} key={row.id} data={row} />;
                })
              ) : (
                <TableBodySortable
                  onSortEnd={onSortEnd}
                  useDragHandle
                  displayRowCheckbox={false}
                >
                  <div className={classes.spinner}>
                    {loading && <CircularProgress />}
                  </div>

                  {resultData &&
                    resultData.map((row, index) => {
                      return (
                        <RowWithOrdering
                          index={index}
                          key={row.id}
                          data={row}
                        />
                      );
                    })}
                </TableBodySortable>
              )}
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={pagination.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={pagination.page}
              rowsPerPage={pagination.take}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </>
      )}
    </Card>
  );
};

export default Results;

const DragHandle = SortableHandle(({ style }) => (
  <span style={{ ...style, ...{ cursor: "move" } }}> {"::::"} </span>
));
const Row = ({ data, ...other }) => {
  const { id: questionnaireId } = useParams();
  const permissions = usePermission();
  const location = useLocation();
  const isReordering = location.pathname.includes("reorder");

  return (
    <TableRow {...other}>
      {isReordering && other?.children?.[0]}
      {isReordering && (
        <TableCell style={{ width: "5%" }}>
          <DragHandle />
        </TableCell>
      )}
      <TableCell>
        <Typography fontWeight="800">{data.id}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{data?.question}</Typography>
      </TableCell>
      <TableCell>
        <Box>
          <Typography> {data?.description}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography>{data?.type}</Typography>
      </TableCell>
      {!isReordering && (
        <>
          <TableCell>
            <ToggleActivation
              disabled={!permissions[PERMISSIONS.Update_Question]}
              data={{ ...data, questionnaireId: questionnaireId }}
              targetApi="activationQuestion"
            />
          </TableCell>
          <TableCell align="center">
            <Typography noWrap>
              <Tooltip title={"update"} arrow>
                <Link
                  to={
                    permissions[PERMISSIONS.Update_Question]
                      ? {
                          pathname:
                            ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.UPDATE.EDIT_BY_DATA(
                              questionnaireId,
                              data.id
                            ).ABSOLUTE,
                          search: `?question=${data?.question}&questionAr=${data?.questionAr}&description=${data?.description}&descriptionAr=${data?.descriptionAr}&type=${data?.type}`,
                        }
                      : "#"
                  }
                >
                  <IconButton color="primary">
                    <LaunchTwoToneIcon fontSize="small" />
                  </IconButton>
                </Link>
              </Tooltip>
              {(data?.type === "multiple_select" ||
                data?.type === "single_select") && permissions[PERMISSIONS.Get_Options_Related_To_Question] && (
                <Tooltip title={"options"} arrow>
                  <Link
                    to={
                      permissions[PERMISSIONS.Get_Options_Related_To_Question] ? 
                      ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.ROOT.GET_BY_DATA(
                        questionnaireId,
                        data.id
                      ).ABSOLUTE
                      :"#"
                    }
                  >
                    <IconButton color="primary">
                      <AssignmentIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              )}
            </Typography>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

const RowWithOrdering = SortableElement(({ data, index, key }) => {
  return <Row data={data} index={index} key={key} />;
});
