import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { adminService } from "src/api/services/admin";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  TextField,
  Divider,
  Button,
  CircularProgress,
  Tooltip,
  Box,
} from "@mui/material";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useToast from "src/hooks/useToast";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { addUpdateUnitValidationSchema } from "./add-update-unit-validation-schema";
import { errorMessage } from "src/utils/errorTypeDetector";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const AddUpdatePage = () => {
  const { id } = useParams();
  const {
    control,
    formState: { errors, isSubmitted, isSubmitting },
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      nameAr: "",
    },
    mode: "all",
    resolver: yupResolver(addUpdateUnitValidationSchema()),
  });
  const theme = useTheme();
  const { toast } = useToast();
  const navigate = useNavigate();
  const permissions = usePermission();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const is_small_screen = useMediaQuery(theme.breakpoints.down("sm"));
  const targetId = id;
  const [loading, setLoading] = useState(false);
  const [, setDetail] = useState(null);

  const onCreate = async (formData) => {
    if (!permissions[PERMISSIONS.Create_New_Unit]) return;
    try {
      await adminService.createUnitOfMeasurement(formData);
      toast("unit of measurement create successful!", "success");
      reset();
    } catch (error) {
      toast(errorMessage(error), "error");
      console.log(error);
    }
  };

  const onUpdate = async (formData) => {
    if (!permissions[PERMISSIONS.Update_Unit]) return;
    try {
      await adminService.updateUnitsOfMeasurement(targetId, formData);
      toast("Unit of measurement update successful!", "success");
      reset();
      navigate(
        ROUTE_CONSTANTS.DASHBOARD.SYSTEM.UNIY_MEASUREMENT.ROOT.ABSOLUTE,
        {
          replace: true,
        }
      );
    } catch (error) {
      toast(errorMessage(error), "error");
      console.error(error);
    }
  };
  const onSubmit = (data) => {
    !id ? onCreate(data) : onUpdate(data);
  };

  const getUnitDetail = useCallback(async () => {
    if (!permissions[PERMISSIONS.Get_Unit_Detail]) return;
    try {
      setLoading(true);
      const response = await adminService.getUnitDetail(id);
      const result = response?.data?.data;
      setDetail(result);
      setLoading(false);
      setValue("name", result?.name);
      setValue("nameAr", result?.nameAr);
    } catch (error) {
      console.error(error);
      toast(errorMessage(error), "error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      getUnitDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUnitDetail]);

  return (
    <>
      <Grid
        container
        padding={2}
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="nowrap"
      >
        <Tooltip arrow placement="top" title={"Go back"}>
          <Button
            onClick={() => navigate(-1)}
            color="primary"
            sx={{
              p: { xs: 1, sm: 1, md: 2, xl: 2, lg: 2 },
              mr: { xs: 0, sm: 0, md: 2, xl: 2, lg: 2 },
            }}
          >
            <ArrowBackTwoToneIcon />
          </Button>
        </Tooltip>
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {!id
              ? "Define Unit Of Measurement for Pro Bid"
              : "Update Unit Of Measurement for Pro Bid"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        p={2}
        spacing={2}
        direction={!isMediumScreen ? "row" : "column-reverse"}
      >
        <Grid xs={12} sm={12} md={6} lg={8} xl={8} item>
          <Typography variant="subtitle2" marginBottom="20px">
            {!id
              ? "Fill out form below to add your unit of measurement for pro bid"
              : "Fill out form below to update your unit of measurement for pro bid"}
          </Typography>
          <Card style={{ padding: "25px" }}>
            {loading ? (
              <Box textAlign="center">
                <CircularProgress />{" "}
                <Typography pt={3}>
                  Waite until the service detail is loaded
                </Typography>
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} marginBottom={2}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label={"Name(En)"}
                          name="name"
                          variant="outlined"
                        />
                      )}
                    />
                    {errors.name && (
                      <Typography color="red">{errors.name.message}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="nameAr"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          dir="rtl"
                          fullWidth
                          label={"Name(Ar)"}
                          name="nameAr"
                          variant="outlined"
                          sx={{ "& .MuiOutlinedInput-notchedOutline": { direction: "ltr" } }}
                        />
                      )}
                    />
                    {errors.nameAr && (
                      <Typography color="red">
                        {errors.nameAr.message}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  style={{ margin: "20px 0px" }}
                  direction={!is_small_screen ? "row" : "column-reverse"}
                  alignItems="center"
                >
                  <Button color="secondary" onClick={() => navigate(-1)}>
                    {"Cancel"}
                  </Button>
                  <Button
                    type="submit"
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={isSubmitted}
                    variant="contained"
                    style={{ width: is_small_screen && "100%", color: "#fff" }}
                  >
                    Save
                  </Button>
                </Grid>
              </form>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AddUpdatePage;
