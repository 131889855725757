import ThemeProviderWrapper from "./theme/ThemeProvider";
import AppRouter from "./routes";
import { CssBaseline } from "@mui/material";
import CustomSnackbarProvider from "./components/SnackbarProvider";
import "simplebar-react/dist/simplebar.min.css";

function App() {
  return (
    <ThemeProviderWrapper>
      <CustomSnackbarProvider>
        <CssBaseline />
        <AppRouter />
      </CustomSnackbarProvider>
    </ThemeProviderWrapper>
  );
}

export default App;
