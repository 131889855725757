import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { useUser } from "src/contexts/GlobalContext";
import GeneralLayoutWrapper from "../GeneralLayout/GeneralLayoutWrapper";
import AccentSidebarLayout from "../AccentSidebarLayout";

const DashboardLayoutContent = () => {
  const user = useUser();
  const location = useLocation();

  if (!user)
    return (
      <Navigate
        to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE}
        state={{ previousPath: location.pathname }}
        replace
      />
    );

  return <AccentSidebarLayout />;
};

const DashboardLayout = () => {
  return (
    <GeneralLayoutWrapper>
      <DashboardLayoutContent />
    </GeneralLayoutWrapper>
  );
};
export default DashboardLayout;
