import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Card,
  Divider,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import Footer from "src/components/layouts/AccentSidebarLayout/Footer";
import PageHeader from "./components/PageHeader";
import { adminEmailValidationSchema } from "./email-validation-schema";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { adminService } from "src/api/services/admin";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const AdminEmail = () => {
  const [loading, setLoading] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "all",
    resolver: yupResolver(adminEmailValidationSchema()),
  });
  const { toast } = useToast();
  const theme = useTheme();
  const permissions = usePermission();
  const is_small_screen = useMediaQuery(theme.breakpoints.down("sm"));

  const getEmail = async () => {
    if (!permissions[PERMISSIONS.Get_Setting]) return;
    try {
      const response = await adminService.getSettings("admin:email");
      setValue("email", response?.data?.data?.value);
    } catch (error) {
      toast(errorMessage(error), "error");
    }
  };

  const onSubmit = async (formData) => {
    if (!permissions[PERMISSIONS.Update_Settings]) return;
    try {
      setLoading(true);
      let data = {
        value: `${formData?.email}`,
      };
      await adminService.updateSettings("admin:email", data);
      toast("limit is changed successfully.");
    } catch (error) {
      setLoading(false);
      toast(errorMessage(error), "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "91vh",
        position: "relative",
      }}
    >
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        sx={{
          mt: { xs: 0, sm: 1, md: 6, lg: 8, xl: 8 },
          pl: { xs: 5, sm: 5, md: 8, lg: 8, xl: 8 },
        }}
        container
        direction="row"
        justifyContent="flex-start"
        spacing={4}
      >
        <StyledWrapperCard>
          <Typography variant="h5" component="h5" gutterBottom>
            Set Email for Admin
          </Typography>
          <Box mt={2}></Box>

          <Grid item xs={12}>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={12} fullWidth>
                  <Box display="flex" lignItems="center" fullWidth>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ width: "100%" }}
                        >
                          <TextField
                            {...field}
                            fullWidth
                            margin="normal"
                            autoFocus
                            type="text"
                            name="email"
                            variant="outlined"
                            label="value"
                            id="email"
                          />
                        </Box>
                      )}
                    />
                  </Box>
                  <Box>
                    {errors.email && (
                      <Typography color="red">
                        {errors.email.message}
                      </Typography>
                    )}
                  </Box>
                  <Divider style={{ marginTop: "10px" }} />
                </Grid>

                <Grid
                  container
                  style={{ margin: "20px 0px" }}
                  direction={!is_small_screen ? "row" : "column-reverse"}
                  alignItems="center"
                >
                  <Button
                    color="primary"
                    startIcon={
                      loading ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={loading}
                    type="submit"
                    size="large"
                    variant="contained"
                    style={{ color: "#fff", width: "120px" }}
                  >
                    {"Update"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </StyledWrapperCard>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Footer />
      </Grid>
    </Box>
  );
};

export default AdminEmail;

const StyledWrapperCard = styled(Card)`
  padding: 20px 20px;
`;
