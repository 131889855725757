export const tabs = [
  {
    value: "ALL",
    label: "All users",
  },
  {
    value: "ADMIN",
    label: "Admins",
  },
  {
    value: "SUPERADMIN",
    label: "Super Admins",
  },
];
