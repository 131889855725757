import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
  Button,
  CircularProgress,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  Card,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { questionTypes } from "src/constants";
import ClearIcon from "@mui/icons-material/Clear";
import useToast from "src/hooks/useToast";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { errorMessage } from "src/utils/errorTypeDetector";
import { addUpdateQuestionValidationSchema } from "../add-update-question-validation-schema";
import { adminService } from "src/api/services/admin";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const AddUpdateForm = ({ targetAPI, subTitle }) => {
  const { id: questionnaireId , questionId:id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const question = queryParams.get("question") || "";
  const questionAr = queryParams.get("questionAr") || "";
  const description = queryParams.get("description") || "";
  const descriptionAr = queryParams.get("descriptionAr") || "";
  const type = queryParams.get("type") || "";
  const questionId = queryParams.get("questionId") || "";


  const [questions, setQuestions] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const {
    control,
    formState: { errors, isSubmitted, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      question: question,
      questionAr: questionAr,
      description: description,
      descriptionAr: descriptionAr,
      type: type,
      questionId: questionId,
    },
    mode: "all",
    resolver: yupResolver(addUpdateQuestionValidationSchema()),
  });

  const { toast } = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const permissions = usePermission();
  const is_small_screen = useMediaQuery(theme.breakpoints.down("sm"));

  const getQuestionList = async () => {
    if(!permissions[PERMISSIONS.Get_Question_List]) return;
    try {
      let params = {
        skip: 0,
        take: 10000,
      };
      let response = await adminService.getQuestionList(params);
      let items = response?.data?.data?.items.map((item) => {
        return {
          label: item?.question + " " + item?.questionAr,
          value: item?.id,
        };
      });
      setQuestions(items);
    } catch (err) {
      toast(errorMessage(err), "error");
    }
  };
  // const getQuestionDetail = useCallback(async()=>{
  //   try{
  //     const response = adminService.getQuestionDetail(questionnaireId,id);
  //     console.log('detail',(await response).data)
  //   }catch(error){
  //     console.error(error);
  //     toast(errorMessage(error),'error');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  useEffect(() => {
    getQuestionList();
    // if(id){
    //   getQuestionDetail()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    try {
      let formData = data.questionId
        ? {
            ...data,
            questionId: +data?.questionId,
            questionnaireId: +questionnaireId,
          }
        : data;
      await targetAPI(formData);
      toast(
        `question ${!id ? "created" : "update"} successfully!`,
        "success"
      );
      navigate(
        ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.ROOT.GET_BY_DATA(questionnaireId).ABSOLUTE,
        { replace: true }
      );
    } catch (err) {
      toast(errorMessage(err), "error");
    }
  };

  return (
    <>
      <Typography variant="subtitle2" marginBottom="20px">
        {subTitle}
      </Typography>
      <Card style={{ padding: "25px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} marginBottom={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="question"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={selectedQuestion}
                    {...field}
                    fullWidth
                    label={"Name(En)"}
                    name="question"
                    variant="outlined"
                  />
                )}
              />
              {errors.question && (
                <Typography color="red">{errors.question.message}</Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="questionAr"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={selectedQuestion}
                    {...field}
                    dir="rtl"
                    fullWidth
                    label={"Name(Ar)"}
                    name="questionAr"
                    variant="outlined"
                    sx={{ "& .MuiOutlinedInput-notchedOutline": { direction: "ltr" } }}
                  />
                )}
              />
              {errors.questionAr && (
                <Typography color="red">{errors.questionAr.message}</Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={selectedQuestion}
                    {...field}
                    fullWidth
                    label={"Description(En)"}
                    name="description"
                    variant="outlined"
                  />
                )}
              />
              {errors.description && (
                <Typography color="red">
                  {errors.description.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="descriptionAr"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={selectedQuestion}
                    {...field}
                    dir="rtl"
                    fullWidth
                    label={"Description(Ar)"}
                    name="descriptionAr"
                    variant="outlined"
                    sx={{ "& .MuiOutlinedInput-notchedOutline": { direction: "ltr" } }}
                  />
                )}
              />
              {errors.descriptionAr && (
                <Typography color="red">
                  {errors.descriptionAr.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <>
                    <InputLabel id="select-role-label">Type</InputLabel>
                    <Select
                      disabled={selectedQuestion}
                      fullWidth
                      labelId="select-role-label"
                      id="select-role"
                      label="Type"
                      name={field.name}
                      {...field}
                      value={field.value}
                      onChange={(event) => field.onChange(event.target.value)}
                    >
                      {questionTypes.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
              {errors.type && (
                <Typography color="red">{errors.type.message}</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                You can select from the list of previous questions or ask new
                ones!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="questionId"
                control={control}
                render={({ field }) => (
                  <>
                    <InputLabel id="select-role-label">
                      Question List
                    </InputLabel>
                    <Select
                      endAdornment={
                        <IconButton
                          size="small"
                          sx={{
                            visibility: field.value ? "visible" : "hidden",
                            marginRight: "10px",
                          }}
                          onClick={() => {
                            field.onChange("");
                            setSelectedQuestion(null);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      }
                      fullWidth
                      labelId="select-role-label"
                      id="select-role"
                      label="Question List"
                      name={field.name}
                      {...field}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                        setSelectedQuestion(event.target.value);
                      }}
                    >
                      {questions &&
                        questions?.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                )}
              />
              {errors.questionId && (
                <Typography color="red">{errors.questionId.message}</Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            style={{ margin: "20px 0px" }}
            direction={!is_small_screen ? "row" : "column-reverse"}
            alignItems="center"
          >
            <Button
              color="secondary"
              onClick={() => {
               navigate(ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.ROOT.GET_BY_DATA(questionnaireId).ABSOLUTE)
              }}
            >
              {"Cancel"}
            </Button>
            <Button
              type="submit"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitted}
              variant="contained"
              style={{ width: is_small_screen && "100%", color: "#fff" }}
            >
              Save
            </Button>
          </Grid>
        </form>
      </Card>
    </>
  );
};
export default AddUpdateForm;
