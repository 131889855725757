import {
  Grid,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
  Button,
  CircularProgress,
  Divider,
  Card,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useToast from "src/hooks/useToast";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { errorMessage } from "src/utils/errorTypeDetector";
import { addUpdateOptionValidationSchema } from "../add-update-option-validation-schema";

const AddUpdateForm = ({ targetAPI, subTitle }) => {
  const { id: questionnaireId, questionId, optionId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name") || "";
  const nameAr = queryParams.get("nameAr") || "";

  const {
    control,
    formState: { errors, isSubmitted, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: name,
      nameAr: nameAr,
    },
    mode: "all",
    resolver: yupResolver(addUpdateOptionValidationSchema()),
  });

  const { toast } = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const is_small_screen = useMediaQuery(theme.breakpoints.down("sm"));

  const onSubmit = async (data) => {
    try {
      await targetAPI(data);
      toast(
        `Option ${!optionId ? "created" : "update"} successfully!`,
        "success"
      );
      navigate(
        ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.ROOT.GET_BY_DATA(
          questionnaireId,
          questionId
        ).ABSOLUTE
      );
    } catch (err) {
      toast(errorMessage(err), "error");
    }
  };
  return (
    <>
      <Typography variant="subtitle2" marginBottom="20px">
        {subTitle}
      </Typography>
      <Card style={{ padding: "25px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} marginBottom={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={"Name(En)"}
                    name="name"
                    variant="outlined"
                  />
                )}
              />
              {errors.name && (
                <Typography color="red">{errors.name.message}</Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="nameAr"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    dir="rtl"
                    fullWidth
                    label={"Name(Ar)"}
                    name="nameAr"
                    variant="outlined"
                    sx={{ "& .MuiOutlinedInput-notchedOutline": { direction: "ltr" } }}
                  />
                )}
              />
              {errors.nameAr && (
                <Typography color="red">{errors.nameAr.message}</Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            style={{ margin: "20px 0px" }}
            direction={!is_small_screen ? "row" : "column-reverse"}
            alignItems="center"
          >
            <Button
              color="secondary"
              onClick={() => {
                navigate(
                  ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.ROOT.GET_BY_DATA(
                    questionnaireId,
                    questionId
                  ).ABSOLUTE
                );
              }}
            >
              {"Cancel"}
            </Button>
            <Button
              type="submit"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitted}
              variant="contained"
              style={{
                width: is_small_screen && "100%",
                color: `${isSubmitted ? "gray" : "#fff"}`,
              }}
            >
              Save
            </Button>
          </Grid>
        </form>
      </Card>
    </>
  );
};
export default AddUpdateForm;
