import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CustomButton from "../CustomButton";

const ConfirmModal = ({ show, onHide, action, actionTitle , title="warning" , actionText = "Are you sure? Do you want delete this"}) => {
  return (
    <Dialog fullWidth maxWidth="md" open={show} onClose={onHide}>
      <DialogTitle>
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent style={{ padding: "10px", margin: "10px" }}>
        <Typography variant="h4">
          {actionText} {actionTitle}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <CustomButton
          text={"Yes"}
          style={{ backgroundColor: "#f33" }}
          onClick={()=>{
            action();
            onHide();
          }}
        />
        <CustomButton text={"No"} onClick={onHide} />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
