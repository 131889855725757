import {
  useNavigate,
} from "react-router-dom"
import { ROUTE_CONSTANTS } from '../constants/AppRoutes'
import { usePermission } from '../contexts/GlobalContext'

const ProtectedRoutes = ({ children, accessKey }) => {
  const permissions = usePermission();
  const navigate = useNavigate();
  if(permissions && permissions[accessKey]){
    return children;
  }else{
    navigate(ROUTE_CONSTANTS.PUBLIC_ROUTES.NOT_PERMITTED.ABSOLUTE, { replace: true })
  }
  return null;
}
export default ProtectedRoutes
