// import { getAuthorization } from "src/utils/getAuthorization";
import create from "zustand";
import { ACCESS_TOKEN } from "src/constants/access-token";
import { REFRESH_TOKEN } from "src/constants/refresh_token";
import { USER_INFO } from "src/constants/user-info";

const { localStorage } = window;

const getToken = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  return token ?? undefined;
};

const myUserInfo = () => {
  const myStoredUserInfo = localStorage.getItem(USER_INFO);
  if (myStoredUserInfo) {
    return JSON.parse(myStoredUserInfo);
  } else {
    return undefined;
  }
};

const useStore = create((set) => ({
  user: myUserInfo(),
  isLogin: getToken(),
  myPermissions: [],
  setPermission: async (myPermissions) => {
    set(() => ({ myPermissions }));
  },
  setUser: (user) => {
    set(() => ({ user }));
    localStorage.setItem(USER_INFO, JSON.stringify(user));
  },
  setTokens: (access_token, refresh_token) => {
    localStorage.setItem(ACCESS_TOKEN, access_token);
    localStorage.setItem(REFRESH_TOKEN, refresh_token);
  },
  // ****** sidebar management ******
  sidebarToggle: false,
  setSidebarToggle: () =>
    set((state) => ({ sidebarToggle: !state.sidebarToggle })),
  sideBarClose: () => set(() => ({ sidebarToggle: false })),
}));

export const useUser = () => useStore((state) => state.user);
export const useIsLogin = () => useStore((state) => state.isLogin);
export const useSetUser = () => useStore((state) => state.setUser);
export const usePermission = () => useStore((state) => state.myPermissions);
export const useSetPermission = () => useStore((state) => state.setPermission);
export const useSetTokens = () => useStore((state) => state.setTokens);
export const useSidebarShow = () => useStore((state) => state.sidebarToggle);
export const useSidebarToggle = () =>
  useStore((state) => state.setSidebarToggle);
export const useSidebarClose = () => useStore((state) => state.sideBarClose);
