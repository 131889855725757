import React from "react";
import { Box, Typography, styled } from "@mui/material";
import { staticImages } from "src/assets/images";

const WelcomPage = () => {
  return (
    <Box
      style={{ width: "100%", minHeight: "50vh" }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <StyledImage src={staticImages.taaalLogo} />
      <Typography variant="h4">Welcom to the Thalatha Admin Panel</Typography>
    </Box>
  );
};

export default WelcomPage;

const StyledImage = styled("img")(
  () => `
        width: 70px;
        height: 70px;
        object-fit: cover;
        border-radius: 9px;
        margin-bottom: 20px;
  `
);
