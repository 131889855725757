import { Grid, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { Link } from "react-router-dom";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const PageHeader = () => {
  const permissions = usePermission();
  const classes = useStyles();

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {"Questionnaire Management"}
          </Typography>
          <Typography variant="subtitle2">
            {
              "All aspects related to the panel Questionnaires can be managed from this page"
            }
          </Typography>
        </Grid>
        {permissions[PERMISSIONS.Create_New_Questionnaire] && (
          <Grid item className={classes.header_create_service}>
            <Link
              to={ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.ADD_NEW.ABSOLUTE}
              style={{ textDecoration: "none" }}
            >
              <Button
                sx={{
                  mt: { xs: 2, sm: 0 },
                }}
                style={{ color: "#fff" }}
                variant="contained"
                startIcon={<AddTwoToneIcon fontSize="small" />}
              >
                {"Create Questionnaire"}
              </Button>
            </Link>
          </Grid>
        )}
        {/* } */}
      </Grid>
    </>
  );
};

export default PageHeader;

const useStyles = makeStyles((theme) => ({
  header:{
    [theme.breakpoints.down("md")]: {
      padding: '25px'
    },
  },
  header_create_service: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px !important",
      width: "100%",
      textAlign: "right",
    },
  },
}));