import React from "react";
import { Link, useParams } from "react-router-dom";
import { adminService } from "src/api/services/admin";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import AddUpdateForm from "./components/AddUpdateForm";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const AddUpdatePage = () => {
  const { id: questionnaireId, questionId, optionId } = useParams();
  const { toast } = useToast();
  const permissions = usePermission();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const targetId = optionId;

  const onCreate = async (formData) => {
    if(!permissions[PERMISSIONS.Create_New_Option]) return;
    try {
      await adminService.createOptions({
        ...formData,
        questionId: +questionId,
      });
    } catch (error) {
      toast(errorMessage(error), "error");
    }
  };

  const onUpdate = async (formData) => {
    if(!permissions[PERMISSIONS.Update_Option]) return;

    try {
      await adminService.updateOptions(targetId, {
        ...formData,
        questionId: +questionId,
      });
    } catch (error) {
      toast(errorMessage(error), "error");
    }
  };

  return (
    <>
      <Grid
        container
        padding={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Tooltip arrow placement="top" title={"Go back"}>
          <Link
            to={
              ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.ROOT.GET_BY_DATA(
                questionnaireId,
                questionId
              ).ABSOLUTE
            }
          >
            <IconButton
              color="primary"
              sx={{
                p:  { xs: 0, sm: 0, lg: 2, md: 2, xsl: 2 },
                mr:  { xs: 0, sm: 0, lg: 2, md: 2, xsl: 2 },
              }}
            >
              <ArrowBackTwoToneIcon />
            </IconButton>
          </Link>
        </Tooltip>
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {!optionId ? "Add new Option" : "Update Option"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        p={{ xs: 0, sm: 0, lg: 2, md: 2, xsl: 2 }}
        direction={!isMediumScreen ? "row" : "column-reverse"}
      >
        <Grid xs={12} sm={12} md={6} lg={6} xl={6} item p={2}>
          <AddUpdateForm
            targetAPI={!optionId ? onCreate : onUpdate}
            subTitle={
              !optionId
                ? "Fill out form below to add your new Option"
                : "Fill out form below to update your Option"
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddUpdatePage;
