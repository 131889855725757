import { ACCESS_TOKEN } from "src/constants/access-token";
import { REFRESH_TOKEN } from "src/constants/refresh_token";

export const getAuthorization = (type) => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  const refresh_token = localStorage.getItem(REFRESH_TOKEN);

  if (token || refresh_token) {
    switch (type) {
      case 'refresh':
        return `Bearer ${refresh_token}`;
      case 'token':
        return `Bearer ${token}`;
      default:
        return `Bearer ${token}`;
    }
  }

  return null;
}
