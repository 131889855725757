import React from "react";
import { Box } from "@mui/material";
import { sidebarCategoryObjects } from "./SidebarCategoryObjects";
import SidebarCategories from "./SidebarCategories";

const SidebarMenu = () => {
  const menuCategoryGenerator = () => {
    let tempMenuCategory = [];

    Object.keys(sidebarCategoryObjects).forEach((category) => {
      tempMenuCategory.push(sidebarCategoryObjects[category]);
    });

    return tempMenuCategory;
  };

  return (
    <Box style={{ margin: "0px 8px" }}>
      {menuCategoryGenerator().map((category, index) => (
        <SidebarCategories
          key={index}
          category={category}
          Icon={category.icon}
        />
      ))}
    </Box>
  );
};

export default SidebarMenu;
