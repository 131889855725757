import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';

const userManagementCategory = {
  name: "User Management",
  slug: "user_management",
  badgeTooltip: "Users",
  icon: PeopleAltOutlinedIcon,
};
const catalogCategory = {
  name: "Catalog",
  slug: "catalog",
  badgeTooltip: "Catalog",
  icon: CollectionsBookmarkOutlinedIcon,
};
const questionnaireCategory = {
  name: "Questionnaire",
  slug: "questionnaire",
  badgeTooltip: "Questionnaire",
  icon: QuestionAnswerOutlinedIcon,
};
const bookingCategory = {
  name: "Booking",
  slug: "booking",
  badgeTooltip: "Booking",
  icon: CottageOutlinedIcon,
};
const bidPricingCategory = {
  name: "Payment",
  slug: "bid-pricing",
  badgeTooltip: "BidPricing",
  icon: AccountBalanceWalletOutlinedIcon,
};
const systemCategory = {
  name: "System",
  slug: "system",
  badgeTooltip: "System",
  icon: SettingsSuggestOutlinedIcon,
};

// ***** IMPORTANT NOTE ABOUT EXPOSING CATEGORIES ****  orders of defining categories inside export object has a direct effect on orders in sidebar
export const sidebarCategoryObjects = {
  userManagementCategory,
  catalogCategory,
  questionnaireCategory,
  bookingCategory,
  bidPricingCategory,
  systemCategory,
};
