import PropTypes from 'prop-types';
import { Box, styled } from '@mui/material';

const PageTitle = styled(Box)(
  ({ theme }) => `
  ${theme.breakpoints.up("lg")} {
    padding: ${theme.spacing(4)}
  };
  ${theme.breakpoints.up("md")} {
    padding: ${theme.spacing(4)}
  };
  ${theme.breakpoints.down("sm")} {
    padding: ${theme.spacing(0)}
  }
`
);

const PageTitleWrapper = ({ children }) => {
  return (
    <>
      <PageTitle className="MuiPageTitle-wrapper">{children}</PageTitle>
    </>
  );
};

PageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageTitleWrapper;
