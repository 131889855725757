import React, { useState } from "react";
import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import Text from "../Text";
import UpdateModal from "./UpdateModal";
import * as Yup from "yup";
import { adminService } from "src/api/services/admin";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { Controller } from "react-hook-form";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";
import "yup-phone-lite";

const PersonalDetails = ({ user, handleRefreshData, showSections }) => {
  const { toast } = useToast();
  const { userType } = useParams();
  const permissions = usePermission();

  const [modalShow, setModalShow] = useState(false);

  const schema = Yup.object().shape({
    fullName: Yup.string()
      .max(100)
      .required("The full name field is required"),
    ...(userType === "admin",
    {
      phoneNumber: Yup.string().phone('Phone number must be a valid number').required()
    }),
    ...(userType === "pro",
    {
      introduction: Yup.string("The introduction field is required").max(
        100
      ),
    }),
    ...(userType === "pro",
    {
      services: Yup.array().required(
        "You have to select at least one service"
      ),
    }),
    ...(userType === "pro",
    {
      areas: Yup.array().required("You have to select at least one area"),
    }),
  })

  const handleChangeModalShow = (bool) => {
    if (!bool) setModalShow((prevShow) => !prevShow);
    else setModalShow(bool);
  };

  const handleUpdatePersonalInfo = async (_values) => {
    const data = { ..._values };

    Object.keys(data).forEach((item) => {
      if (!data[item]) delete data[item];
    });

    try {
      if (userType === "pro") {
        if (!permissions[PERMISSIONS.Update_Pro_Detail]) return;
        delete data["services"];
        await adminService.updatePro({ ...data, userId: user?.id });
        handleRefreshData();

      } else {
        if (!permissions[PERMISSIONS.Update_User]) return;
        await adminService.updateUser(user.id, data);
        handleRefreshData();

      }
      toast("Personal details updated!", "success");
      setModalShow(false);
    } catch (error) {
      toast(errorMessage(error), "error");
      console.error(error);
    }
  };


  return (
    <Card>
      <Box
        p={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            {"Personal Details"}
          </Typography>
          <Typography variant="subtitle2">
            {"Manage information related to personal details"}
          </Typography>
        </Box>
        {showSections.includes("edit") &&
          (userType === "admin" ||
            (userType === "customer" &&
              permissions[PERMISSIONS.Update_User])) && (
            <Button
              variant="text"
              onClick={() => handleChangeModalShow(true)}
              startIcon={<EditTwoToneIcon />}
            >
              {"Edit"}
            </Button>
          )}

        {showSections.includes("edit") &&
          userType === "pro" &&
          permissions[PERMISSIONS.Update_Pro_Detail] && (
            <Button
              variant="text"
              onClick={() => handleChangeModalShow(true)}
              startIcon={<EditTwoToneIcon />}
            >
              {"Edit"}
            </Button>
          )}
      </Box>
      <Divider />
      <CardContent
        sx={{
          p: 4,
        }}
      >
        <Typography variant="subtitle2">
          {showSections.includes("fullName") && (
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                <Box pr={3} pb={2}>
                  {"Full name"}:
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Text color="black">
                  <b>{user?.fullName}</b>
                </Text>
              </Grid>
            </Grid>
          )}
          {showSections.includes("phoneNumber") && (
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                <Box pr={3} pb={2}>
                  {"Phone number"}:
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Text color="black">
                  <b>{user?.phoneNumber || "-"}</b>
                </Text>
              </Grid>
            </Grid>
          )}
          {showSections.includes("email") && (
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                <Box pr={3} pb={2}>
                  {"Email"}:
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Text color="black">
                  <b>{user?.email || "-"}</b>
                </Text>
              </Grid>
            </Grid>
          )}
          {showSections.includes("introduction") && (
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                <Box pr={3} pb={2}>
                  {"Introduction"}:
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Text color="black">
                  <b>{user?.introduction || "-"}</b>
                </Text>
              </Grid>
            </Grid>
          )}
        </Typography>
      </CardContent>
      <UpdateModal
        show={modalShow}
        onHide={() => handleChangeModalShow(false)}
        onCancel={() => handleChangeModalShow(false)}
        title="Personal information form"
        subtitle="You can change personal information"
        buttonText="Update"
        onSubmitCustomAction={handleUpdatePersonalInfo}
        validationSchema={schema}
        initialValues={{
          fullName: user?.fullName || "",
          phoneNumber: user?.phoneNumber || "",
          ...(userType === "pro",
          {
            introduction: user.introduction || "",
          }),
          ...(userType === "pro", { services: user?.services || [] }),
          ...(userType === "pro", { areas: user?.areas || [] }),
        }}
        fields={(errors, control, setValue) => (
          <>
            <Grid item>
              <Controller
                name="fullName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    margin="normal"
                    autoFocus
                    label="Full Name"
                    name="fullName"
                    variant="outlined"
                    value={field.value}
                  />
                )}
              />
              {errors.fullName && (
                <Typography color="red">{errors.fullName.message}</Typography>
              )}
            </Grid>
            {(userType === "admin" || !userType) && (
              <Grid item mt={2}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="normal"
                      autoFocus
                      label="Phone Number"
                      name="phoneNumber"
                      variant="outlined"
                      value={field.value}
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <Typography color="red">
                    {errors.phoneNumber.message.includes('US') ? errors.phoneNumber.message.replace('US', "") : errors.phoneNumber.message}
                  </Typography>
                )}
              </Grid>
            )}
            {userType === "pro" && (
              <Grid item mt={2}>
                <Controller
                  name="introduction"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="normal"
                      autoFocus
                      label="Introdunction"
                      name="introduction"
                      variant="outlined"
                    />
                  )}
                />
                {errors.introduction && (
                  <Typography color="red">
                    {errors.introduction.message}
                  </Typography>
                )}
              </Grid>
            )}
          </>
        )}
      />
    </Card>
  );
};
export default PersonalDetails;
