import { useState } from "react";
import {
  Box,
  Typography,
  Card,
  Grid,
  ListItem,
  List,
  ListItemText,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import UpdateModal from "./UpdateModal";
import * as Yup from "yup";
import { adminService } from "src/api/services/admin";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { authService } from "src/api/services/auth";
import { ACCESS_TOKEN } from "src/constants/access-token";
import { Controller } from "react-hook-form";
import ConfirmModal from "../ConfirmModal";

const SecurityTab = ({ user }) => {
  const isOwnProfile = user.ownProfile;
  const { toast } = useToast();
  const theme = useTheme();
  const is_small_screen = useMediaQuery(theme.breakpoints.down("sm"));
  

  const [myOwnPasswordShow, setMyOwnPasswordShow] = useState(false);
  const [confirmModal, setConfirmModal] = useState({ show: false, data: null });
  

  const handleChangeModalShow = (bool) => {
    if (isOwnProfile) {
      if (!bool) setMyOwnPasswordShow((prevShow) => !prevShow);
      else setMyOwnPasswordShow(bool);
    } else {
      setConfirmModal({ show: true, data: user });
    }
  };
  const changePasswordForAdmin = async () => {
    try {
      await adminService.forgetPasswordForAdmin(user.id);
      toast("reset password link will be sent on your email", "success");
    } catch (err) {
      toast(errorMessage(err), "error");
      console.log(err);
    }
  };
  const handleUpdatePassword = async (_values) => {
    const data = { ..._values };
    let token = localStorage.getItem(ACCESS_TOKEN);
    try {
      if (isOwnProfile) await authService.resetPassword(data, token);
      else await adminService.forgetPasswordForAdmin(user.id, data);
      toast("Admin password changed successfully!", "success");
      if (isOwnProfile) setMyOwnPasswordShow(false);
    } catch (error) {
      toast(errorMessage(error), "error");
      console.log(error);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box pb={2}>
          <Typography variant="h3">{"Security"}</Typography>
          <Typography variant="subtitle2">
            {"Change your security preferences below"}
          </Typography>
        </Box>
        <Card>
          <List>
            <ListItem
              sx={{
                p: 3,
              }}
            >
              <ListItemText
                primaryTypographyProps={{ variant: "h5", gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  lineHeight: 1,
                }}
                primary={"Change Password"}
                secondary={"You can change your password here"}
              />
              <Button
                size="large"
                variant="outlined"
                onClick={() => handleChangeModalShow(true)}
              >
                {is_small_screen ? "Change" : "Change password"}
              </Button>
            </ListItem>
          </List>
        </Card>
      </Grid>
      <ConfirmModal
        show={confirmModal.show}
        onHide={() => setConfirmModal({ show: false, data: null })}
        action={changePasswordForAdmin}
        actionText ={`Are you sure? Do you want to change ${user?.fullName} password `}
        title={'change password'}
      />
      <UpdateModal
        show={myOwnPasswordShow}
        onHide={() => handleChangeModalShow(false)}
        onCancel={() => handleChangeModalShow(false)}
        title={`Change password for ${user.fullName}`}
        subtitle="You can change other admin password here"
        buttonText="Update"
        onSubmitCustomAction={handleUpdatePassword}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string()
            .max(100)
            .required("The current password field is required"),
          password: Yup.string()
            .max(100)
            .min(8)
            .required("The password field is required"),
          confirmPassword: Yup.string()
            .max(100)
            .min(8)
            .required("The confirm password field is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
        })}
        initialValues={{
          currentPassword: "",
          password: "",
          confirmPassword: "",
        }}
        fields={(errors, control) => (
          <>
            <Grid item>
              <Controller
                name="currentPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    margin="normal"
                    autoFocus
                    label="Current Password"
                    name="currentPassword"
                    variant="outlined"
                    value={field.value}
                  />
                )}
              />
              {errors.currentPassword && (
                <Typography color="red">
                  {errors.currentPassword.message}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    margin="normal"
                    autoFocus
                    label="Password"
                    name="password"
                    variant="outlined"
                    value={field.value}
                  />
                )}
              />
              {errors.password && (
                <Typography color="red">{errors.password.message}</Typography>
              )}
            </Grid>
            <Grid item>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    margin="normal"
                    autoFocus
                    label="Confirm Password"
                    name="confirmPassword"
                    variant="outlined"
                    value={field.value}
                  />
                )}
              />
              {errors.confirmPassword && (
                <Typography color="red">
                  {errors.confirmPassword.message}
                </Typography>
              )}
            </Grid>
          </>
        )}
      />
    </Grid>
  );
};

export default SecurityTab;
