import { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
  Button,
  CircularProgress,
  Divider,
  Card,
  Autocomplete,
  Box,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import useToast from "src/hooks/useToast";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { addUpdateQuestionnaireValidationSchema } from "../add-update-questionnaire-validation-schema";
import { errorMessage } from "src/utils/errorTypeDetector";
import { adminService } from "src/api/services/admin";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const AddUpdateForm = ({ targetAPI, subTitile }) => {
  const { id } = useParams();
  const [services, setServices] = useState(null);
  const [loadServices, setLoadServices] = useState(true);
  const [questionnaireDetail, setQuestionnaireDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const {
    control,
    formState: { errors, isSubmitting, isSubmitted },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: '',
      nameAr: '',
      serviceId: '',
    },
    mode: "all",
    resolver: yupResolver(addUpdateQuestionnaireValidationSchema()),
  });
  const { toast } = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const permissions = usePermission();
  const is_small_screen = useMediaQuery(theme.breakpoints.down("sm"));

  const getServices = useCallback(
    async (serviceId) => {
      if(!permissions[PERMISSIONS.Get_Service_List]) return;
      try {
        let params = {
          skip: 0,
          take: 1000,
        };
        const response = await adminService.getServices(params);
        setLoadServices(false);
        let items =
          response?.data?.data?.items &&
          response?.data?.data?.items.map((item) => {
            return {
              label: item?.name + " " + item?.nameAr,
              value: item?.id,
            };
          });
        if (serviceId) {
          let findValue = items.filter((item) => item.value === serviceId);
          if (findValue) setInputValue(findValue?.[0]?.label);
        }
        setServices(items);
      } catch (error) {
        toast(errorMessage(error), "error");
        setLoadServices(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questionnaireDetail]
  );

  const getQuestionnaireDetail = useCallback(async () => {
    try {
      setLoading(true);
      const response = await adminService.getQuestionnaireDetail(id);
      const result = response?.data?.data;
      setQuestionnaireDetail(result);
      setValue("name", result?.name);
      setValue("nameAr", result?.nameAr);
      setValue("serviceId", result?.serviceId);
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast(errorMessage(error), "error")
    }finally{
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      getQuestionnaireDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestionnaireDetail]);

  useEffect(() => {
    getServices(questionnaireDetail?.serviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getServices]);

  const onSubmit = async (data) => {
    try {
      await targetAPI(data);
      navigate(ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.ROOT.ABSOLUTE, {
        replace: true,
      });
    } catch (error) {
      toast(errorMessage(error), "error");
    }
  };

  return (
    <>
      <Typography variant="subtitle2" marginBottom="20px">
        {subTitile}
      </Typography>
      <Card style={{ padding: "25px" }}>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />{" "}
            <Typography pt={3}>
              Waite until the service detail is loaded
            </Typography>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} style={{ overflow: "unset" }}>
            <Grid container spacing={3} marginBottom={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={"Name(En)"}
                      name="name"
                      variant="outlined"
                    />
                  )}
                />
                {errors.name && (
                  <Typography color="red">{errors.name.message}</Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="nameAr"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      dir="rtl"
                      fullWidth
                      label={"Name(Ar)"}
                      name="nameAr"
                      variant="outlined"
                      sx={{ "& .MuiOutlinedInput-notchedOutline": { direction: "ltr" } }}
                    />
                  )}
                />
                {errors.nameAr && (
                  <Typography color="red">{errors.nameAr.message}</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="serviceId"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={services && services.length > 0 ? services : []}
                      isOptionEqualToValue={(option, value) => {
                        return option.label === value;
                      }}
                      sx={{ width: 300 }}
                      value={inputValue}
                      inputValue={inputValue}
                      onChange={(event, newValue) => {
                        field.onChange(newValue?.value);
                      }}
                      onInputChange={(e, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderInput={(params) => {
                        return loadServices ? (
                          <CircularProgress />
                        ) : (
                          <>
                            <TextField {...params} label="services" />
                            {errors.serviceId && (
                              <Typography color="red">
                                {errors.serviceId.message}
                              </Typography>
                            )}
                          </>
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              style={{ margin: "20px 0px" }}
              direction={!is_small_screen ? "row" : "column-reverse"}
              alignItems="center"
            >
              <Button
                color="secondary"
                onClick={() =>
                  navigate(
                    ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.ROOT.ABSOLUTE,
                    {
                      replace: true,
                    }
                  )
                }
              >
                {"Cancel"}
              </Button>
              <Button
                type="submit"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitted}
                variant="contained"
                style={{ width: is_small_screen && "100%", color: "#fff" }}
              >
                Save
              </Button>
            </Grid>
          </form>
        )}
      </Card>
    </>
  );
};
export default AddUpdateForm;
