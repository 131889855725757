import { Typography } from "@mui/material";
import dayjs from "dayjs";
var timezone = require('dayjs/plugin/timezone');
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
dayjs.extend(timezone)
const tz = "Asia/Kuwait"

  export const convertTimezone = (date) => {
    return dayjs.utc(date).tz(tz).format('D MMMM , YYYY h:mm A')
  }

  export const diffDateTime = (date) => {
    const kuwaitDate = dayjs.utc(date).tz(tz);
    const nowDate = dayjs.utc().tz(tz);

    let dayDiff = kuwaitDate.diff(nowDate, 'day');
    let hoursDiff = kuwaitDate.diff(nowDate,'hours');
    let minuteDiff = kuwaitDate.diff(nowDate, 'minute');

    minuteDiff = minuteDiff - hoursDiff * 60;
    hoursDiff = hoursDiff - dayDiff * 24;

    if (dayDiff < 0 || dayDiff === 0) {
      if (hoursDiff < 0) {
        return <Typography variant="h5" color='red'>Expired</Typography>;
      } else if (minuteDiff < 0) {
        return <Typography variant="h5" color='red'>Expired</Typography>;
      } else {
        return `${hoursDiff}H and ${minuteDiff}M `;
      }
    } else {
      return `${dayDiff}D, ${hoursDiff}H, ${minuteDiff}M `;
    }
  }

