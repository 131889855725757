import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const PageHeader = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {"Book Request Management"}
          </Typography>
          <Typography variant="subtitle2">
            {
              "All aspects related to the panel books can be managed from this page"
            }
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PageHeader;

const useStyles = makeStyles((theme) => ({
  header:{
    [theme.breakpoints.down("md")]: {
      padding: '25px'
    },
  },
}));