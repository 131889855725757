import React, { useState, useCallback, useEffect } from "react";
import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button,
  Autocomplete,
  Checkbox,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import Text from "src/components/Text";
import UpdateModal from "src/components/Profile/UpdateModal";
import { adminService } from "src/api/services/admin";
import { errorMessage } from "src/utils/errorTypeDetector";
import { Controller } from "react-hook-form";
import * as Yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import useToast from "src/hooks/useToast";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Areas = ({ user, handleRefreshData }) => {
  const classes = useStyles();
  const { toast } = useToast();
  const permissions = usePermission();

  const [modalShow, setModalShow] = useState(false);
  const [expanded, setExpanded] = useState('');
  const [areas, setAreas] = useState(null);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [cityWithSelectedAreas, setCityWithSelectedAreas] = useState([]);

  const handleChangeModalShow = (bool) => {
    if (!bool) setModalShow((prevShow) => !prevShow);
    else setModalShow(bool);
  };

  const handleUpdateProAreas = async (_values) => {
    if (!permissions[PERMISSIONS.Update_User_Areas]) return;

    const data = { ..._values };
    const ids = data?.areas?.map((item) => item.code);
    const selectedAreasFromResponse = areas.filter(
      (service) => service.checked
    );

    let deletedAndAddedAreas = {
      toAdd: [],
      toDelete: [],
    };
    if (!selectedAreasFromResponse.length) {
      deletedAndAddedAreas.toAdd = [...ids];
    } else {
      let currentServiceId = selectedAreasFromResponse?.map(
        (item) => item.code
      );
      let newItemAdded = ids.filter((item) => !currentServiceId.includes(item));
      let deletedItem = currentServiceId.filter((item) => !ids.includes(item));

      deletedAndAddedAreas.toAdd = [...newItemAdded];
      deletedAndAddedAreas.toDelete = [...deletedItem];
    }
    try {
      await adminService.updateProAreas(user?.id, deletedAndAddedAreas);
      toast("You change areas successfully.");
      handleChangeModalShow(false);
      handleRefreshData();
    } catch (error) {
      console.error(error);
      toast(errorMessage(error), "error");
    } finally {
    }
  };
  const unselectOption = (code, onChange) => {
    setSelectedAreas((prevState) => prevState.filter((c) => c.code !== code));
    onChange(selectedAreas.filter((c) => c.code !== code));
  };

  const getProAreas = useCallback(async () => {
    try {
      const response = await adminService.getProAreas(user?.id);
      const cityWithSelectedAreas = response?.data?.data?.cities.map((city) => {
        return {
          name: city.name,
          id: city.id,
          areas: city.areas.filter((area) => area.isSelected === true),
        };
      });
      let citiesHaveAreas = cityWithSelectedAreas.filter(
        (item) => item.areas.length > 0
      );
      setCityWithSelectedAreas(citiesHaveAreas);

      const result = response.data?.data?.cities
        ?.map((city) => {
          return city?.areas?.flat()?.map((item) => {
            return {
              group: city?.name,
              code: item?.id,
              label: item?.name,
              checked: item?.isSelected,
            };
          });
        })
        ?.filter((item) => item.length > 0)
        ?.flat();
      setAreas(result);
      let selectedServices = result?.length
        ? result.filter((item) => item.checked)
        : [];
      setSelectedAreas(selectedServices);
    } catch (error) {
      console.error(error);
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAccordion = (city) =>{
    setExpanded(city.name === expanded ? false: city.name)
  }

  const showCityWithAreas = (cities) => {
    return cities.map((city) => {
      return (
        <div style={{ width: "100%" }} key={city?.id}>
          <Accordion
            style={{ backgroundColor: "#0311030d", marginBottom: "5px" , maxHeight:'250px', overflowY:'auto' }}
            onChange={()=>handleChangeAccordion(city)}
            expanded={expanded === city.name}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{city?.name || "-"}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {city?.areas.map((area) => (
                <Typography key={area?.id}>{area?.name + "," || "-"}</Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    });
  };
  useEffect(() => {
    getProAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProAreas]);
  return (
    <Card>
      <Box
        p={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            {"Pro Areas"}
          </Typography>
          <Typography variant="subtitle2">{"View Pro Areas List"}</Typography>
        </Box>
        <Button
          disabled={!permissions[PERMISSIONS.Update_User_Areas]}
          variant="text"
          onClick={() => handleChangeModalShow(true)}
          startIcon={<EditTwoToneIcon />}
        >
          {"Edit"}
        </Button>
      </Box>
      <Divider />
      <CardContent
        sx={{
          p: 4,
        }}
      >
        <Typography variant="subtitle2">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
              <Box pr={3} pb={2}>
                {"Selected Areas"}:
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Text color="black">
                <b style={{ lineBreak: "anywhere" }}>
                  {cityWithSelectedAreas.length > 0 &&
                    showCityWithAreas(cityWithSelectedAreas)}
                </b>
              </Text>
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
      {areas && (
        <UpdateModal
          positionTop={true}
          show={modalShow}
          onHide={() => handleChangeModalShow(false)}
          onCancel={() => handleChangeModalShow(false)}
          title="Areas Info"
          subtitle="You can change pro areas"
          buttonText="Update"
          onSubmitCustomAction={handleUpdateProAreas}
          validationSchema={Yup.object().shape({
            areas: Yup.array().min(1),
          })}
          initialValues={{
            services: selectedAreas || [],
          }}
          fields={(errors, control) => (
            <>
              <Grid item>
                <Controller
                  name="areas"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      id="areas-select-demo"
                      {...field}
                      className={classes.autocomplete}
                      classes={{
                        option: classes.option,
                      }}
                      options={areas}
                      onChange={(_, option) => {
                        setSelectedAreas([...option]);
                        field.onChange(option);
                      }}
                      value={selectedAreas}
                      inputValue={inputValue}
                      autoHighlight
                      multiple
                      disableCloseOnSelect
                      getOptionSelected={(option, value) =>
                        option.code === value.code
                      }
                      getOptionLabel={(option) => option.label}
                      groupBy={(option) => option.group}
                      onInputChange={(e, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderOption={(props, option, { selected }) => (
                        <>
                          <li {...props} key={option.code}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.label}
                          </li>
                        </>
                      )}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Choose a service"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        );
                      }}
                      renderTags={() => {
                        return selectedAreas.map((tag) => (
                          <Button
                            size="small"
                            variant="contained"
                            key={tag.code}
                            style={{ color: "white", marginTop: "5px" }}
                            endIcon={<CancelIcon />}
                            onClick={() =>
                              unselectOption(tag.code, field.onChange)
                            }
                          >
                            {tag.label}
                          </Button>
                        ));
                      }}
                    />
                  )}
                />
                {errors.areas && (
                  <Typography color="red">{errors.areas.message}</Typography>
                )}
              </Grid>
            </>
          )}
        />
      )}
    </Card>
  );
};
export default Areas;

const useStyles = makeStyles({
  autocomplete: {
    width: "100%",
    height: "300px",
    overflowY: "auto",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
