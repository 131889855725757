import React, { useState } from "react";
import {
  Box,
  Card,
  TablePagination,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Divider,
  TableSortLabel,
  CircularProgress,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { Link } from "react-router-dom";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import ToggleActivation from "./ToggleActivation";
import { useStyles } from "src/pages/dashboard/styles/main";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name(En)",
  },
  {
    id: "nameAr",
    numeric: false,
    disablePadding: false,
    label: "Name(Ar)",
  },
];

const Results = ({ resultData, pagination, actions, loading }) => {
  const classes = useStyles();
  const permissions = usePermission();

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState({ id: false, name: false, nameAr: false });

  const handlePageChange = (_event, newPage) => {
    actions.changePage(newPage);
  };

  const handleLimitChange = (event) => {
    actions.changeTake(parseInt(event.target.value));
  };

  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    setOrder((prev) => {
      return {
        ...prev,
        [property]: !prev[property],
      };
    });
    actions.handleSortQuestionnaire({ [property]: order[property] ? 1 : -1 });
  };

  return (
    <Card>
      <Box p={2}>
        <TextField
          sx={{
            m: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          onChange={actions.handleSearchQuestionnaire}
          placeholder={"Search by name ..."}
          size="small"
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </Box>
      <Divider />
      {resultData?.length === 0 ? (
        <Typography
          sx={{
            py: 10,
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {loading ? (
            <div className={classes.findingContainer}>
              <CircularProgress className={classes.findingSpinner} />
              trying to find data , Please waite !
            </div>
          ) : (
            "We couldn't find any questionnaire matching your search criteria"
          )}
        </Typography>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={"left"}
                      padding={"normal"}
                      sortDirection={true}
                    >
                      <TableSortLabel
                        active={true}
                        direction={
                          orderBy === headCell.id &&
                          order[headCell.id] !== false
                            ? "asc"
                            : "desc"
                        }
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell>{"Service"}</TableCell>
                  <TableCell>{"Active"}</TableCell>
                  <TableCell align="center">{"Action"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                className={loading ? classes.fadedRow : classes.tableRow}
              >
                <div className={classes.spinner}>
                  {loading && <CircularProgress />}
                </div>

                {resultData &&
                  resultData.map((data) => {
                    return (
                      <TableRow hover key={data.id}>
                        <TableCell>
                          <Typography fontWeight="800">{data.id}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{data?.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Typography> {data?.nameAr}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Typography>{data?.service?.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <ToggleActivation
                            disabled={
                              !permissions[PERMISSIONS.Update_Questionnaire]
                            }
                            data={data}
                            targetApi="toggleQuestionnaireActivation"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap>
                            <Tooltip title={"update"} arrow>
                              <Link
                                to={
                                  permissions[PERMISSIONS.Update_Questionnaire]
                                    ? ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.UPDATE.EDIT_BY_DATA(
                                        data.id
                                      ).ABSOLUTE
                                    : "#"
                                }
                              >
                                <IconButton color="primary">
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                            {permissions[
                              PERMISSIONS.Get_Related_Questions_To_Questionnaire
                            ] && (
                              <Tooltip title={"questions"} arrow>
                                <Link
                                  to={
                                    ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.ROOT.GET_BY_DATA(
                                      data.id
                                    ).ABSOLUTE
                                  }
                                >
                                  <IconButton color="primary">
                                    <AssignmentIcon />
                                  </IconButton>
                                </Link>
                              </Tooltip>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={pagination.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={pagination.page}
              rowsPerPage={pagination.take}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </>
      )}
    </Card>
  );
};

export default Results;
