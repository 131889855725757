import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
  Button,
  CircularProgress,
  Divider,
  Card,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { adminService } from "src/api/services/admin";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import useToast from "src/hooks/useToast";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { errorMessage } from "src/utils/errorTypeDetector";
import { addUpdateCategoryValidationSchema } from "../add-update-category-validation-schema";
import { useParams } from "react-router-dom";

const AddUpdateForm = ({ targetAPI, detail }) => {
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: detail?.name || "",
      nameAr: detail?.nameAr || "",
      leadPrice: detail?.leadPrice || 0,
      unitId: detail?.unitOfMeasurements || [],
    },
    mode: "all",
    resolver: yupResolver(addUpdateCategoryValidationSchema()),
  });

  const { toast } = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const is_small_screen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState(null);
  const [loadingUnits, setLoadingUnits] = useState(true);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await targetAPI(data);
      toast(`Category ${!id ? "created" : "update"} successfully!`, "success");
      navigate(ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.ROOT.ABSOLUTE, {
        replace: true,
      });
    } catch (err) {
      toast(errorMessage(err), "error");
      console.log(err);
    }
  };

  const getUnits = async () => {
    try {
      const param = { take: 1000, skip: 0 };
      let response = await adminService.getUnitsOfMeasurement(param);
      setUnits(response?.data?.data?.items);
      setLoadingUnits(false);
    } catch (err) {
      console.log(err);
      toast(errorMessage(err), "error");
      setLoadingUnits(false);
    }
  };

  useEffect(() => {
    getUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue("name", detail?.name);
    setValue("nameAr", detail?.nameAr);
    setValue("leadPrice", detail?.leadPrice);
    setValue("unitId", detail?.unitOfMeasurements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  return (
    <>
      <Typography variant="subtitle2" marginBottom="20px">
        {!id
          ? "Fill out form below to add your new category"
          : "Fill out form below to update your category"}
      </Typography>
      <Card style={{ padding: "25px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} marginBottom={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={"Name(En)"}
                    name="name"
                    variant="outlined"
                  />
                )}
              />
              {errors.name && (
                <Typography color="red">{errors.name.message}</Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="nameAr"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    dir="rtl"
                    fullWidth
                    label={"Name(Ar)"}
                    name="nameAr"
                    variant="outlined"
                    sx={{ "& .MuiOutlinedInput-notchedOutline": { direction: "ltr" } }}
                  />
                )}
              />
              {errors.nameAr && (
                <Typography color="red">{errors.nameAr.message}</Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="leadPrice"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={"Price"}
                    name="leadPrice"
                    variant="outlined"
                    type="number"
                  />
                )}
              />
              {errors.leadPrice && (
                <Typography color="red">{errors.leadPrice.message}</Typography>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="unitId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      multiple
                      loadingText={loadingUnits}
                      loading={loadingUnits}
                      id="tags-outlined"
                      onChange={(event, values) => onChange(values)}
                      value={value}
                      options={units || []}
                      getOptionLabel={(option) => option.name || option.nameAr}
                      isOptionEqualToValue={(option, value) =>
                        value === undefined ||
                        value === "" ||
                        option.name === value.name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="units of measurement"
                          placeholder="units of measurement"
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
              {errors.unitId && (
                <Typography color="red">{errors.unitId.message}</Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            style={{ margin: "20px 0px" }}
            direction={!is_small_screen ? "row" : "column-reverse"}
            alignItems="center"
          >
            <Button color="secondary" onClick={() => navigate(-1)}>
              {"Cancel"}
            </Button>
            <Button
              type="submit"
              startIcon={loading ? <CircularProgress size="1rem" /> : null}
              disabled={loading}
              variant="contained"
              style={{ width: is_small_screen && "100%", color: "#fff" }}
            >
              Save
            </Button>
          </Grid>
        </form>
      </Card>
    </>
  );
};
export default AddUpdateForm;
