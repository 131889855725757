import axios from "axios";
import { HTTP_STATUS_CODES } from "src/constants/HttpStatusCodes";
import { getJWTExpireTime } from "src/utils/get-jwt-expire-time";
import { ACCESS_TOKEN } from "src/constants/access-token";
import { REFRESH_TOKEN } from "src/constants/refresh_token";
import { USER_INFO } from "src/constants/user-info";
import { authService } from "./services/auth";
import { getAuthorization } from "src/utils/getAuthorization";

export const responseInterceptors = {
  successInterceptor(response) {
    return response;
  },
  async errorInterceptor(error) {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    const statusCode = error?.response?.status;

    if (statusCode) {
      switch (statusCode) {
        case HTTP_STATUS_CODES.CACHED_RESPONSE:
          return Promise.resolve(error.response.data);
        case HTTP_STATUS_CODES.VALIDATION_ERROR:
          console.error(error.response?.data?.meta?.message);
          break;
        case HTTP_STATUS_CODES.SERVER_ERROR:
          console.error(error.response?.data?.meta?.message);
          break;
        case HTTP_STATUS_CODES.NOT_FOUND:
          console.error(error.response?.data?.meta?.message);
          break;
        case HTTP_STATUS_CODES.UN_AUTHORIZED:
          console.error(error.response?.data?.meta?.message);
        case HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY:
          console.error(error.response?.data?.meta?.message);
          break;
        default:
          return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
};

export const requestInterceptors = {
  async beforeSent(config) {
    if (config?.url !== "/auth/login") {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);
      const token = localStorage.getItem(ACCESS_TOKEN);
      if (refreshToken && token) {
        const expTime = getJWTExpireTime(token);
        const alreadyFetching = sessionStorage.getItem("rFlag");
        if (expTime < Date.now() && !alreadyFetching) {
          try {
            sessionStorage.setItem("rFlag", "true");
            let result = await authService.refresh(refreshToken);
            sessionStorage.removeItem("rFlag");
            localStorage.setItem(
              REFRESH_TOKEN,
              result?.data?.data?.refresh_token
            );
            localStorage.setItem(
              ACCESS_TOKEN,
              result?.data?.data?.access_token
            );
            return {
              ...config,
              headers: {
                Authorization: "Bearer " + result?.data?.data?.access_token,
              },
            };
          } catch (error) {
            sessionStorage.removeItem("rFlag");
            localStorage.removeItem(USER_INFO);
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);
            window.location.reload();
            Promise.reject(error);
          }
        }
      }
    }
    if (!config.byRefreshToken) {
      config.headers = {
        ...config.headers,
        Authorization: getAuthorization("token"),
      };
    }
    return config;
  },
  onError(error) {
    return Promise.reject(error);
  },
};

