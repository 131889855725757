import React from "react";
import {
  Grid,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { useNavigate } from "react-router-dom";

const PageHeader = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid container justifyContent="flex-start" alignItems="center" flexWrap="nowrap">
        <Tooltip arrow placement="top" title={"Go back"}>
          <Button
            onClick={() => navigate(-1)}
            color="primary"
            sx={{
              p: { xs: 1, sm: 1, lg: 2, md: 2, xl: 2 },
              mr: { xs: 1, sm: 1, lg: 2, md: 2, xl: 2 },
            }}
          >
            <ArrowBackTwoToneIcon />
          </Button>
        </Tooltip>
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {"Book Request Detail Management"}
          </Typography>
          <Typography variant="subtitle2">
            {
              "All aspects related to the panel Book Request Detail can be managed from this page"
            }
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PageHeader;
