import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { authService } from 'src/api/services/auth';
// import useToast from 'src/hooks/useToast';
// import { errorMessage } from 'src/utils/errorTypeDetector';

import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  styled,
  Card,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Logo from "./components/Logo";
import { forgetPasswordValidationSchema } from "./forget-password-validation-schema";
import { adminService } from "src/api/services/admin";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";

const ResetPassword = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "all",
    resolver: yupResolver(forgetPasswordValidationSchema()),
  });
  const { toast } = useToast();
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);


  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await adminService.forgotPassword(data);
      setEmailSent(true);
      setLoading(false);
      reset();
      toast("Email sent successfully", "success");
    } catch (err) {
      setLoading(false);
      toast(errorMessage(err?.message), "error");
    }
  };

  if (emailSent)
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          style={{
            maxWidth: "100%",
            width: "400px",
          }}
        >
          <StyledNotificationBox>
            <CheckCircleOutlineIcon
              style={{ width: "70px", height: "70px", color: "#82d271" }}
            />
            <Typography
              mt={2}
              variant="h2"
              style={{
                marginBottom: "20px",
                textAlign: "center",
                color: "#82d271",
              }}
            >
              Success!
            </Typography>
            <Typography
              variant="h4"
              style={{ marginBottom: "20px", textAlign: "center" }}
            >
              Email has been send successfully!
            </Typography>
          </StyledNotificationBox>
        </Box>
      </Box>
    );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        style={{
          maxWidth: "100%",
          width: "400px",
        }}
      >
        <Logo />
        <Typography
          variant="h4"
          style={{ marginBottom: "20px", textAlign: "center" }}
        >
          Reset your password
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Enter your user account verified email address and we will send you a
          password reset link.
        </Typography>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                autoFocus
                label="Enter your email address"
                name="email"
                type="email"
                variant="outlined"
              />
            )}
          />
          {errors.email && (
            <Typography color="red">{errors.email.message}</Typography>
          )}
          <Button
            sx={{
              mt: 3,
            }}
            style={{color:'white'}}
            color="primary"
            startIcon={loading ? <CircularProgress size="1rem" /> : null}
            disabled={loading}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {"Send password reset email"}
          </Button>
        </form>
      </Box>
    </Box>
  );
};
export default ResetPassword;

const StyledNotificationBox = styled(Card)(
  () => `
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
  `
);
