import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordValidationSchema } from "./reset-password-validation-schema";
import { authService } from "src/api/services/auth";
import useToast from "src/hooks/useToast";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { errorMessage } from "src/utils/errorTypeDetector";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import Logo from "./components/Logo";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      confirmPassword: "",
      password: "",
    },
    mode: "all",
    resolver: yupResolver(resetPasswordValidationSchema()),
  });

  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const secRef = useRef(null);

  const getSecret = () => {
    const queryParams = new URLSearchParams(search)
    const secret = queryParams.get("token")
    secRef.current = secret
}

  useEffect(() => {
    getSecret();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    const bearerToken = secRef.current;
    if (bearerToken === null) return;
    let body = { password: data.password };

    try {
      setLoading(true);
      await authService.resetPassword(body, bearerToken);
      toast("Password successfully changed", "success");
      navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE, { replace: true });
    } catch (err) {
      setLoading(false);
      toast(errorMessage(err), "error");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        style={{
          maxWidth: "100%",
          width: "400px",
        }}
      >
        <Logo />
        <Typography
          variant="h4"
          style={{ marginBottom: "20px", textAlign: "center" }}
        >
          Reset your password
        </Typography>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label={"Password"}
                name="password"
                type="password"
                variant="outlined"
              />
            )}
          />
          {errors.password && (
            <Typography color="red">{errors.password.message}</Typography>
          )}
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                margin="normal"
                label={"confirmPassword"}
                name="confirmPassword"
                type="password"
                variant="outlined"
              />
            )}
          />
          {errors.confirmPassword && (
            <Typography color="red">
              {errors.confirmPassword.message}
            </Typography>
          )}
          <Button
            sx={{
              mt: 3,
            }}
            style={{color:'white'}}
            color="primary"
            startIcon={loading ? <CircularProgress size="1rem" /> : null}
            disabled={loading}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {"Submit"}
          </Button>
        </form>
      </Box>
    </Box>
  );
};
export default ResetPassword;
