import React, { useState, useEffect } from "react";
import { adminService } from "src/api/services/admin";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Card,
  CardContent,
  Divider,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import Text from "src/components/Text";
import { Link, useNavigate, useParams } from "react-router-dom";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { convertTimezone, diffDateTime } from "src/utils/FormatDate";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { useCallback } from "react";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const BidDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const permissions = usePermission();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { toast } = useToast();

  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  const getDetail = useCallback(async () => {
    try {
      let response = await adminService.getBidDetail(id);
      setDetail(response?.data?.data);
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast(errorMessage(err), "error");
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDetail]);

  return (
    <>
      <Grid
        container
        padding={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Tooltip arrow placement="top" title={"Go back"}>
          <Button
            onClick={() => navigate(-1)}
            color="primary"
            sx={{
              p: 2,
              mr: 2,
            }}
          >
            <ArrowBackTwoToneIcon />
          </Button>
        </Tooltip>
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {"Bid Detil Page"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        p={2}
        direction={!isMediumScreen ? "row" : "column-reverse"}
      >
        <Grid xs={12} sm={12} md={6} lg={6} xl={6} item p={2}>
          <Card style={{ paddingBottom: "10px" }}>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  {"Detail "}
                </Typography>
                <Typography variant="subtitle2">
                  {"Manage details related to bid"}
                </Typography>
              </Box>
            </Box>
            <Divider
              style={loading ? { marginBottom: "10px" } : { marginBottom: 0 }}
            />
            {loading ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
                <Typography>Waite until detail is loaded.</Typography>
              </Box>
            ) : (
              <CardContent
                sx={{
                  p: 4,
                }}
              >
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                    >
                      <Box pr={2} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Bid ID:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.id}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      mt={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Bid Amount(KD):"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>
                          {detail?.amount +
                            " " +
                            detail?.unitOfMeasurement?.name}
                        </b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      mt={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Bid Decription:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.description}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      mt={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Pro Name:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.agentName}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      mt={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Phone Number:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.agentPhoneNumber}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mt={2}
                      mb={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Created At:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>
                          {detail?.createdAt
                            ? convertTimezone(detail?.createdAt)
                            : ""}
                        </b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mt={2}
                      mb={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Expire Date:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>
                          {detail?.bookExpiresAt
                            ? diffDateTime(detail.bookExpiresAt)
                            : ""}
                        </b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      mt={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Book ID:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <Link
                          // to={`${
                          //   ROUTE_CONSTANTS.DASHBOARD.BOOKING.BOOKREQUESTS.GET_BY_DATA(
                          //     detail?.bookId
                          //   ).ABSOLUTE
                          // }?query=${createHash({
                          //   redirectFrom: "bid-detail",
                          //   bidId: id,
                          // })}`}
                          to={
                            permissions[PERMISSIONS.Get_Book_Detail]
                              ? ROUTE_CONSTANTS.DASHBOARD.BOOKING.BOOKREQUESTS.DETAILS.GET_BY_DATA(
                                  detail?.bookId
                                ).ABSOLUTE
                              : "#"
                          }
                          style={{ textDecoration: "none", color: "#14C38E" }}
                        >
                          <b>{detail?.bookId}</b>
                        </Link>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      mt={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Call Recieved:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.callReceived ? 1 : 0}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mt={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {" "}
                          {"Message Recieved:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.messageReceived ? 1 : 0}</b>
                      </Text>
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default BidDetail;
