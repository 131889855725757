import React from "react";
import { Link, useParams } from "react-router-dom";
import { adminService } from "src/api/services/admin";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import AddUpdateForm from "./components/AddUpdateForm";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";

const AddUpdatePage = () => {
  const { id } = useParams();
  const { toast } = useToast();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const onCreate = async (formData) => {
    try {
      await adminService.createQuestionnaire(formData);
      toast(`Questionnaire created successfully!`, "success");
    } catch (error) {
      toast(errorMessage(error), "error");
    }
  };

  const onUpdate = async (formData) => {
    try {
      await adminService.updateQuestionnaire(id, formData);
      toast(`Questionnaire update successfully!`, "success");
    } catch (error) {
      toast(errorMessage(error), "error");
    }
  };

  return (
    <>
      <Grid
        container
        padding={2}
        justifyContent="flex-start"
        alignItems="center"
        className="mmm"
      >
        <Tooltip arrow placement="top" title={"Go back"}>
          <Link to={ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.ROOT.ABSOLUTE}>
            <IconButton
              color="primary"
              sx={{
                p: { xs: 0, sm: 0, lg: 2, md: 2, xl: 2 },
                mr:  { xs: 0, sm: 0, lg: 2, md: 2, xl: 2 },
              }}
            >
              <ArrowBackTwoToneIcon />
            </IconButton>
          </Link>
        </Tooltip>
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {!id ? "Add new questionnaire" : "Update questionnaire"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        p={2}
        direction={!isMediumScreen ? "row" : "column-reverse"}
        style={{ height: `calc(100vh - 150px)` }}
        width='100%'
        maxWidth="100%"
      >
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          item
          p={2}
          style={{ height: "100%" }}
        >
          <AddUpdateForm
            targetAPI={!id ? onCreate : onUpdate}
            subTitile={
              !id
                ? "Fill out form below to add your new Questionnaire"
                : "Fill out form below to update your Questionnaire"
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddUpdatePage;
