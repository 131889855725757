import React, { useState, useEffect } from "react";
import { Grid, Box, CircularProgress } from "@mui/material";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "./components/PageHeader";
import Results from "./components/Results";
import { adminService } from "src/api/services/admin";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";
import Footer from "src/components/layouts/AccentSidebarLayout/Footer";
const Administration = () => {
  const permissions = usePermission();
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [perms, setPerms] = useState(false);

  const getPermissions = async () => {
    if (!permissions[PERMISSIONS.Role_Controller]) return;
    try {
      let response = await adminService.getPermissions();
      setPerms(response?.data?.data.items);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast(errorMessage(error), "error");
      console.log(error);
    }
  };

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        sx={{
          px: { xs: 1, lg: 4, xl: 4, md: 4, sm: 1 },
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          {loading ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            perms && <Results resultData={perms} />
          )}
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Administration;
