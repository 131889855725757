import * as Yup from 'yup';

export const resetPasswordValidationSchema = () => Yup.object().shape({
    password: Yup.string()
        .min(8)
        .max(255)
        .required('The password field is required'),
    confirmPassword: Yup.string()
        .required('The confirm password field is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
})