import { string, object } from "yup";

export const loginValidationSchema = () => object().shape({
    email: string('The email provided should be a valid email address')
    .max(255)
    .required('The email field is required'),
    password: string()
    .min(8)
    .max(255)
    .required('The password field is required'),
})