import React, { useState, useEffect, useRef, useCallback } from "react";
import { Grid, Box, CircularProgress } from "@mui/material";
// import BookDetaile from "../../../../../../components/Profile/BookDetaile";
import Results from "./components/Result";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { adminService } from "src/api/services/admin";
import { useSearchDebounce } from "src/hooks/useSearchDebounce";
import { useLocation } from "react-router-dom";
import { useSortDebounce } from "src/hooks/useSortDebounce";

const BookHistory = () => {
  const { toast } = useToast();
  const location = useLocation();
  const paths = location?.pathname?.split("/");
  const customerId = paths[paths.length - 1];
  const { handleChangeSearch, query } = useSearchDebounce();
  const { handleChangeSort, order } = useSortDebounce();
  const isSerchedRef = useRef(false);

  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);

  //   const [open, setOpen] = useState(false);
  //   const [selectedBook, setSelectedBook] = useState(null);

  // const handleClickOpen = (data) => {
  //     setOpen(true);
  //     setSelectedBook(data);
  // };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  const getUserHistories = useCallback(async () => {
    if (query && isSerchedRef.current && page !== 0) {
      return true;
    }

    let params = {
      take,
      skip: page,
      userId: customerId,
    };

    if (query) params.search = query;
    else delete params.search;

    if (order) params.order = JSON.stringify(order);
    else delete params.order;
    try {
      const response = await adminService.getBookRequestList(params);
      isSerchedRef.current = false;
      setLoading(false);
      setHistories(response?.data?.data?.items);
      setTotalCount(response?.data?.data?.count);
    } catch (error) {
      isSerchedRef.current = false;
      toast(errorMessage(error), "error");
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, take, query, order]);

  useEffect(() => {
    setPage(0);
    isSerchedRef.current = true;
  }, [query]);


  useEffect(() => {
    getUserHistories();
  }, [getUserHistories]);

  const handleChangePage = (page) => {
    if (page >= 0) setPage(page);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "92vh",
      }}
    >
      {/* <PageTitleWrapper>
        <Box>
          <Typography variant="h4" gutterBottom>
            {"Book History Details"}
          </Typography>
          <Typography variant="subtitle2">
             {"Manage information related to book history"}
          </Typography>
        </Box>
      </PageTitleWrapper> */}
      <Grid
        sx={{
          px: { xs: 0.5, sm: 0.5, md: 3, xl: 4, lg: 4 },
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          {loading ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Results
              resultData={histories || []}
              pagination={{
                page,
                take,
                totalCount: totalCount,
              }}
              actions={{
                changePage: (page) => handleChangePage(page),
                changeTake: (take) => setTake(take),
                handleSearchCustomerBookHistory: handleChangeSearch,
                handleSortCustomerBookHistory: handleChangeSort,
              }}
            />
          )}
        </Grid>
      </Grid>
      {/* <BookDetaile data={selectedBook} open={open} handleClose={handleClose} /> */}
    </Box>
  );
};

export default BookHistory;
