import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button,
  Autocomplete,
  TextField,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import UpdateModal from "src/components/Profile/UpdateModal";
import Text from "src/components/Text";
import { convertArrayToStringName } from "src/utils/convertArrayToStringName";
import { adminService } from "src/api/services/admin";
import { errorMessage } from "src/utils/errorTypeDetector";
import { Controller } from "react-hook-form";
import * as Yup from "yup";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import useToast from "src/hooks/useToast";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Services = ({ user, handleRefreshData }) => {
  const classes = useStyles();
  const { toast } = useToast();
  const permissions = usePermission();

  const [modalShow, setModalShow] = useState(false);
  const [services, setServices] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleChangeModalShow = (bool) => {
    if (!bool) setModalShow((prevShow) => !prevShow);
    else setModalShow(bool);
  };

  // const checkOption = (option) => {
  //   const check = selectedServices.some((c) => c.checked === option.checked);
  //   return check;
  // };

  const getCategoriesWithServices = useCallback(async () => {
    try {
      const response = await adminService.getCategoriesWithServices(user?.id);
      const result = response.data?.data?.items
        ?.map((item) =>
          item?.services?.flat()?.map((item) => {
            return {
              group: item?.category?.name,
              code: item?.id,
              label: item?.name,
              checked: item?.isSelected,
            };
          })
        )
        ?.filter((item) => item.length > 0)
        ?.flat();
      setServices(result);
      let selectedServices = result?.length
        ? result.filter((item) => item.checked)
        : [];
      setSelectedServices(selectedServices);
    } catch (error) {
      console.error(error);
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateProServices = async (_values) => {
    if(!permissions[PERMISSIONS.Update_Pro_Services]) return;

    const data = { ..._values };
    const ids = data?.services?.map((item) => item.code);
    const selectedServicesFromResponse = services.filter(service=>service.checked);

    let deletedAndAddedService = {
      toAdd: [],
      toDelete: [],
    };
    if (!selectedServicesFromResponse.length) {
      deletedAndAddedService.toAdd = [...ids];
    } else {
      let currentServiceId = selectedServicesFromResponse?.map((item) => item.code);
      let newItemAdded = ids.filter(
        (item) => !currentServiceId.includes(item)
      );
      let deletedItem = currentServiceId.filter(
        (item) => !ids.includes(item)
      );

      deletedAndAddedService.toAdd = [...newItemAdded];
      deletedAndAddedService.toDelete = [...deletedItem];
    }
    try{
      await adminService.updateProServices(user?.id,deletedAndAddedService);
      toast('You change services successfully.');
      handleChangeModalShow(false);
      handleRefreshData();
    }catch(error){
      console.error(error);
      toast(errorMessage(error),'error');
    }finally{

    }
  };

  // const checkGroup = (group) => {
  //   const groupLength = services.filter((c) => c.group === group).length;
  //   const selectedGroupLength = selectedServices.filter(
  //     (c) => c.group === group
  //   ).length;
  //   return groupLength === selectedGroupLength;
  // };

  const unselectOption = (code,onChange) => {
    setSelectedServices((prevState) =>
      prevState.filter((c) => c.code !== code)
    );
    onChange(selectedServices.filter(c=>c.code !==code));
  };
  // const selectGroup = (group) => {
  //   const groupedServices = services.filter((c) => c.group === group);
  //   const selectedGroupServices = selectedServices.filter(
  //     (c) => c.group === group
  //   );
  //   if (selectedGroupServices.length > 0) {
  //     setSelectedServices((prevState) => [
  //       ...prevState.filter((c) => c.group !== group),
  //     ]);
  //   } else {
  //     setSelectedServices((prevState) => [...prevState, ...groupedServices]);
  //   }
  // };

  const disabledOption = (option) => {
    if (selectedServices.length === 3) {
      const codes = selectedServices.map((i) => i.code);
      const notSelectedOptions = services.filter(
        (item) => !codes.includes(item.code)
      );
      return (
        notSelectedOptions.find(
          (notSelectedOption) => notSelectedOption.code === option.code
        ) !== undefined
      );
    }
  };

  useEffect(() => {
    getCategoriesWithServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCategoriesWithServices]);

  return (
    <Card>
      <Box
        p={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            {"Pro Services"}
          </Typography>
          <Typography variant="subtitle2">
            {"View Pro Services List"}
          </Typography>
        </Box>
        <Button
          variant="text"
          onClick={() => handleChangeModalShow(true)}
          startIcon={<EditTwoToneIcon />}
          disabled ={!permissions[PERMISSIONS.Update_Pro_Services]}
        >
          {"Edit"}
        </Button>
      </Box>
      <Divider />
      <CardContent
        sx={{
          p: 4,
        }}
      >
        <Typography variant="subtitle2">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
              <Box pr={3} pb={2}>
                {"Services"}:
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Text color="black">
                <b>
                  {user?.services?.length > 0 &&
                  convertArrayToStringName(user.services)
                    ? convertArrayToStringName(user.services).slice(1)
                    : "" || "-"}
                </b>
              </Text>
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
      {
        services && <UpdateModal
        show={modalShow}
        onHide={() => handleChangeModalShow(false)}
        onCancel={() => handleChangeModalShow(false)}
        title="Services Info"
        subtitle="You can change pro services"
        buttonText="Update"
        onSubmitCustomAction={handleUpdateProServices}
        validationSchema={Yup.object().shape({
          services: Yup.array()
            .max(3)
            .min(1)
        })}
        initialValues={{
          services: selectedServices || []
        }}
        fields={(errors, control) => (
          <>
            <Grid item>
              <Controller
                name="services"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    id="services-select-demo"
                    {...field}
                    className={classes.autocomplete}
                    classes={{
                      option: classes.option,
                    }}
                    options={services}
                    onChange={(_, option) => {
                      setSelectedServices([...option]);
                     field.onChange( option);
                    }}
                    value={selectedServices}
                    inputValue={inputValue}
                    autoHighlight
                    getOptionDisabled={(option) => disabledOption(option)}
                    multiple
                    disableCloseOnSelect
                    getOptionSelected={(option, value) =>
                      option.code === value.code
                    }
                    getOptionLabel={(option) => option.label}
                    groupBy={(option) => option.group}
                    onInputChange={(e, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <>
                        <li {...props} key={option.code}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      </>
                    )}
                    // renderGroup={(params) => (
                    //   <>
                    //     {/* <span key={params.key}>
                    //       <Checkbox
                    //         icon={icon}
                    //         checkedIcon={checkedIcon}
                    //         style={{ marginRight: 8 }}
                    //         checked={checkGroup(params.group)}
                    //         onChange={() => selectGroup(params.group)}
                    //       />
                    //       {params.group}
                    //     </span> */}
                    //     <Accordion>
                    //       <AccordionSummary
                    //         expandIcon={<ExpandMoreIcon />}
                    //         aria-controls="panel1a-content"
                    //         id="panel1a-header"
                    //       >
                    //         <Typography>{params.group}</Typography>
                    //       </AccordionSummary>
                    //       <AccordionDetails>
                    //         {params.children}
                    //       </AccordionDetails>
                    //     </Accordion>
                    //   </>
                    // )}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Choose a service"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      );
                    }}
                    renderTags={() => {
                      return selectedServices.map((tag) => (
                        <Button
                          size="small"
                          variant="contained"
                          key={tag.code}
                          style={{color:'white', marginTop:'5px'}}
                          endIcon={<CancelIcon />}
                          onClick={() => unselectOption(tag.code,field.onChange)}
                        >
                          {tag.label}
                        </Button>
                      ));
                    }}
                  />
                )}
              />
              {errors.services && (
                <Typography color="red">{errors.services.message}</Typography>
              )}
            </Grid>
          </>
        )}
      />
      }
    </Card>
  );
};
export default Services;

const useStyles = makeStyles({
  autocomplete: {
    width: "300px",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
