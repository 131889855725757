import { AxiosInstance, RawAxiosInstance } from "../index";

export const authService = {
  login(email, password) {
    return AxiosInstance.post("/auth/login", {
      email,
      password,
    });
  },
  logout() {
    return AxiosInstance.get("/auth/logout");
  },
  resetPassword(body, token) {
    return RawAxiosInstance.post("auth/reset-password", body, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  refresh(refresh_token) {
    return AxiosInstance.get(
      'auth/refresh',
      {
        byRefreshToken:true,
        headers: {
            Authorization: `Bearer ${refresh_token}`,
        },
      }
    );
  },
  getProfile: () => AxiosInstance.get("admin/auth/me"),
};
