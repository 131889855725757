import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { adminService } from "src/api/services/admin";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { useUser, useSetUser, useSetPermission } from "src/contexts/GlobalContext";

const GeneralLayoutWrapper = ({ children }) => {
  const location = useLocation();
  const user = useUser();
  const setUser = useSetUser();
  const setPermission = useSetPermission();

  // ***** get user profile on every refresh.
  const getMe = async () => {
    try {
      let userInfoResponse = await adminService.getUserInfo();
      const {permissions,...rest} = userInfoResponse.data.data;
      setUser(rest);
      const currentUserPermissions = {};
      for(let p of permissions){
        currentUserPermissions[p] = true;
      }
      setPermission(currentUserPermissions);
    } catch (error) {
      // ******* if user be deleted or any things happened not normal, api will respond me with 403 error
      // ******* handle logout user here on 403 error
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user)
    return (
      <Navigate
        to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE}
        state={{ previousPath: location.pathname }}
        replace
      />
    );

  return children;
};
export default GeneralLayoutWrapper;
