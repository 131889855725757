import React, { useEffect, useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import { sidebarRoutesObjects } from "./SidebarRoutesObjects";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SidebarRoutes from "./SidebarRoutes";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const SidebarCategories = ({ category, Icon }) => {
  const permissions = usePermission();
  const [isOpen, setIsOpen] = useState(false);
  const [routeHight, setRouteHight] = useState(0);

  const menuItemsGenerator = () => {
    let tempMenuItems = [];

    Object.keys(sidebarRoutesObjects)
      .filter(
        (route) => sidebarRoutesObjects[route].categorySlug === category.slug
      )
      .forEach((route) => {
        if (permissions[PERMISSIONS[sidebarRoutesObjects[route].permissionKey]])
        tempMenuItems.push(sidebarRoutesObjects[route]);
      });

    return tempMenuItems;
  };

  useEffect(() => {
    if (isOpen) setRouteHight(menuItemsGenerator().length * 35);
    else setRouteHight(0);
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <SidebarWrapper isopen={isOpen ? "true" : "false"} routehight={routeHight}>
      <Box
        className="header"
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Icon className="icon" />
          <Typography className="category-name" ml={1}>
            {category.name}
          </Typography>
        </Box>
        {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
      </Box>
      <Box className="body">
        {menuItemsGenerator().map((route, index) => (
          <SidebarRoutes key={index} route={route} Icon={route.icon} />
        ))}
      </Box>
    </SidebarWrapper>
  );
};

export default SidebarCategories;

const SidebarWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    padding-left: 10px;
    cursor: pointer;
    background: "#31DDA1";
    margin-bottom:2px;
    color: ${(props) => props.isopen === "true" && "#182057"};
    :hover {
      background: #31dda1;
      color: #182057;
    }
    .active {
      background: ${(props) => props.isOpen === "true" && "#31dda1"};
      color: #182057;
    }
    .category-name {
      font-size: 13px;
      font-weight: 600;
      color: #182057;
    }
    .icon {
      color: #182057;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: ${(props) =>
      props.isopen === "true" ? `${props.routehight}px` : "0px"};
    transition: height 0.3s;
  }
`;
