import { useEffect, useState } from "react";
import { TextField, Typography, Box, Card, styled } from "@mui/material";
import CustomButton from "src/components/CustomButton";
import { Editor as ReactEditor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { useStaticPages } from "../../EditStaticPagesContext";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useToast from "src/hooks/useToast";
import { adminService } from "src/api/services/admin";
import { errorMessage } from "src/utils/errorTypeDetector";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const Editor = ({ title, editorValue, language }) => {
  const { toast } = useToast();
  const { activePage, setPages, pages } = useStaticPages();
  const navigate = useNavigate();
  const permissions = usePermission();

  const [titleInputValue, setTitleInputValue] = useState(title);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(editorValue))
    )
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(editorValue))
      )
    );
  }, [editorValue]);

  useEffect(() => {
    setTitleInputValue(title);
  }, [title]);

  const onTitleInputChanged = (e) => {
    setTitleInputValue(e.target.value);
  };

  const onEditorStateChanged = (e) => {
    setEditorState(e);
  };

  const onUpdateClicked = async () => {
    if (!permissions[PERMISSIONS.Update_Static_Page]) return;
    setLoading(loading);

    let htmlContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    try {
      setLoading(true);
      const response = await adminService.editStaticPage(activePage?.id, {
        [language.slug === "EN" ? "html" : "htmlAr"]: htmlContent,
        [language.slug === "EN" ? "title" : "titleAr"]: titleInputValue,
      });
      let copyPages = [...pages];
      let findIndex = pages.findIndex((p) => p.id === response.data.data.id);
      copyPages[findIndex] = response.data.data;
      setPages(copyPages);
      toast("changed successfully", "success");
    } catch (err) {
      console.log(err);
      toast(errorMessage(err), "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      pt={{ xs: 1, sm: 1, md: 4, lg: 2, xl: 2 }}
      pr={{ xl: 8, lg: 8, md: 4, sm: 0, xs: 0 }}
      pl={{ xl: 8, lg: 8, md: 4, sm: 0, xs: 0 }}
    >
      <Typography variant="button">{`Edit ${activePage?.slug}(${
        language.slug === "EN" ? "English" : "Arabic"
      })`}</Typography>
      <StyledEditorCard>
        <Box className="title-wrapper">
          <Box>
            <TextField
              label="Edit title"
              variant="standard"
              style={{ width: "200px" }}
              value={titleInputValue}
              onChange={onTitleInputChanged}
            />
          </Box>
        </Box>
        <Box className="editor-wrapper">
          <ReactEditor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChanged}
          />
        </Box>
        <Box className="actions-wrapper">
          <Box />
          <CustomButton
            text="Visit "
            onClick={() =>
              navigate(
                ROUTE_CONSTANTS.PUBLIC_ROUTES.STATIC_PAGES.GET_BY_DATA(
                  activePage?.slug.split(" ").join("_"),
                  language?.slug
                ).ABSOLUTE
              )
            }
            style={{ marginRight: "10px" }}
          />
          <CustomButton
            text="Save"
            loading={loading === activePage?.id + language?.slug + "body"}
            disabled={loading === activePage?.id + language?.slug + "body"}
            onClick={onUpdateClicked}
          />
        </Box>
      </StyledEditorCard>
    </Box>
  );
};

export default Editor;

const StyledEditorCard = styled(Card)(
  () => `
        width: 100%;
        margin-bottom: 3rem;
        margin-top: 1rem;
        padding: 10px;
        .actions-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
        .editor-wrapper{
            margin-top: 30px;
            min-height: 300px;
        }
        .title-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            .actions-wrapper{
                .title-edit-button{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #14C38E;
                    margin-left: 8px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        
    `
);
