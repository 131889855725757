import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles({
    fadedRow: {
        background: "white",
        opacity: 0.5,
        position: "relative",
    },
    spinner: {
        position: "absolute",
        right: "50%",
        bottom: "50%",
    },
    tableRow: {
        position: 'unset'
    },
    findingContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    findingSpinner: {
        marginRight: '15px'
    }
});