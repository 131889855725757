import { Switch, Box } from "@mui/material";
import { useState } from "react";
import { adminService } from "src/api/services/admin";
import useToast from "src/hooks/useToast";

const ToggleActivation = ({ data, targetApi, disabled }) => {
  const { toast } = useToast();
  const [isTypeDisabled, setIsTypeDisabled] = useState(data.isActive);
  const [, setLoading] = useState(false);

  const handleToggle = async () => {
    const tempData = {
      isActive: !isTypeDisabled,
    };

    try {
      setLoading(true);
      if (targetApi == "activationQuestion") {
        await adminService[targetApi](data.questionnaireId, data.id, tempData);
      } else {
        await adminService[targetApi](data.id, tempData);
      }
      toast(
        `${data?.question?.toUpperCase() || data?.name?.toUpperCase()} ${
          !isTypeDisabled ? "Activate" : "Deactivate"
        } successfully!`,
        "success"
      );
      setIsTypeDisabled((toggle) => !toggle);
      setLoading(false);
    } catch (error) {
      toast(`Error happened in toggle type`, "error");
      setLoading(false);
    }
  };

  return (
    <Box>
      <Switch
        disabled={disabled}
        checked={isTypeDisabled}
        onChange={(e) => handleToggle(e)}
        inputProps={{ "aria-label": "controlled" }}
      />
    </Box>
  );
};

export default ToggleActivation;
