import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const DynamicForm = ({ initialValues, validationSchema, onSubmitCustomAction, fields }) => {
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: initialValues,
        mode: "all",
        resolver: yupResolver(validationSchema),
    });
    return (
        <form noValidate onSubmit={handleSubmit(onSubmitCustomAction)}>
            {fields(errors, control, handleSubmit)}
        </form>
    )
}

export default DynamicForm;