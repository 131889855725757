import React, { useState } from "react";
import {
  Box,
  Card,
  TablePagination,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  IconButton,
  Tooltip,
  Switch,
  styled,
  TextField,
  InputAdornment,
  TableSortLabel,
  CircularProgress,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
// import { staticImages } from "src/assets/images";
import { imageURLCombiner } from "src/utils/imageUrlCombiner";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import { Link } from "react-router-dom";
import { adminService } from "src/api/services/admin";
import useToast from "src/hooks/useToast";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "src/components/ConfirmModal";
import { useStyles } from "src/pages/dashboard/styles/main";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "id",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name(En)",
  },
  {
    id: "nameAr",
    numeric: false,
    disablePadding: false,
    label: "Name(Ar)",
  },
];

const Results = ({ resultData, pagination, actions, loading }) => {
  const classes = useStyles();
  const permissions = usePermission();

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState({ id: false, name: false, nameAr: false });
  const [confirmModal, setConfirmModal] = useState({ show: false, data: null });
  const handlePageChange = (_event, newPage) => {
    actions.changePage(newPage);
  };

  const handleLimitChange = (event) => {
    actions.changeTake(parseInt(event.target.value));
  };

  const handleDelete = () => {
    actions.onDelete(confirmModal.data);
  };

  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    setOrder((prev) => {
      return {
        ...prev,
        [property]: !prev[property],
      };
    });
    actions.handleSortService({ [property]: order[property] ? 1 : -1 });
  };

  return (
    <Card>
      <Box p={2}>
        <TextField
          sx={{
            m: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          onChange={actions.handleSearchService}
          placeholder={"Search by name ..."}
          size="small"
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </Box>
      <Divider />
      {resultData && resultData?.length === 0 ? (
        <>
          <Typography
            sx={{
              py: 10,
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {loading ? (
              <div className={classes.findingContainer}>
                <CircularProgress className={classes.findingSpinner} />
                trying to find data , Please waite !
              </div>
            ) : (
              "We couldn't find any service matching your search criteria"
            )}
          </Typography>
        </>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={"left"}
                      padding={"normal"}
                      sortDirection={true}
                    >
                      <TableSortLabel
                        active={true}
                        direction={
                          orderBy === headCell.id &&
                          order[headCell.id] !== false
                            ? "asc"
                            : "desc"
                        }
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell>{"Expire Time"}</TableCell>
                  <TableCell align="center">{"Photo"}</TableCell>
                  <TableCell>{"Active"}</TableCell>
                  <TableCell align="center">{"Update"}</TableCell>
                  {
                    permissions[PERMISSIONS.Revoke_Service] && <TableCell>{"Delete"}</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody
                className={loading ? classes.fadedRow : classes.tableRow}
              >
                <TableRow>
                  <TableCell>
                    <div className={classes.spinner}>
                      {loading && <CircularProgress />}
                    </div>
                  </TableCell>
                </TableRow>

                {resultData.map((data) => {
                  return (
                    <TableRow key={data?.id}>
                      <TableCell>{data.id}</TableCell>
                      <TableCell>
                        <Typography fontWeight="700">{data?.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="700">{data?.nameAr}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="700">
                          {data?.expirationTime}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {data.media?.path && (
                          <StyledImage
                            alt={`service-${data?.name}-photo`}
                            src={imageURLCombiner(data?.media?.path)}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <ListActiveStatus data={data} />
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap>
                          <Typography noWrap>
                            <Tooltip title={"View"} arrow>
                              <Link
                                to={
                                  permissions[PERMISSIONS.Update_Service]
                                    ? {
                                        pathname:
                                          ROUTE_CONSTANTS.DASHBOARD.CATALOG.TYPE.UPDATE.EDIT_BY_DATA(
                                            data.id
                                          ).ABSOLUTE,
                                      }
                                    : "#"
                                }
                              >
                                <IconButton color="primary">
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          </Typography>
                        </Typography>
                      </TableCell>
                      {permissions[PERMISSIONS.Revoke_Service] && (
                        <TableCell>
                          <DeleteIcon
                            onClick={() =>
                              permissions[PERMISSIONS.Revoke_Service] &&
                              setConfirmModal({ show: true, data: data })
                            }
                            style={{ color: "red", cursor: "pointer" }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={pagination.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={pagination.page}
              rowsPerPage={pagination.take}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </>
      )}
      <ConfirmModal
        show={confirmModal.show}
        onHide={() => setConfirmModal({ show: false, data: null })}
        action={handleDelete}
        actionTitle="service"
      />
    </Card>
  );
};

export default Results;

const ListActiveStatus = ({ data }) => {
  const { toast } = useToast();
  const permissions = usePermission();
  const [isTypeDisabled, setIsTypeDisabled] = useState(data.isActive);
  const [, setLoading] = useState(false);

  const handleToggle = async () => {
    if (!permissions[PERMISSIONS.Update_Service]) return;
    const tempData = {
      isActive: !isTypeDisabled,
    };

    try {
      setLoading(true);
      await adminService.updateService(data.id, tempData);
      toast(
        `${data.name.toUpperCase()} ${
          !isTypeDisabled ? "Activate" : "Deactivate"
        } successfully!`,
        "success"
      );
      setIsTypeDisabled((toggle) => !toggle);
      setLoading(false);
    } catch (error) {
      toast(`Error happened in toggle type`, "error");
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Box>
      <Switch
        disabled={!permissions[PERMISSIONS.Update_Service]}
        checked={isTypeDisabled}
        onChange={(e) => handleToggle(e)}
        inputProps={{ "aria-label": "controlled" }}
      />
    </Box>
  );
};

const StyledImage = styled("img")`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
`;
