import { useState } from "react";
import { Grid, Box } from "@mui/material";
import Results from "./components/Results";
import { useSearchDebounce } from "src/hooks/useSearchDebounce";
import { useSortDebounce } from "src/hooks/useSortDebounce";

const Books = ({id, data}) => {
  const { handleChangeSearch, query } = useSearchDebounce();
  const { handleChangeSort, order } = useSortDebounce();
  const [totalCount, setTotalCount] = useState(data?.length);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);

  const handleChangePage = (page) => {
    if (page >= 0) setPage(page);
  };


  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "92vh",
      }}
    >
      <Grid
        sx={{
          px: { sm: 1, xs: 1, md: 3, xl: 4, lg: 4 },
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
            <Results
              resultData={data || []}
              pagination={{
                page,
                take,
                totalCount: totalCount,
              }}
              actions={{
                changePage: (page) => handleChangePage(page),
                changeTake: (take) => setTake(take),
                handleSearchBooks: handleChangeSearch,
                handleSortBooks: handleChangeSort,
              }}
            />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Books;
