import { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import CreateAdmin from "./CreateAdminForm";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";
import { makeStyles } from "@mui/styles";

const PageHeader = ({ reload }) => {
  const classes = useStyles();
  const permissions = usePermission();

  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => setOpenDialog(false);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {"Admin Management"}
          </Typography>
          <Typography variant="subtitle2">
            {
              "All aspects related to the panel users can be managed from this page"
            }
          </Typography>
        </Grid>
        {permissions[PERMISSIONS.Create_New_Admin] && (
          <Grid item className={classes.header_create_admin}>
            <Button
              sx={{
                mt: { xs: 2, sm: 0 },
              }}
              style={{ color: "#fff" }}
              onClick={() => setOpenDialog(true)}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
            >
              {"Create admin"}
            </Button>
          </Grid>
        )}
        <CreateAdmin
          handleClose={handleCloseDialog}
          open={openDialog}
          reload={reload}
        />
      </Grid>
    </>
  );
};

export default PageHeader;

const useStyles = makeStyles((theme) => ({
  header:{
    [theme.breakpoints.down("md")]: {
      padding: '25px'
    },
  },
  header_create_admin: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px !important",
      width: "100%",
      textAlign: "right",
    },
  },
}));
