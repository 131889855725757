import React from "react";
import {
  Box,
  Card,
  Divider,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import Text from "src/components/Text";

const LeadsAndPendingPayments = ({ user }) => {
  return (
    <Card>
      <Box
        p={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            {"Pro Lead History"}
          </Typography>
          <Typography variant="subtitle2">
            {"View Pro Leads and Pending Payment amout"}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <CardContent
        sx={{
          p: 4,
        }}
      >
        <Typography variant="subtitle2">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
              <Box pr={3} pb={2}>
                {"Message"}:
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Text color="black">
                <b>{user?.statistics?.messeges}</b>
              </Text>
            </Grid>
          </Grid>
        </Typography>
        <Typography variant="subtitle2">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
              <Box pr={3} pb={2}>
                {"Call"}:
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Text color="black">
                <b>{user?.statistics?.calls}</b>
              </Text>
            </Grid>
          </Grid>
        </Typography>
        <Typography variant="subtitle2">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
              <Box pr={3} pb={2}>
                {"Lead"}:
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Text color="black">
                <b>{user?.statistics?.leads}</b>
              </Text>
            </Grid>
          </Grid>
        </Typography>
        <Typography variant="subtitle2">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
              <Box pr={3} pb={2}>
                {"Pending Payment"}:
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <Text color="black">
                <b>{user?.pendingPayment}</b>
              </Text>
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default LeadsAndPendingPayments;
