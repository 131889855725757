import { StaticPagesProvider } from "./EditStaticPagesContext";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "./components/PageHeader";
import Editors from "./components/Editors";
import Footer from "src/components/layouts/AccentSidebarLayout/Footer";

const StaticPages = () => {
  return (
    <div>
      <StaticPagesProvider>
        <PageTitleWrapper>
          <PageHeader />
        </PageTitleWrapper>
        <Editors />
      </StaticPagesProvider>
      <Footer />
    </div>
  );
};

export default StaticPages;
