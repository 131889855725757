export const PERMISSIONS = {
    Create_New_Admin: "Create_New_Admin",
    Get_Amin_List: "Get_Amin_List",
    Get_Customer_List: "Get_Customer_List",
    Get_User_Info: "Get_User_Info",
    Update_User: "Update_User",
    Get_User_Detail: "Get_User_Detail",
    Upload_User_Media: "Upload_User_Media",
    Update_User_Areas: "Update_User_Areas",
    Get_Pro_List: "Get_Pro_List",
    Get_Pro_Detail: "Get_Pro_Detail",
    Update_Pro_Detail: "Update_Pro_Detail",
    Approve_Pro: "Approve_Pro",
    Get_Pro_Payment_List: "Get_Pro_Payment_List",
    Get_Pro_Bid_List: "Get_Pro_Bid_List",
    Get_User_Address: "Get_User_Address",
    Get_User_Areas: "Get_User_Areas",
    Update_Pro_Services: "Update_Pro_Services",
    Get_Pro_Services: "Get_Pro_Services",
    Get_Pro_Bid_History: "Get_Pro_Bid_History",
    Get_Category_List: "Get_Category_List",
    Get_Category_Detail: "Get_Category_Detail",
    Create_New_Category: "Create_New_Category",
    Update_Category: "Update_Category",
    Set_Category_Units: "Set_Category_Units",
    Revoke_Category: "Revoke_Category",
    Reorder_Categories: "Reorder_Categories",
    Get_Service_List: "Get_Service_List",
    Create_New_Service: "Create_New_Service",
    Get_Service_Detail: "Get_Service_Detail",
    Update_Service: "Update_Service",
    Revoke_Service: "Revoke_Service",
    Reorder_Services: "Reorder_Services",
    Get_Related_Services_To_Category: "Get_Related_Services_To_Category",
    Get_Questionnaire_List: "Get_Questionnaire_List",
    Get_Qusetionnaire_Detail: "Get_Qusetionnaire_Detail",
    Create_New_Questionnaire: "Create_New_Questionnaire",
    Update_Questionnaire: "Update_Questionnaire",
    Disactive_Questionnaire: "Disactive_Questionnaire",
    Attach_Questions_To_Questionnaire: "Attach_Questions_To_Questionnaire",
    Get_Question_List: "Get_Question_List",
    Get_Related_Questions_To_Questionnaire: "Get_Related_Questions_To_Questionnaire",
    Create_New_Question: "Create_New_Question",
    Update_Question: "Update_Question",
    Toggle_Activation_Question: "Toggle_Activation_Question",
    Revoke_Qustion: "Revoke_Qustion",
    Reorder_Questions: "Reorder_Questions",
    Get_Option_List: "Get_Option_List",
    Get_Options_Related_To_Question: "Get_Options_Related_To_Question",
    Create_New_Option: "Create_New_Option",
    Update_Option: "Update_Option",
    Revoke_Option: "Revoke_Option",
    Reorder_Options: "Reorder_Options",
    Get_Book_Request_List: "Get_Book_Request_List",
    Get_Book_Detail: "Get_Book_Detail",
    Get_Bids_Related_To_Book: "Get_Bids_Related_To_Book",
    Get_Bid_List: "Get_Bid_List",
    Get_Bid_Detail: "Get_Bid_Detail",
    Get_Units: "Get_Units",
    Get_Unit_Detail: "Get_Unit_Detail",
    Create_New_Unit: "Create_New_Unit",
    Update_Unit: "Update_Unit",
    Get_Payment_History_List: "Get_Payment_History_List",
    Get_Payment_Detail: "Get_Payment_Detail",
    Get_Setting: "Get_Setting",
    Update_Settings: "Update_Settings",
    Create_New_setting: "Create_New_setting",
    Get_Static_Page_List: "Get_Static_Page_List",
    Get_Static_page: "Get_Static_page",
    Update_Static_Page: "Update_Static_Page",
    Role_Controller: "Role_Controller",
    Set_Role_Controller: "Set_Role_Controller",
}