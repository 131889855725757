import { useState } from "react";

let sorthDebounce = null;
export const useSortDebounce = () => {
  const [order, setOrder] = useState("");

  const handleChangeSort = (target) => {
    const value = target;
    clearTimeout(sorthDebounce);

    sorthDebounce = setTimeout(() => {
        setOrder(value);
    }, 500);
  };

  return {
    order,
    handleChangeSort: handleChangeSort,
  };
};
