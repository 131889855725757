import { useState } from "react";
import { adminService } from "src/api/services/admin";
import { Switch, ListItem, List, Card, ListItemText } from "@mui/material";
import useToast from "src/hooks/useToast";

const Activation = ({ user, handleRefreshData ,targetApi, disabled }) => {
  const { toast } = useToast();
  const [isUserDisabled, setIsUserDisabled] = useState(user.isActive);
  const [, setLoading] = useState(false);

  const handleToggle = async () => {
    const tempData = {
      isActive: !isUserDisabled,
    };

    try {
      setLoading(true);
      await adminService[targetApi](user?.id, tempData);
      toast(
        `${user?.fullName.toUpperCase()} ${
          !isUserDisabled ? "Activate" : "Deactivate"
        } successful!`,
        "success"
      );
      setIsUserDisabled((toggle) => !toggle);
      setLoading(false);
      handleRefreshData();
    } catch (error) {
      toast(`Error happened in toggle class`, "error");
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Card>
      <List>
        <ListItem
          sx={{
            p: 3,
          }}
        >
          <ListItemText
            primaryTypographyProps={{ variant: "h5", gutterBottom: true }}
            secondaryTypographyProps={{
              variant: "subtitle2",
              color: "warning",
              lineHeight: 1,
            }}
            primary={"Disable this account ?"}
            secondary={
              "if you want to disable this account you can use this toggle button!"
            }
          />

          <Switch
            disabled={disabled}
            checked={isUserDisabled}
            onChange={(e) => handleToggle(e)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </ListItem>
      </List>
    </Card>
  );
};

export default Activation;
