import React from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import AddUpdateForm from "./components/AddUpdateForm";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { adminService } from "src/api/services/admin";
import { Link, useParams } from "react-router-dom";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const AddUpdatePage = () => {
  const { id, questionId } = useParams();
  const { toast } = useToast();
  const permissions = usePermission();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const targetId = questionId;

  const onCreate = async (formData) => {
    if (!permissions[PERMISSIONS.Create_New_Question]) return;
    try {
      if (formData.questionId) {
        let data = {
          questionnaireId: +formData?.questionnaireId,
          questionId: +formData?.questionId,
        };
        if (!permissions[PERMISSIONS.Attach_Questions_To_Questionnaire]) return;
        await adminService.attachQuestionToQuestinnaire(data);
      } else {
        delete formData["questionId"];
        await adminService.createQuestions({
          ...formData,
          questionnaireId: +id,
        });
      }
    } catch (error) {
      console.error(error);
      toast(errorMessage(error), "error");
    }
  };

  const onUpdate = async (formData) => {
    try {
      if (formData.questionId) {
        let data = {
          questionnaireId: +formData?.questionnaireId,
          questionId: +formData?.questionId,
        };
        if (!permissions[PERMISSIONS.Attach_Questions_To_Questionnaire]) return;
        await adminService.attachQuestionToQuestinnaire(data);
      } else {
        if (!permissions[PERMISSIONS.Update_Question]) return;
        delete formData["questionId"];
        await adminService.updateQuestions(targetId, {
          ...formData,
        });
      }
    } catch (error) {
      console.error(error);
      toast(errorMessage(error), "error");
    }
  };

  return (
    <>
      <Grid
        container
        padding={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Tooltip arrow placement="top" title={"Go back"}>
          <Link
            to={
              ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.ROOT.GET_BY_DATA(
                id
              ).ABSOLUTE
            }
          >
            <IconButton
              color="primary"
              sx={{
                p:  { xs: 0, sm: 0, lg: 2, md: 2, xl: 2 },
                mr:  { xs: 0, sm: 0, lg: 2, md: 2, xl: 2 },
              }}
            >
              <ArrowBackTwoToneIcon />
            </IconButton>
          </Link>
        </Tooltip>
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {!questionId ? "Add new question" : "Update question"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        p={2}
        direction={!isMediumScreen ? "row" : "column-reverse"}
      >
        <Grid xs={12} sm={12} md={6} lg={6} xl={6} item p={{ xs: 0, sm: 0, lg: 2, md: 2, xl: 2 }}>
          <AddUpdateForm
            targetAPI={!questionId ? onCreate : onUpdate}
            subTitle={
              !questionId
                ? "Fill out form below to add your new Question"
                : "Fill out form below to update your Question"
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddUpdatePage;
