import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import {
  Grid,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { Link, useLocation } from "react-router-dom";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";
import { makeStyles } from "@mui/styles";

const PageHeader = ({ pageTitle, pageSubtitle }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const persmissions = usePermission();
  const location = useLocation();
  const isReordering = location.pathname.includes("reorder");

  const route = isReordering
    ? ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.ROOT.ABSOLUTE
    : ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.REORDER.ABSOLUTE;

  const icon = isReordering ? (
    <ArrowBackIcon fontSize="small" />
  ) : (
    <FormatListBulletedIcon fontSize="small" />
  );

  const handleNavigate = () => {
    if (persmissions[PERMISSIONS.Reorder_Categories]) {
      return (
        <Link to={route} style={{ textDecoration: "none" }}>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 },
            }}
            style={{ color: "#fff" }}
            variant="contained"
            startIcon={icon}
          >
            {isReordering ? "Back to list" : "Reorder"}
          </Button>
        </Link>
      );
    }
  };
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {pageTitle}
          </Typography>
          <Typography variant="subtitle2">{pageSubtitle}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>{handleNavigate()}</Grid>
            {persmissions[PERMISSIONS.Create_New_Category] && (
              <Grid item>
                <Link
                  to={
                    ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.ADD_NEW.ABSOLUTE
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      mt: { xs: 2, sm: 0 },
                    }}
                    style={{ color: "#fff" }}
                    variant="contained"
                    startIcon={<AddTwoToneIcon fontSize="small" />}
                  >
                    {isSmallScreen ? "Create Category" : "Create New Category"}
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PageHeader;

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down("md")]: {
      padding: "25px",
    },
  },
}));
