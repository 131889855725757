import React, { useState, useEffect } from "react";
import { adminService } from "src/api/services/admin";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Card,
  CardContent,
  Divider,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import Text from "src/components/Text";
import { useNavigate, useParams } from "react-router-dom";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { useCallback } from "react";
import { convertTimezone } from "src/utils/FormatDate";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const PaymentHistoryDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const permissions = usePermission();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { toast } = useToast();

  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  const getDetail = useCallback(async () => {
    if (!permissions[PERMISSIONS.Get_Payment_Detail]) return;
    try {
      let response = await adminService.getPaymentDetail(id);
      setDetail(response?.data?.data);
      setLoading(false);
    } catch (err) {
      toast(errorMessage(err), "error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDetail]);

  return (
    <>
      <Grid
        container
        padding={2}
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="nowrap"
        width='100%'
        maxWidth="100%"
      >
        <Tooltip arrow placement="top" title={"Go back"}>
          <Button
            onClick={() => navigate(-1)}
            color="primary"
            sx={{
              p: { sm: 1, xs: 1, md: 2, xl: 2, lg: 2 },
              mr: { sm: 0, xs: 0, md: 2, xl: 2, lg: 2 },
            }}
          >
            <ArrowBackTwoToneIcon />
          </Button>
        </Tooltip>
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {"Payment History Detil Page"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        p={2}
        direction={!isMediumScreen ? "row" : "column-reverse"}
      >
        <Grid xs={12} sm={12} md={6} lg={5} xl={5} item p={2}>
          <Card style={{ minHeight: "70vh" }}>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  {"Detail "}
                </Typography>
                <Typography variant="subtitle2">
                  {"Manage details related to Payment History"}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <CardContent
              sx={{
                p: 4,
              }}
            >
              {loading ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      mb={2}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Pro Name:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.name || "-"}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      mb={2}
                      mt={2}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Phone Number:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.phoneNumber || "-"}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      mb={2}
                      mt={2}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Date:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>
                          {detail?.date ? convertTimezone(detail?.date) : "-"}
                        </b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      mb={2}
                      mt={2}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box pr={2} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Payment ID:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.paymentId || "-"}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      mb={2}
                      mt={2}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Payment Amount(KD):"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.amount || "-"}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      mb={2}
                      mt={2}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Payment Method:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.method || "-"}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      mt={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Payment Status:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.status || "-"}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      mt={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"Start Date:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>
                          {detail?.startDate
                            ? convertTimezone(detail?.startDate)
                            : "-"}
                        </b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      mt={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"End Date:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>
                          {detail?.endDate
                            ? convertTimezone(detail?.endDate)
                            : "-"}
                        </b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      mt={2}
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {" "}
                          {"Call:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.calls}</b>
                      </Text>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      mb={2}
                      mt={2}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box pr={3} display="flex">
                        <Typography pr={3} variant="subtitle2">
                          {"message:"}
                        </Typography>
                      </Box>
                      <Text color="black">
                        <b>{detail?.messages}</b>
                      </Text>
                    </Grid>
                  </Grid>
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentHistoryDetail;
