import React, { useState } from "react";
import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Typography,
  Tabs,
  styled,
  Tab,
  Switch,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import useToast from "src/hooks/useToast";
import { adminService } from "src/api/services/admin";
import { errorMessage } from "src/utils/errorTypeDetector";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const Results = ({ resultData }) => {
  const [activeTab, setActiveTab] = useSearchParams({ role: "SUPERADMIN" });

  const handleTabsChange = (_event, tabsValue) => {
    setActiveTab((prevFilters) => ({
      ...prevFilters,
      role: tabsValue,
    }));
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
        pb={3}
      >
        <TabsWrapper
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={activeTab.get("role") || "all"}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </TabsWrapper>
      </Box>
      <Card>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{"ID"}</TableCell>
                <TableCell>{"Permission Name"}</TableCell>
                <TableCell>{"Active"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultData.map((permission) => (
                <CustomTableRow
                  key={permission.id}
                  permission={permission}
                  activeTab={activeTab.get("role")}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default Results;

const CustomTableRow = ({ permission, activeTab }) => {
  const [hasPermission, setHasPermission] = useState(permission?.forAdmin);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const permissions = usePermission();

  const onTogglePermission = async (id) => {
    if (!permissions[PERMISSIONS.Set_Role_Controller]) return;
    const data = {
      id: id,
      forAdmin: !hasPermission,
    };

    try {
      setLoading(true);
      await adminService.setPermissions(data);
      setLoading(false);
      setHasPermission((pervState) => !pervState);
      toast(`Permission ${hasPermission ? "Denied" : "Granted"}`, "success");
    } catch (error) {
      setLoading(false);
      toast(errorMessage(error), "error");
      console.error(error);
    }
  };

  return (
    <TableRow hover>
      <TableCell>
        <Typography fontWeight="700">{permission?.id}</Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight="700">
          {permission?.name?.split("_")?.join(" ")}
        </Typography>
      </TableCell>
      <TableCell>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          style={{ width: "200px" }}
        >
          <Tooltip
            title={
              activeTab === "SUPERADMIN"
                ? "You couldn't change super admin permission"
                : ""
            }
          >
            <div>
              <Switch
                checked={activeTab === "SUPERADMIN" ? true : hasPermission}
                disabled={activeTab === "SUPERADMIN" ? true : loading}
                disableRipple={activeTab === "SUPERADMIN"}
                onChange={() => onTogglePermission(permission.id)}
                color="primary"
              />
            </div>
          </Tooltip>
          {loading && (
            <CircularProgress size="1rem" style={{ marginRight: "8px" }} />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

const tabs = [
  {
    value: "SUPERADMIN",
    label: "Super Admin",
  },
  {
    value: "ADMIN",
    label: "Admin",
  },
];

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);
