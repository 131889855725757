import React, { useState } from "react";
import {
  Box,
  Card,
  TablePagination,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  TableSortLabel,
  CircularProgress,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import { Link } from "react-router-dom";
import ConfirmModal from "src/components/ConfirmModal";
import { useStyles } from "src/pages/dashboard/styles/main";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "id",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name(En)",
  },
  {
    id: "nameAr",
    numeric: false,
    disablePadding: false,
    label: "Name(Ar)",
  },
];

const Results = ({ resultData, pagination, actions, loading }) => {
  const classes = useStyles();
  const permissions = usePermission();

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState({ id: false, name: false, nameAr: false });
  const [confirmModal, setConfirmModal] = useState({ show: false, data: null });
  const handlePageChange = (_event, newPage) => {
    actions.changePage(newPage);
  };

  const handleLimitChange = (event) => {
    actions.changeTake(parseInt(event.target.value));
  };

  const handleDelete = () => {
    actions.onDelete(confirmModal.data);
  };

  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    setOrder((prev) => {
      return {
        ...prev,
        [property]: !prev[property],
      };
    });
    actions.handleSortUnits({ [property]: order[property] ? 1 : -1 });
  };

  return (
    <Card>
      <Box p={2}>
        <TextField
          sx={{
            m: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          onChange={actions.handleSearchUnits}
          placeholder={"Search by name ..."}
          size="small"
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </Box>
      <Divider />
      {resultData && resultData?.length === 0 ? (
        <>
          <Typography
            sx={{
              py: 10,
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {loading ? (
              <div className={classes.findingContainer}>
                <CircularProgress className={classes.findingSpinner} />
                trying to find data , Please waite !
              </div>
            ) : (
              "We couldn't find any unit of measurement matching your search criteria"
            )}
          </Typography>
        </>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={"left"}
                      padding={"normal"}
                      sortDirection={true}
                    >
                      <TableSortLabel
                        active={true}
                        direction={
                          orderBy === headCell.id &&
                          order[headCell.id] !== false
                            ? "asc"
                            : "desc"
                        }
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell align="center">{"Update"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                className={loading ? classes.fadedRow : classes.tableRow}
              >
                <div className={classes.spinner}>
                  {loading && <CircularProgress />}
                </div>

                {resultData.map((data) => {
                  return (
                    <TableRow key={data?.id}>
                      <TableCell>{data.id}</TableCell>
                      <TableCell>
                        <Typography fontWeight="700">{data.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="700">{data.nameAr}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap>
                          <Typography noWrap>
                            <Tooltip title={"Update"} arrow>
                              <Link
                                to={
                                 permissions[PERMISSIONS.Update_Unit] ? 
                                 ROUTE_CONSTANTS.DASHBOARD.SYSTEM.UNIY_MEASUREMENT.UPDATE.EDIT_BY_DATA(
                                  data?.id
                                ).ABSOLUTE:
                                "#"
                                }
                              >
                                <IconButton color="primary">
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          </Typography>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={pagination.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={pagination.page}
              rowsPerPage={pagination.take}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </>
      )}
      <ConfirmModal
        show={confirmModal.show}
        onHide={() => setConfirmModal({ show: false, data: null })}
        action={handleDelete}
        actionTitle="category"
      />
    </Card>
  );
};

export default Results;
