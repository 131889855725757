import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  TablePagination,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  TableSortLabel,
  Divider,
  CircularProgress,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { adminService } from "src/api/services/admin";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import ToggleActivation from "../../Questionnaire/components/ToggleActivation";
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { Link, useParams, useLocation } from "react-router-dom";
import { useStyles } from "src/pages/dashboard/styles/main";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name(En)",
  },
  {
    id: "nameAr",
    numeric: false,
    disablePadding: false,
    label: "Name(Ar)",
  },
];

// const Row = SortableElement(({ data, ...other }) => {
//   const { id: questionnaireId, questionId } = useParams();
//   return (
//     <TableRow {...other}>
//       {other?.children?.[0]}
//       <TableCell style={{ width: "5%" }}>
//         <DragHandle />
//       </TableCell>
//       <TableCell>{data.id}</TableCell>
//       <TableCell>
//         <Typography fontWeight="700">{data.name}</Typography>
//       </TableCell>
//       <TableCell>
//         <Typography fontWeight="700">{data.nameAr}</Typography>
//       </TableCell>
//       <TableCell>
//         <ToggleActivation data={data} targetApi="updateOptions" />
//       </TableCell>
//       <TableCell align="center">
//         <Typography noWrap>
//           <Typography noWrap>
//             <Tooltip title={"Update"} arrow>
//               <Link
//                 to={{
//                   pathname:
//                     ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.UPDATE.EDIT_BY_DATA(
//                       questionnaireId,
//                       questionId,
//                       data.id
//                     ).ABSOLUTE,
//                   search: `?name=${data?.name}&nameAr=${data?.nameAr}`,
//                 }}
//               >
//                 <IconButton color="primary">
//                   <LaunchTwoToneIcon fontSize="small" />
//                 </IconButton>
//               </Link>
//             </Tooltip>
//           </Typography>
//         </Typography>
//       </TableCell>
//     </TableRow>
//   );
// });

const Results = ({ resultData, pagination, actions, loading }) => {
  const classes = useStyles();
  const location = useLocation();
  const isReordering = location.pathname.includes("reorder");

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState(true);
  const [movedRowIndex, setMovedRowIndex] = useState(null);

  const { toast } = useToast();

  const handlePageChange = (_event, newPage) => {
    actions.changePage(newPage);
  };

  const handleLimitChange = (event) => {
    actions.changeTake(parseInt(event.target.value));
  };

  const reorderList = async (prevId, nextId, id) => {
    try {
      const data = {
        prevId,
        nextId,
      };
      await adminService.reorderOptions(id, data);
      toast("Reordered successfully!");
    } catch (err) {
      toast(errorMessage(err), "error");
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    actions.setter(arrayMove(resultData, oldIndex, newIndex));
    setMovedRowIndex(newIndex);
  };

  useEffect(() => {
    if (movedRowIndex || movedRowIndex === 0) {
      let currentIndex = movedRowIndex;
      const prevIndex = currentIndex > 0 ? currentIndex - 1 : -1;
      const nextIndex =
        currentIndex !== resultData.length ? currentIndex + 1 : -1;

      const currentId = resultData[currentIndex].id;
      let prevId = currentIndex > -1 ? resultData[prevIndex]?.id : -1;
      let nextId = currentIndex > -1 ? resultData[nextIndex]?.id : -1;
      reorderList(prevId, nextId, currentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movedRowIndex]);

  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    actions.handleSortOption({ [property]: order ? 1 : -1 });
    setOrder((prev) => !prev);
  };

  const TableBodySortable = SortableContainer(
    ({ children, displayRowCheckbox }) => (
      <TableBody
        className={loading ? classes.fadedRow : classes.tableRow}
        displayRowCheckbox={displayRowCheckbox}
      >
        {children}
      </TableBody>
    )
  );

  return (
    <Card>
      <Box p={2}>
        <TextField
          sx={{
            m: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon />
              </InputAdornment>
            ),
          }}
          onChange={actions.handleSearchOption}
          placeholder={"Search by name ..."}
          size="small"
          fullWidth
          margin="normal"
          variant="outlined"
        />
      </Box>
      <Divider />
      {resultData && resultData?.length === 0 ? (
        <Typography
          sx={{
            py: 10,
          }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {loading ? (
            <div className={classes.findingContainer}>
              <CircularProgress className={classes.findingSpinner} />
              trying to find data , Please waite !
            </div>
          ) : (
            "We couldn't find any option matching your search criteria"
          )}
        </Typography>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {isReordering && (
                    <TableCell style={{ width: "5%" }}>&nbsp; </TableCell>
                  )}
                  {!isReordering &&
                    headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={"normal"}
                        sortDirection={true}
                      >
                        <TableSortLabel
                          active={true}
                          direction={
                            orderBy === headCell.id && order !== false
                              ? "asc"
                              : "desc"
                          }
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  {isReordering && (
                    <>
                      <TableCell>{"ID"}</TableCell>
                      <TableCell>{"Name(En)"}</TableCell>
                      <TableCell>{"Name(Ar)"}</TableCell>
                    </>
                  )}
                  {!isReordering && <TableCell>{"Active"}</TableCell>}
                  {!isReordering && (
                    <TableCell align="center">{"Action"}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              {!isReordering ? (
                resultData &&
                resultData.map((row, index) => {
                  return <Row index={index} key={row.id} data={row} />;
                })
              ) : (
                <TableBodySortable
                  onSortEnd={onSortEnd}
                  useDragHandle
                  displayRowCheckbox={false}
                >
                  <div className={classes.spinner}>
                    {loading && <CircularProgress />}
                  </div>

                  {resultData &&
                    resultData.map((row, index) => {
                      return (
                        <RowWithOrdering
                          index={index}
                          key={row.id}
                          data={row}
                        />
                      );
                    })}
                </TableBodySortable>
              )}
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={pagination.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={pagination.page}
              rowsPerPage={pagination.take}
              rowsPerPageOptions={[5, 10, 15]}
            />
          </Box>
        </>
      )}
    </Card>
  );
};

export default Results;
const DragHandle = SortableHandle(({ style }) => (
  <span style={{ ...style, ...{ cursor: "move" } }}> {"::::"} </span>
));

const Row = ({ data, ...other }) => {
  const { id: questionnaireId, questionId } = useParams();
  const permissions = usePermission();
  const location = useLocation();
  const isReordering = location.pathname.includes("reorder");
  return (
    <TableRow {...other}>
      {isReordering && other?.children?.[0]}
      {isReordering && (
        <TableCell style={{ width: "5%" }}>
          <DragHandle />
        </TableCell>
      )}
      <TableCell>{data.id}</TableCell>
      <TableCell>
        <Typography fontWeight="700">{data.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight="700">{data.nameAr}</Typography>
      </TableCell>
      {!isReordering && (
        <TableCell>
          <ToggleActivation
          disabled={!permissions[PERMISSIONS.Update_Option]}
          data={data} targetApi="updateOptions" />
        </TableCell>
      )}
      {!isReordering && (
        <TableCell align="center">
          <Typography noWrap>
            <Typography noWrap>
              <Tooltip title={"Update"} arrow>
                <Link
                  to={{
                    pathname:
                      ROUTE_CONSTANTS.DASHBOARD.QUESTIONNAIRE.QUESTIONS.OPTIONS.UPDATE.EDIT_BY_DATA(
                        questionnaireId,
                        questionId,
                        data.id
                      ).ABSOLUTE,
                    search: `?name=${data?.name}&nameAr=${data?.nameAr}`,
                  }}
                >
                  <IconButton color="primary">
                    <LaunchTwoToneIcon fontSize="small" />
                  </IconButton>
                </Link>
              </Tooltip>
            </Typography>
          </Typography>
        </TableCell>
      )}
    </TableRow>
  );
};

const RowWithOrdering = SortableElement(({ data, index, key }) => {
  return <Row data={data} index={index} key={key} />;
});
