import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { useParams } from "react-router-dom";
import PageHeader from "./components/PageHeader";
import Results from "./components/Results";
import { adminService } from "src/api/services/admin";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { useSearchDebounce } from "src/hooks/useSearchDebounce";
import { useSortDebounce } from "src/hooks/useSortDebounce";

const Questions = () => {
  const { id: questionnaireId } = useParams();
  const { toast } = useToast();
  const { handleChangeSearch, query } = useSearchDebounce();
  const { handleChangeSort, order } = useSortDebounce();
  const isSerchedRef = useRef(false);

  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const getQuestions = useCallback(async () => {
    if (query && isSerchedRef.current && page !== 0) {
      return true;
    }
    const params = {
      skip: page === 0 ? page : page * take,
      take: take,
    };

    if (query) params.search = query;
    else delete params.search;

    if (order) params.order = JSON.stringify(order);
    else delete params.order;

    try {
      setLoading(true);
      const response = await adminService.getQuestionnairQuestions(
        questionnaireId,
        params
      );
      setQuestions(response?.data?.data?.items);
      setTotalCount(response?.data?.data?.count);
      isSerchedRef.current = false;
      setLoading(false);
    } catch (err) {
      setLoading(false);
      isSerchedRef.current = false;
      toast(errorMessage(err), "error");
    } finally {
      setLoading(false);
      isSerchedRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, take, query, order]);

  const handleChangePage = (page) => {
    if (page >= 0) setPage(page);
  };
  useEffect(() => {
    setPage(0);
    isSerchedRef.current = true;
  }, [query]);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  return (
    <Box>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        sx={{
          px: { xs: 1, sm: 1, lg: 4, md: 3, xl: 4 },
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
        marginBottom="auto"
      >
        <Grid item xs={12}>
          <Results
            loading={loading}
            resultData={questions}
            pagination={{
              page,
              take,
              totalCount: totalCount,
            }}
            actions={{
              changePage: (page) => handleChangePage(page),
              changeTake: (take) => setTake(take),
              setter: setQuestions,
              handleSearchQuestion: handleChangeSearch,
              handleSortQuestion: handleChangeSort,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Questions;
