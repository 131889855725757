import React, { useState, useEffect, useRef, useCallback } from "react";
import { Grid, Box, CircularProgress } from "@mui/material";
import Results from "./Result";
import { useSearchDebounce } from "src/hooks/useSearchDebounce";
import { useSortDebounce } from "src/hooks/useSortDebounce";
import { adminService } from "src/api/services/admin";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";

const AddressHistory = ({user, handleRefreshData}) => {
  const { handleChangeSearch, query } = useSearchDebounce();
  const { handleChangeSort, order } = useSortDebounce();
  const { toast } = useToast();
  const isSerchedRef = useRef(false);

  const [address, setAddress] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [take, setTake] = useState(10);

  const getMyList = useCallback(async () => {
    if (query && isSerchedRef.current && page !== 0) {
      return true;
    }

    let params = {
      take,
      skip: page,
    };

    if (query) params.search = query;
    else delete params.search;

    if (order) params.order = JSON.stringify(order);
    else delete params.order;

    try {
      const response = await adminService.getUserAddress(user?.id,params);
      isSerchedRef.current = false;
      setLoading(false);
      setAddress(response?.data?.data?.items);
      setTotalCount(response?.data?.data?.count);
    } catch (err) {
      isSerchedRef.current = false;
      setLoading(false);
      toast(errorMessage(err), "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page, take, query, order])

  const handleChangePage = (page) => {
    if (page >= 0) setPage(page);
  };

  useEffect(() => {
    getMyList();
  }, [getMyList]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "92vh",
      }}
    >
      <Grid
        sx={{
          px: { xs: 0.5, sm: 0.5, md: 3, xl: 4, lg: 4 },
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          {loading ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Results
              resultData={address || []}
              pagination={{
                page,
                take,
                totalCount: totalCount,
              }}
              actions={{
                changePage: (page) => handleChangePage(page),
                changeTake: (take) => setTake(take),
                handleSearchAddress: handleChangeSearch,
                handleSortAddress: handleChangeSort,
              }}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddressHistory;
