import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { adminService } from "src/api/services/admin";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Card,
  TextField,
  Divider,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useToast from "src/hooks/useToast";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { addUpdateTypeValidationSchema } from "./add-update-type-validation-schema";
import { errorMessage } from "src/utils/errorTypeDetector";
import ImagePicker from "src/components/ImagePicker";
import { useCallback } from "react";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const AddUpdatePage = () => {
  const { id } = useParams();
  const permissions = usePermission();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formCategory = queryParams.get("from") || "";

  const {
    control,
    formState: { errors, isSubmitting, isSubmitted },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      nameAr: "",
      categoryId: "",
      expirationTime: "",
    },
    mode: "all",
    resolver: yupResolver(addUpdateTypeValidationSchema()),
  });
  const theme = useTheme();
  const { toast } = useToast();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const is_small_screen = useMediaQuery(theme.breakpoints.down("sm"));
  const targetId = id;
  const [loading, setLoading] = useState(false);
  const [serviceDetail, setServiceDetail] = useState(null);
  const [categouries, setCategouries] = useState(null);
  const [imageIds, setImageIds] = useState([]); //**** handling array of ids of images for sending to create or update vehicle api
  const [, setDisconnectImageIds] = useState([]);

  const onCreate = async (formData) => {
    if (!permissions[PERMISSIONS.Create_New_Service]) return;
    let tempData = { ...formData };
    tempData.mediaId = imageIds?.[0];
    tempData.categoryId = +tempData.categoryId;
    try {
      await adminService.createServices(tempData);
      toast("Service create successful!", "success");
      setLoading(false);
      reset();
      navigate(ROUTE_CONSTANTS.DASHBOARD.CATALOG.TYPE.ROOT.ABSOLUTE, {
        replace: true,
      });
    } catch (error) {
      toast(errorMessage(error), "error");
      setLoading(false);
      console.error(error);
    }
  };

  const onUpdate = async (formData) => {
    if (!permissions[PERMISSIONS.Update_Service]) return;

    let tempData = { ...formData };
    tempData.categoryId = +tempData.categoryId;
    try {
      await adminService.updateService(targetId, tempData);
      toast("Service update successful!", "success");
      // reset();
      setLoading(false);
      // if (formCategory) {
      //   navigate(ROUTE_CONSTANTS.DASHBOARD.CATALOG.CATEGORY.ROOT.ABSOLUTE, {
      //     replace: true,
      //   });
      // } else {
      //   navigate(ROUTE_CONSTANTS.DASHBOARD.CATALOG.TYPE.ROOT.ABSOLUTE, {
      //     replace: true,
      //   });
      // }
    } catch (error) {
      setLoading(false);
      toast("Error in updating Service!", "error");
      console.error(error);
    }
  };
  const onSubmit = (data) => {
    !id ? onCreate(data) : onUpdate(data);
    setLoading(true);
  };
  const getCategories = async () => {
    if (!permissions[PERMISSIONS.Get_Category_List]) return;
    try {
      const param = { take: 1000, skip: 0 };
      let response = await adminService.getCategories(param);
      setCategouries(response?.data?.data?.items);
    } catch (err) {
      console.error(err);
      toast(errorMessage(err), "error");
    }
  };

  const getServiceDetail = useCallback(async () => {
    if (!permissions[PERMISSIONS.Get_Service_Detail]) return;
    try {
      setLoading(true);
      const response = await adminService.getServiceDetail(id);
      setServiceDetail(response?.data?.data);
      setValue("name", response?.data?.data?.name);
      setValue("nameAr", response?.data?.data?.nameAr);
      setValue("categoryId", response?.data?.data?.categoryId);
      setValue("expirationTime", +response?.data?.data?.expirationTime);
    } catch (error) {
      console.error("error", error);
      toast(errorMessage(error), "error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCategories();
    if (id) {
      getServiceDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getServiceDetail]);
  return (
    <>
      <Grid
        container
        sx={{
          p: { xs: 1, sm: 1, lg: 2, md: 2, xl: 2 },
        }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Tooltip arrow placement="top" title={"Go back"}>
          <Button
            onClick={() => navigate(-1)}
            color="primary"
            sx={{
              p: { xs: 0, sm: 0, lg: 2, md: 2, xl: 2 },
              mr: { xs: 0, sm: 0, lg: 2, md: 2, xl: 2 },
            }}
          >
            <ArrowBackTwoToneIcon />
          </Button>
        </Tooltip>
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {!id ? "Add new Service" : "Update Service"}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        p={2}
        spacing={2}
        direction={!isMediumScreen ? "row" : "column-reverse"}
      >
        {loading ? (
          <Grid item xs={12} textAlign="center">
            <CircularProgress />{" "}
            <Typography pt={3}>
              Waite until the service detail is loaded
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid xs={12} sm={12} md={6} lg={8} xl={8} item>
              <Typography variant="subtitle2" marginBottom="20px">
                {!id
                  ? "Fill out form below to add your Service"
                  : "Fill out form below to update your Service"}
              </Typography>
              <Card style={{ padding: "25px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={3} marginBottom={2}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label={"Name(En)"}
                            name="name"
                            variant="outlined"
                          />
                        )}
                      />
                      {errors.name && (
                        <Typography color="red">
                          {errors.name.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="nameAr"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            dir="rtl"
                            fullWidth
                            label={"Name(Ar)"}
                            name="nameAr"
                            variant="outlined"
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                direction: "ltr",
                              },
                            }}
                          />
                        )}
                      />
                      {errors.nameAr && (
                        <Typography color="red">
                          {errors.nameAr.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="categoryId"
                        control={control}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              categories
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              {...field}
                              value={field.value}
                              name="categoryId"
                              label="categories"
                            >
                              {categouries &&
                                categouries.map((catg) => (
                                  <MenuItem key={catg?.id} value={catg.id}>
                                    {catg?.name || catg.nameAr}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                      {errors.categoryId && (
                        <Typography color="red">
                          {errors.categoryId.message}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="expirationTime"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label={"Expiration Time (minute)"}
                            name="expirationTime"
                            variant="outlined"
                          />
                        )}
                      />
                      {errors.expirationTime && (
                        <Typography color="red">
                          {errors.expirationTime.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    style={{ margin: "20px 0px" }}
                    direction={!is_small_screen ? "row" : "column-reverse"}
                    alignItems="center"
                  >
                    <Button color="secondary" onClick={() => navigate(-1)}>
                      {"Cancel"}
                    </Button>
                    <Button
                      type="submit"
                      startIcon={
                        isSubmitting ? <CircularProgress size="1rem" /> : null
                      }
                      disabled={isSubmitted}
                      variant="contained"
                      style={{
                        width: is_small_screen && "100%",
                        color: "#fff",
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </form>
              </Card>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={4} xl={4} item>
              <ImagePicker
                imageStates={{
                  setDisconnectImageIds,
                  imageIds,
                  setImageIds,
                }}
                addNew={!id}
                data={serviceDetail}
                imageIds={imageIds}
                singlePicker
                target={"SERVICES"}
                targetId={id}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default AddUpdatePage;
