import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './sidebar';
import Header from './Header';
import SimpleBar from 'simplebar-react';


const AccentSidebarLayout = () => {
  const theme = useTheme();

  return (
    <Box sx={{ pt: theme.header.height }}>
      <Header />
      <Sidebar />

      <SimpleBar style={{
        // height: `calc(100vh - ${theme.header.height})`,
        // [theme.breakpoints.up('lg')]: {
        //   pl: `calc(${theme.sidebar.width} + 24px)`
        // }
        }}>
        <Box sx={{
          position: "relative",
          overflow: "auto",
          [theme.breakpoints.up('lg')]: {
            pl: `calc(${theme.sidebar.width} + 24px )`
          }
        }}>
          <Outlet />
        </Box>
      </SimpleBar>

    </Box>
  );
};

export default AccentSidebarLayout;
