import { Grid } from "@mui/material";
import PersonalDetails from "./PersonalDetails";
import RoleManagement from "./RoleManagement";
import Services from "src/pages/dashboard/users/Pro/components/Services";
import Areas from "src/pages/dashboard/users/Pro/components/Areas";
import Activation from "./Activation";
import LeadsAndPendingPayments from "src/pages/dashboard/users/Pro/components/Leads";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";


const Profile = ({ user, handleRefreshData, from }) => {
  const permissions = usePermission();
  return (
    <Grid container spacing={3}>
      {!from && (
        <Grid item xs={12}>
          <PersonalDetails
            user={user}
            handleRefreshData={handleRefreshData}
            showSections={["fullName", "phoneNumber", "edit", "email"]}
          />
        </Grid>
      )}
      {from === "pro" && (
        <>
          <Grid item xs={12}>
            <PersonalDetails
              user={user}
              handleRefreshData={handleRefreshData}
              showSections={["fullName", "phoneNumber", "introduction","edit"]}
            />
          </Grid>
          <Grid item xs={12}>
            <RoleManagement user={{ ...user, role: "pro" }} />
          </Grid>
          <Grid item xs={12}>
            <Services user={user} handleRefreshData={handleRefreshData}/>
          </Grid>
          <Grid item xs={12}>
            <Areas user={user} handleRefreshData={handleRefreshData}/>
          </Grid>
          <Grid item xs={12}>
            <LeadsAndPendingPayments user={user} />
          </Grid>
          <Grid item xs={12}>
            <Activation
              targetApi="updateProDetail"
              user={user}
              handleRefreshData={handleRefreshData}
              disabled={!permissions[PERMISSIONS.Update_Pro_Detail]}
            />
          </Grid>
        </>
      )}
      {from === "customer" && (
        <>
          <Grid item xs={12}>
            <PersonalDetails
              user={user}
              handleRefreshData={handleRefreshData}
              showSections={["phoneNumber","fullName",'edit']}
            />
          </Grid>
          <Grid item xs={12}>
            <RoleManagement user={user} />
          </Grid>
        </>
      )}
      {from === "admin" && (
        <>
          <Grid item xs={12}>
            <PersonalDetails
              user={user}
              handleRefreshData={handleRefreshData}
              showSections={["edit", "fullName", "phoneNumber", "email"]}
            />
          </Grid>
          <Grid item xs={12}>
            <RoleManagement user={user} />
          </Grid>
          <Grid item xs={12}>
            <Activation
              disabled={!permissions[PERMISSIONS.Update_User]}
              targetApi="updateUser"
              user={user}
              handleRefreshData={handleRefreshData}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Profile;
