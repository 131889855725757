import { useState, useEffect } from "react";
import Footer from "src/components/layouts/AccentSidebarLayout/Footer";
import { Box, Tabs, Tab, Grid, styled, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "./components/PageHeader";
import Info from "./components/Info";
import Bids from "./components/Bids";
import Books from "./components/Books";
import useToast from "src/hooks/useToast";
import { errorMessage } from "src/utils/errorTypeDetector";
import { adminService } from "src/api/services/admin";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const BookRequestDetail = () => {
  const { toast } = useToast();
  const { id } = useParams();
  const permissions = usePermission();

  const [currentTab, setCurrentTab] = useState("info");
  const [details, setDetails] = useState({
    info: null,
    answers: null,
    bids: null,
  });
  const [loading, setLoading] = useState(true);

  const tabs = [
    { value: "info", label: "Info" },
    ...(permissions[PERMISSIONS.Get_Bids_Related_To_Book]
      ? [{ value: "bids", label: "Bids" }]
      : []),
    ...(permissions[PERMISSIONS.Get_Book_Detail]
      ? [{ value: "books", label: "Book Details" }]
      : []),
  ];

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };
  const getDetail = async () => {
    if (!permissions[PERMISSIONS.Get_Book_Detail]) return;
    try {
      const response = await adminService.getBookDetail(id);
      setLoading(false);
      const { answers, bids, ...info } = response?.data?.data;
      setDetails({
        info: info,
        answers: answers,
        bids: bids,
      });
    } catch (error) {
      toast(errorMessage(error), "error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          mt: { xs: 1, sm: 1, lg: 3, md: 3, xl: 3 },
          mb: { xs: 1, sm: 1, lg: 6, md: 6, xl: 6 },
        }}
      >
        <PageTitleWrapper>
          <PageHeader />
        </PageTitleWrapper>
        <Grid
          sx={{
            px: { xs: 1, sm: 1, lg: 4, md: 3, xl: 4 },
          }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={{ xs: 2, sm: 2, lg: 4, md: 3, xl: 4 }}
        >
          {loading ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                width: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid item xs={12} mt={{ xs: 3, lg: 0, md: 1, xl: 0, sm: 3 }}>
                <TabsWrapper
                  onChange={handleTabsChange}
                  value={currentTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  {tabs.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
                </TabsWrapper>
              </Grid>
              <Grid
                item
                xs={12}
                md={currentTab !== "info" ? 12 : 6}
                lg={currentTab !== "info" ? 12 : 6}
                xl={currentTab !== "info" ? 12 : 6}
              >
                {currentTab === "info" && <Info id={id} data={details?.info} />}
                {currentTab === "bids" && <Bids id={id} data={details?.bids} />}
                {currentTab === "books" && (
                  <Books id={id} data={details?.answers} />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default BookRequestDetail;

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;

      .MuiTabs-indicator {
        box-shadow: none;
      }
    }
`
);
