export const errorMessage = (error) => {

  const errorMessageType = error?.response?.data?.meta?.responseType;
  switch (errorMessageType) {
    case "SUCCESS":
      return "Success";
    case "ERROR":
      return (
        error?.response?.data?.meta?.message ||
        "Error happened in updating password"
      );
    case "WARNING":
      return "Warning";
    case "VALIADTION_ERROR":
      let customErr = convertErrorToString(error?.response?.data?.meta?.validationErrors).join(',')
      return (
        customErr ||
        "Validation error happened in updating password"
      );
    default:
      break;
  }
};

const convertErrorToString = (errs) => {
 return Object.values(errs).map(err=>err?.message);
};
