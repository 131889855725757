import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { Link } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { usePermission } from "src/contexts/GlobalContext";
import { PERMISSIONS } from "src/constants/permissions";

const PageHeader = ({ pageTitle, pageSubtitle }) => {
  const permissions = usePermission();
  const classes = useStyles();

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.header}> 
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {pageTitle}
          </Typography>
          <Typography variant="subtitle2">{pageSubtitle}</Typography>
        </Grid>
       {
        permissions[PERMISSIONS.Create_New_Unit] && (
          <Grid item className={classes.header_create_units}>
          <Link to={ROUTE_CONSTANTS.DASHBOARD.SYSTEM.UNIY_MEASUREMENT.ADD_NEW.ABSOLUTE}
            style={{ textDecoration: 'none' }}
          >
            <Button
              sx={{
                mt: { xs: 2, sm: 0 },
              }}
              style={{ color: "#fff" }}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
            >
              {"Create New Unit Of Measurement"}
            </Button>
          </Link>
        </Grid>
        )
       }
        {/* } */}
      </Grid>
    </>
  );
};

export default PageHeader;

const useStyles = makeStyles((theme) => ({
  header:{
    [theme.breakpoints.down("md")]: {
      padding: '25px'
    },
  },
  header_create_units: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px !important",
      width: "100%",
      textAlign: "right",
    },
  },
}));