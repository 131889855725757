import { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
  CircularProgress,
  TableSortLabel,
  Chip,
} from "@mui/material";

import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import Label from "src/components/Label";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { ROUTE_CONSTANTS } from "src/constants/AppRoutes";
import { tabs } from "../constants";
import { usePermission, useUser } from "src/contexts/GlobalContext";
import { Link } from "react-router-dom";
import { useStyles } from "src/pages/dashboard/styles/main";
import { PERMISSIONS } from "src/constants/permissions";
import { Tag } from "@mui/icons-material";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "fullName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
];

const Results = ({ resultData, pagination, actions, activeTab, loading }) => {
  const classes = useStyles();
  const persmissions = usePermission();

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState({
    id: false,
    fullName: false,
    email: false,
  });

  const user = useUser();
  const handlePageChange = (_event, newPage) => {
    actions.changePage(newPage);
  };

  const handleLimitChange = (event) => {
    actions.changeTake(parseInt(event.target.value));
  };

  const handleRedirectUserToProfile = (id) => {
    if (id === user.id) {
      return ROUTE_CONSTANTS.DASHBOARD.USERS.PROFILE.ABSOLUTE;
    } else {
      return ROUTE_CONSTANTS.DASHBOARD.USERS.GET_BY_DATA(id, "admin").ABSOLUTE;
    }
  };

  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    setOrder((prev) => {
      return {
        ...prev,
        [property]: !prev[property],
      };
    });
    actions.handleSortAdmin({ [property]: order[property] ? 1 : -1 });
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
        pb={3}
      >
        <TabsWrapper
          onChange={actions.handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={activeTab.get("role")}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </TabsWrapper>
      </Box>
      <Card>
        <Box p={2}>
          <TextField
            sx={{
              m: 0,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
            }}
            onChange={actions.handleSearchAdmin}
            placeholder={"Search by name, email or phone number..."}
            size="small"
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Box>
        <Divider />
        {resultData?.length === 0}
        {resultData?.length === 0 ? (
          <>
            <Typography
              sx={{
                py: 10,
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {loading ? (
                <div className={classes.findingContainer}>
                  <CircularProgress className={classes.findingSpinner} />
                  trying to find data , Please waite !
                </div>
              ) : (
                "We couldn't find any admins matching your search criteria"
              )}
            </Typography>
          </>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={"normal"}
                        sortDirection="asc"
                      >
                        <TableSortLabel
                          active={true}
                          direction={
                            orderBy === headCell.id &&
                            order[headCell.id] !== false
                              ? "asc"
                              : "desc"
                          }
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>{"Phone"}</TableCell>
                    <TableCell>{"Role"}</TableCell>
                    <TableCell>{"Deleted"}</TableCell>
                    <TableCell align="center">{"Actions"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  className={loading ? classes.fadedRow : classes.tableRow}
                >
                  <TableRow>
                    <TableCell>
                      <div className={classes.spinner}>
                        {loading && <CircularProgress />}
                      </div>
                    </TableCell>
                  </TableRow>

                  {resultData &&
                    resultData?.map((user) => {
                      return (
                        <TableRow hover key={user.id}>
                          <TableCell>
                            <Typography variant="h5">{user?.id}</Typography>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Box>
                                <Typography variant="h5">
                                  {" "}
                                  {user.fullName}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">{user.email}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              {user.phoneNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {getUserRoleLabel(
                              user?.isAdmin ? "ADMIN" : "SUPERADMIN"
                            )}
                          </TableCell>
                          <TableCell>
                            {user?.isDeleted ? <Chip color="error" label="Deleted" /> : <Chip color="default" label="Active"/>}
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={"Detail"} arrow>
                                <Link to={persmissions[PERMISSIONS.Get_User_Detail] ? handleRedirectUserToProfile(user.id) :''}>
                                  <IconButton
                                    color="primary"
                                  >
                                    <LaunchTwoToneIcon fontSize="small" />
                                  </IconButton>
                                </Link>
                              </Tooltip>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="footer"
                count={pagination.totalCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={pagination.page}
                rowsPerPage={pagination.take}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          </>
        )}
      </Card>
    </>
  );
};

Results.propTypes = {
  users: PropTypes.array.isRequired,
};

Results.defaultProps = {
  users: [],
};

export default Results;

// const search

const getUserRoleLabel = (userRole) => {
  const map = {
    SUPERADMIN: {
      text: "Super Admin",
      color: "error",
    },
    ADMIN: {
      text: "Admin",
      color: "success",
    },
  };

  const { text, color } = map[userRole];

  return <Label color={color}>{text}</Label>;
};

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);
