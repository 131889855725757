import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start !important",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
});

const UpdateModal = ({
  show,
  title,
  onHide,
  subtitle,
  initialValues,
  validationSchema,
  onSubmitCustomAction,
  fields,
  onCancel,
  buttonText,
  positionTop,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors, isSubmitting },
    setValue,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: initialValues,
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
      reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit]);

  return (
    <Dialog
      classes={
        positionTop
          ? {
              scrollPaper: classes.topScrollPaper,
              paperScrollBody: classes.topPaperScrollBody,
            }
          : {}
      }
      fullWidth
      maxWidth="md"
      open={show}
      onClose={onHide}
    >
      <DialogTitle
        sx={{
          px: { xs: 1, sm: 1, md: 3, xl: 4, lg: 4 },
        }}
      >
        <Typography variant="h4" gutterBottom>
          {title || "setup a title"}
        </Typography>
        <Typography variant="subtitle2">
          {subtitle || "setup a subtitle"}
        </Typography>
      </DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmitCustomAction)}>
        <DialogContent
          style={{ width: "100%" }}
          dividers
          sx={{
            p: { xs: 2, sm: 2, md: 3, xl: 3, lg: 3 },
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              {fields(errors, control, handleSubmit, setValue)}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            px: { xs: 1, sm: 1, md: 3, xl: 4, lg: 4 },
          }}
        >
          <Button color="secondary" onClick={onCancel}>
            {"Cancel"}
          </Button>
          <Button
            style={{ color: "white" }}
            type="submit"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={Boolean(errors.submit) || isSubmitting}
            variant="contained"
          >
            {buttonText || "setup a button text"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateModal;
